import { useState, useEffect, useContext } from "react";
import PrimaryButton from "../../components/buttons/primary-button";
import { PhoneField } from "../../components/input/fields/phone-field";
import TextField from "../../components/input/fields/text-field";
import { DataContext } from "..";
import { SelectMenu } from "../../components/input/select/select-menu";
import InfoButton from "../../components/buttons/info-button";
import { useTranslation } from "react-i18next";


const Organization = () => {
  const { i18n, t } = useTranslation();
  const context = useContext(DataContext);
  const organization = context.organizationInfo;
  const [formValues, setFormValues] = useState({
    name: null,
    state: null,
    collaboratorList: null,
    website: null,
    phoneNumber: null,
    companyEmail: null,
    address: {
      street: null,
      city: null,
      cap: null,
    },
    logoUrl: null,
  });
  

  const options = [
    {
      label: "1-19",
      value: "1-19",
    },
    {
      label: "20-49",
      value: "20-49",
    },
    {
      label: "50-99",
      value: "50-99",
    },
    {
      label: "+100",
      value: "+100",
    },
  ];
  return (
    <div className="flex flex-col content-between w-full h-full mx-10 ">
      
    <InfoButton nameComponent={'organization'}/>
      <div className="space-y-4 text-3xl font-bold">
        <div className="flex items-center justify-start">
          <h1>{t("OrganizationSettings")}</h1>
        </div>

        <div className="flex flex-col w-full p-5 space-y-4 font-normal bg-white rounded-lg shadow-md">
          <div className="my-1 space-y-2">
            <div className="text-xl font-bold ">{t("General")}</div>
            <hr className="w-full" />
          </div>
          <div className="flex items-center justify-between w-full space-x-10">
            <TextField
              label={t("Name")}
              placeholder={
                organization && organization.name
                  ? organization.name
                  : t("OrganizationName")
              }
              labelClassName={"text-sm"}
              inputClassName={"p-1"}
              className={"max-w-xl"}
              onChange={(e) => {
                null;
              }}
            />
            <TextField
              label={t("CommercialEmail")}
              inputClassName={"p-1"}
              labelClassName={"text-sm"}
              placeholder={
                organization && organization.companyEmail
                  ? organization.companyEmail
                  : t("CompanyEmail")
              }
              onChange={(e) => {
                null;
              }}
            />
          </div>
          <div className="flex items-center space-x-10">
            <PhoneField
              label={t("PhoneNumber")}
              placeholder={
                organization && organization.phoneNumber
                  ? organization.phoneNumber
                  : t("OrganizatioNumber")
              }
              labelClassName={"text-sm"}
              inputClassName={"p-1"}
              onChange={(e) => {
                null;
              }}
            />
            <TextField
              label={t("Website")}
              inputClassName={"p-1"}
              labelClassName={"text-sm"}
              placeholder={
                organization && organization.website
                  ? organization.website
                  : t("Companywebsite")
              }
              onChange={(e) => {
                null;
              }}
            />
            
          </div>
          <div className="space-y-2">
            <div className="text-xl font-bold ">{t("Address")}</div>
            <hr className="w-full" />
          </div>
          <div className="flex items-center justify-between w-full space-x-10">
            <TextField
              label={t("Street")}
              placeholder={
                organization && organization.address.street
                  ? organization.address.street
                  : t("CompanyStreet")
              }
              labelClassName={"text-sm"}
              inputClassName={"p-1"}
              onChange={(e) => {
                null;
              }}
            />
            <TextField
              label={t("City")}
              inputClassName={"p-1"}
              labelClassName={"text-sm"}
              placeholder={
                organization && organization.address.city
                  ? organization.address.city
                  : t("CompanyCity")
              }
              onChange={(e) => {
                null;
              }}
            />
          </div>
          <div className="flex items-center space-x-10">
            <TextField
              label={"CAP"}
              placeholder={
                organization && organization.address.cap
                  ? organization.address.cap
                  : t("CompanyCAP")
              }
              labelClassName={"text-sm"}
              inputClassName={"p-1"}
              onChange={(e) => {
                null;
              }}
            />
            <TextField
              label={t("State")}
              inputClassName={"p-1"}
              labelClassName={"text-sm"}
              placeholder={
                organization && organization.address.state
                  ? organization.address.state
                  : t("OrganizationState")
              }
              onChange={(e) => {
                null;
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <PrimaryButton
          content={t("ApplyChanges")}
          className="px-4 py-2 mt-4 max-w-fit"
          //TODO: implementare update organization onclick
        />
      </div>
        <div className="font-bold absolute bottom-0 right-10 flex items-center justify-end p-4 text-petroil rounded-full ">
  <p className="mr-1">{t("NeedSupport")}</p>
  <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
  >
    {t("ContactUs")}
  </a>
</div>
    </div>
  );
};

export default Organization;
