import React, { useContext, useState } from "react";
import { DataContext } from "..";
import PrimaryButton from "../../components/buttons/primary-button";
import TextField from "../../components/input/fields/text-field";
import { createBooking, createCost } from "../../utils/firestore-functions";
import readXlsxFile from "read-excel-file"

import TertiaryButton from "../../components/buttons/tertiary-button";
import MiniLoadingApp from "../shared/miniLoadingApp";
import { useTranslation } from "react-i18next";

const UploadBookings = ({ onClose }) => {
    const { i18n, t } = useTranslation();
    const context = useContext(DataContext);
    const [bookingFileUpload, setBookingFileUpload] = useState(null);
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [bookingSucceded, setBookingSucceded] = useState(0);
    const [bookingError, setBookingError] = useState([]);

    const handleFileSelected = (e) => {
        const files = Array.from(e.target.files)
        setBookingFileUpload(files);
    }


    const InsertBooking = async (accomodation, row) => {

        const isStringBookingDate = typeof accomodation.BookingDate === 'string';
        const isStringCheckInDate = typeof accomodation.CheckInDate === 'string';
        const isStringCheckOutDate = typeof accomodation.CheckOutDate === 'string';

        const locale = navigator.language;
        const dateTimeFormat = new Intl.DateTimeFormat(locale);
        const options = dateTimeFormat.resolvedOptions();

        // TODO: aggiungere controlli nuovi campi
        if (options.locale === "en-US" && (!isStringBookingDate || !isStringCheckInDate || !isStringCheckOutDate)) {
            bookingError.push({ error: t("DateFormaInvalid"), row: row });
            return;
        }

        if (!accomodation.BookingDate) {
            bookingError.push({ error: t("BookingDateRequired"), row: row });
            return;
        }
        if (!accomodation.CheckInDate) {
            bookingError.push({ error: t("CheckInDateRequired"), row: row });
            return;
        }
        if (!accomodation.CheckOutDate) {
            bookingError.push({ error: t("CheckOutDateRequired"), row: row });
            return;
        }


        // transform data from string to date type 
        if (isStringBookingDate) {
            let newBookingDate = accomodation.BookingDate.replace(/(\d+[/])(\d+[/])/, '$2$1');
            accomodation.BookingDate = new Date(newBookingDate);
        }

        if (isStringCheckInDate) {
            let newCheckInDate = accomodation.CheckInDate.replace(/(\d+[/])(\d+[/])/, '$2$1');
            accomodation.CheckInDate = new Date(newCheckInDate);
        }

        if (isStringCheckOutDate) {
            let newCheckOutDate = accomodation.CheckOutDate.replace(/(\d+[/])(\d+[/])/, '$2$1');
            accomodation.CheckOutDate = new Date(newCheckOutDate);
        }


        if (isNaN(accomodation.BookingDate)) {
            bookingError.push({ error: t("DateFormaInvalid"), row: row });
            return;
        }
        if (isNaN(accomodation.CheckInDate)) {
            bookingError.push({ error: t("DateFormaInvalid"), row: row });
            return;
        }
        if (isNaN(accomodation.CheckOutDate)) {
            bookingError.push({ error: t("DateFormaInvalid"), row: row });
            return;
        }

        if (accomodation.Guests === null) {
            bookingError.push({ error: t("GuestsRequired"), row: row });
            return;
        }
        if (accomodation.Guests < 1) {
            bookingError.push({ error: t("InvalidGuestNumber"), row: row });
            return;
        }
        if (accomodation.Amount === null && accomodation.ExtraServiceFee === null) {
            bookingError.push({ error: t("AmountRequired"), row: row });
            return;
        }
        if (accomodation.Amount === 0 && accomodation.ExtraServiceFee === 0) {

            bookingError.push({ error: t("InvalidAmount"), row: row });
            return;
        }
        

        if (accomodation.BookingDate > accomodation.CheckInDate) {
            bookingError.push({ error: t("BookingDateShorter"), row: row });
            return;
        }
        if (accomodation.CheckInDate > accomodation.CheckOutDate) {
            bookingError.push({ error: t("CheckInDate"), row: row });
            return;
        }
        let accomodationReference = context.accomodationsList.filter(x => x.data.name.toLowerCase() == accomodation.AccomodationName.toLowerCase());
        if (!accomodationReference || accomodationReference.length === 0) {
            bookingError.push({ error: t("TheAccommodationDoesNotExist"), row: row });
            return;
        }
        
        accomodationReference = accomodationReference[0];

        if (accomodation.Amount && accomodation.Amount > 0){
            accomodation.Amount = accomodation.Amount;
            accomodation.AccommodationFare = accomodation.Amount - (accomodation.ExtraServiceFee ?? 0);
        }

        let commissionCostRule = null;
        if (accomodation.Channel) {
            commissionCostRule = context.costsRuleList && context.costsRuleList.filter(x => x.data.costTypology.value === 2 && x.data.costCategory.value === "ota-commission" && x.data.name.toLowerCase() === accomodation.Channel.toLowerCase() && (!x.data.accomodationList || (x.data.accomodationList && x.data.accomodationList.indexOf(accomodationReference.id) > -1)) && (!x.data.endDate || (x.data.endDate && new Date() < x.data.endDate.toDate())));
            if (commissionCostRule.length > 0) {
                if (commissionCostRule[0].data.paymentType.value === "Fixed") {
                    accomodation.commissionAmount = commissionCostRule[0].data.amount;
                    accomodation.commissionType = { label: t("Fixed") , value: null };
                }
                else if (commissionCostRule[0].data.paymentType.value === "Percentage") {
                    accomodation.commissionAmount = parseFloat(((commissionCostRule[0].data.amount * accomodation.Amount) / 100).toFixed(2));
                    accomodation.commissionType = { label: t("Percentage"), value: commissionCostRule[0].data.amount };
                }
            }
            else {
                bookingError.push({ error: t("ChannelNonExistent"), row: row });
                return;
            }
        }
        else {
            accomodation.commissionAmount = 0;
            accomodation.Channel = null;
            accomodation.commissionType = null;
        }
        try {
    
            let bookingObj = {
                bookingReference: accomodation.Reference ?? "",
                country: accomodation.Country.toUpperCase() ?? "",
                bookingDate: accomodation.BookingDate,
                checkInDate: accomodation.CheckInDate,
                checkOutDate: accomodation.CheckOutDate,
                numberOfGuest: accomodation.Guests,
                extraServiceFee: accomodation.ExtraServiceFee ?? accomodationReference.data.extraServiceFee,
                accommodationFare: accomodation.AccommodationFare, 
                amount: accomodation.Amount,
                accomodationReference: accomodationReference.id,
                commissionChannel: accomodation.Channel,
                commissionAmount: accomodation.commissionAmount,
                commissionType: accomodation.commissionType
            };
            let bookingId = await createBooking(bookingObj, context.organizationId);
            if (accomodation.Channel) {
                //Adding ota commission cost
                if (commissionCostRule.length > 0) {
                    commissionCostRule = commissionCostRule[0];
                    let commissionBooking = 0;
                    if (commissionCostRule.data.paymentType.value === "Fixed") {
                        commissionBooking = commissionCostRule.data.amount;
                    }
                    else if (commissionCostRule.data.paymentType.value === "Percentage") {
                        commissionBooking = parseFloat(((commissionCostRule.data.amount * accomodation.Amount) / 100).toFixed(2));
                    }
                    let paymentDate = accomodation.BookingDate;
                    if (commissionCostRule.data.paymentDateType) {
                        if (commissionCostRule.data.paymentDateType.value == "check-in") {
                            paymentDate = accomodation.CheckInDate;
                        }
                        else if (commissionCostRule.data.paymentDateType.value == "check-out") {
                            paymentDate = accomodation.CheckOutDate;
                        }
                    }

                    try {
                        await createCost(
                            {
                                name: accomodation.Channel,
                                costDate: accomodation.BookingDate,
                                paymentDate: paymentDate,
                                amount: commissionBooking,
                                costTypology: { label: t("Booking"), value: 2 },
                                costCategory: commissionCostRule.data.costCategory,
                                accomodationList: [accomodationReference.id],
                                bookingRef: bookingId,
                                costRuleRef: commissionCostRule.id,
                                supplierId: commissionCostRule.data.supplierId ? commissionCostRule.data.supplierId : null,
                                otaIncluded: null,
                                extraServiceFee: null,
                                percentage: commissionCostRule.data.amount ?? 0,
                                paymentType: commissionCostRule.data.paymentType.value,
                            },
                            context.organizationId
                        )
                    }
                    catch (e) {
                        bookingError.push({ error: t("ErrorOTACommissionCreation"), row: row });
                    }
                }
            }
            // Adding other costs
            const costRulesAccomodation = context.costsRuleList ? context.costsRuleList.filter(x => x.data.costTypology.value === 2 && x.data.costCategory.value != "ota-commission" && (!x.data.accomodationList || (x.data.accomodationList && x.data.accomodationList.indexOf(accomodationReference.id) > -1)) && (!x.data.endDate || (x.data.endDate && new Date() < x.data.endDate.toDate()))) : [];
            for (let i = 0; i < costRulesAccomodation.length; i++) {
                try {
                    let commissionAccomodation = 0;
                    if (costRulesAccomodation[i].data.paymentType.value === "Fixed") {
                        commissionAccomodation = costRulesAccomodation[i].data.amount;
                    } else if (costRulesAccomodation[i].data.paymentType.value === "Percentage") {
                        commissionAccomodation = parseFloat(((costRulesAccomodation[i].data.amount * accomodation.Amount) / 100).toFixed(2));

                        if(costRulesAccomodation[i].data.costCategory.value === "home-owner-payment"){
                            let tempTotal = accomodation.Amount;
                            tempTotal -= costRulesAccomodation[i].data.extraServiceFee === "Excluded" ? accomodation.ExtraServiceFee ? accomodation.extraServiceFee : accomodationReference.data.extraServiceFee : 0;
                            tempTotal -= costRulesAccomodation[i].data.otaIncluded === "Excluded" ? accomodation.commissionAmount ?? 0 : 0;
                            commissionAccomodation = Number(((tempTotal / 100 ) * (costRulesAccomodation[i].data.amount)).toFixed(2));       
            
                          }else{
                            commissionAccomodation = parseFloat(((costRulesAccomodation[i].data.amount * accomodation.Amount) / 100).toFixed(2));
                          }
                    }
                    let paymentDate = accomodation.BookingDate;
                    if (costRulesAccomodation[i].data.paymentDateType) {
                        if (costRulesAccomodation[i].data.paymentDateType.value == "check-in") {
                            paymentDate = accomodation.CheckInDate;
                        }
                        else if (costRulesAccomodation[i].data.paymentDateType.value == "check-out") {
                            paymentDate = accomodation.CheckOutDate;
                        }
                    }
                    await createCost(
                        {
                            name: costRulesAccomodation[i].data.name,
                            costDate: paymentDate,
                            paymentDate: paymentDate,
                            amount: commissionAccomodation,
                            costTypology: { label: t("Booking"), value: 2 },
                            costCategory: costRulesAccomodation[i].data.costCategory,
                            accomodationList: [accomodationReference.id],
                            bookingRef: bookingId,
                            costRuleRef: costRulesAccomodation[i].id,
                            supplierId: costRulesAccomodation[i].data.supplierId ? costRulesAccomodation[i].data.supplierId : null,
                            otaIncluded: costRulesAccomodation[i].data.otaIncluded ?? null,
                            extraServiceFee: costRulesAccomodation[i].data.extraServiceFee ?? null,
                            percentage: costRulesAccomodation[i].data.percentage ?? 0,
                            paymentType: costRulesAccomodation[i].data.paymentType.value ?? null,
                        },
                        context.organizationId
                    )
                } catch (e) {
                    bookingError.push({ error: t("ErrorCreationCost"), row: row });
                }
            }

            setBookingSucceded(bookingSucceded => bookingSucceded + 1);

            context.updateData("costs");
            context.updateData("bookings");
        }
        catch (e) {
            bookingError.push({ error: t("ErrorBookingCreation"), row: row });

        }

    };

    const UploadBookingsByFile = () => {

        const schema = {
            'Accomodation Name*': {
                prop: 'AccomodationName',
                type: String,
                required: true
            },
            'Booking Date*': {
                prop: 'BookingDate',
                required: true
            },
            'Check-In Date*': {
                prop: 'CheckInDate',
                required: true
            },
            'Check-Out Date*': {
                prop: 'CheckOutDate',
                required: true
            },
            'Channel (Leave empty if is direct)': {
                prop: 'Channel',
                type: String,
                required: false
            },
            'Total Amount*': {
                prop: 'Amount',
                type: Number,
                required: true
            },
            'Guests': {
                prop: 'Guests',
                type: Number,
                required: false
            },
            'Country': {
                prop: 'Country',
                type: String,
                required: false
            },
            'Reference': {
                prop: 'Reference',
                type: String,
                required: false
            },
            'Extras / Service fee': {
                prop: 'ExtraServiceFee',
                type: Number,
                required: false
            },
            
            
            
            
        }
        readXlsxFile(bookingFileUpload[0], { schema }).then(async ({ rows, errors }) => {
            setLoading(true);
            for (let i = 0; i < rows.length; i++) {
                await InsertBooking(rows[i], i + 2);

            }
            setCompleted(true);
            setLoading(false);
        })
    }

    return (
        loading ? <MiniLoadingApp /> : completed ?
            <div className="pt-5">
                <span className="text-xl m-2 text-petroil">{t("Completed")} {bookingSucceded} {t("inserts")}</span>
                {bookingError.length > 0 && (
                    <>
                        <h2 className="text-red text-base ml-2 mt-5 pt-3 pb-5">{t("Errors")}:</h2>
                        <div className="m-2 p-2 border max-h-40 overflow-y-auto">
                            {bookingError.map((error, index) => (
                                <div key={index} className="text-red-500">
                                    <span className="font-semibold">{t("Row")} {error.row}</span>: {error.error}
                                </div>
                            ))}
                        </div>
                    </>
                )}
                <div className="flex justify-end">
                    <TertiaryButton
                        className={"bg-red text-white p-2 w-fit min-w-[130px] mt-10 ml-4"}
                        content={t("Close")}
                        onClick={onClose}
                    />
                </div>

            </div>
            : <div className="mb-2">
                <TextField
                    type={"file"}
                    accept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
                    onChange={handleFileSelected}
                />
                <div className="flex justify-end">
                    <PrimaryButton
                        className={"bg-green text-black p-2 w-fit min-w-[130px] mt-10"}
                        content={"Upload"}
                        onClick={UploadBookingsByFile} />
                    <TertiaryButton
                        className={"bg-red text-white p-2 w-fit min-w-[130px] mt-10 ml-4"}
                        content={t("Close")}
                        onClick={onClose} />
                </div>
            </div>
    );
}

export default UploadBookings;