import React, { useContext, useState } from "react";
import SecondaryButton from "../../components/buttons/secondary-button";
import TextField from "../../components/input/fields/text-field";
import { SelectMenu } from "../../components/input/select/select-menu";
import { UnitField } from "../../components/input/fields/unit-field";
import PrimaryButton from "../../components/buttons/primary-button";
import { CurrencyField } from "../../components/input/fields/currency-field";
import { PercentField } from "../../components/input/fields/percent-field";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/dialogs/dialog";
import { NewOwnerForm } from "../../components/modals/new-owner";
import { DataContext } from "..";
import { useTranslation } from "react-i18next";
import { createAccomodation, createOwner, updateSubscriptionData } from "../../utils/firestore-functions";

export const NewAccomodation = () => {
  const { i18n, t } = useTranslation();
  const context = useContext(DataContext);
  const [modal, setOpenModal] = useState(false);
  const navigator = useNavigate();
  const ownersList = context.ownersList;
  const [oldSubscription, setOldSubscription] = useState(context.organizationInfo.subscription);
  const createdAccomodations = context.accomodationsList.length;

  const onCloseModal = () => {
    setOpenModal(false)
  };

  const [formValues, setFormValues] = useState({
    name: null,
    maxPeople: 0,
    numberRooms: 0,
    contractTypology: null,
    numberBathroom: 0,
    numberDoubleBed: 0,
    numberSingleBed: 0,
    touristTaxRate: 0.00,
    extraServiceFee: 0.00,
    occupancyRate: 0,
    annualRevenue: 0.00,
    basePrice: 0.00,
    address: {
      city: null,
      street: null,
      state: null,
      cap: null,
    },
    owner: null,
    status: null,
  });

  const statusTypology = [
    {
      label: t("Active"),
      value: "Active"
    },
    {
      label: t("Disabled"),
      value: "Disabled"
    }
  ];

  const contractTypology = [
    {
      label: t("FixedRent"),
      value: "fixed-rent",
    },
    {
      label: t("Revenue share"),
      value: "revenue-share",
    },
    {
      label: t("CommissionBased"),
      value: "commission-based"
    },
    {
      label: t("GuaranteedIncome"),
      value: "guaranteed-income"
    },
    {
      label: t("OnlyAgency"),
      value: "only-agency"
    },
    {
      label: t("FeeBased"),
      value: "fee-based"
    },
    {
      label: t("HybridModels"),
      value: "hybrid-models"
    },
    {
      label: t("TieredRevenueShare"),
      value: "tiered-revenue-share"
    },
    {
      label: t("PerformanceBasedBonus"),
      value: "performance-based-bonus"
    },
    {
      label: t("BookingFeeModel"),
      value: "booking-fee-model"
    },
    {
      label: t("FullServiceVariableFee"),
      value: "full-service-with-variable-fee"
    },
    {
      label: t("RetainerPlusCommission"),
      value: "retainer-plus-commission"
    },
    {
      label: t("EquityShareModel"),
      value: "equity-share-model"
    },
    {
      label: t("ZeroFeeUpsells"),
      value: "zero-fee-with-upsells"
    },
  ];


  const checkFields = () => {

    if (!formValues.name || formValues.name === "") {
      toast.error(t("AccommodationNameRequired"));
      return;
    }
    if (!formValues.status || formValues.status === "") {
      //If is not selected, change it in Active automatically
      formValues.status = { label: t("Active"), value: "Active" };
    }

    if (!formValues.address.city || formValues.address.city === "") {
      toast.error(t("AccommodationCityRequired"));
      return;
    }

    if (!formValues.owner || formValues.owner === "") {
      toast.error(t("AccommodationOwnerRequired"));
      return;
    }

    createAccomodation(formValues, context.organizationId).then(() => {

      // Add every time
      updateSubscriptionData({
        subscription: {
          amount: parseFloat(
            (
              oldSubscription.plan === "host"
                ? oldSubscription.amount
                : oldSubscription.properties >= 5
                  ? oldSubscription.amount + oldSubscription.amountExtra
                  : oldSubscription.amount
            ).toFixed(2)
          ),
          expired: oldSubscription.expired,
          plan: oldSubscription.plan,
          properties: oldSubscription.properties + 1,
          recurrency: oldSubscription.recurrency,
          amountExtra: oldSubscription.amountExtra,
          propertiesExtra: 0
        }
      }, context.organizationId).then(() => {
        context.updateData("organization-info");
      });
      context.updateData("accomodations");

      navigator("../accommodations");
    }).catch((error) => {
      toast.error(t("ErrorOccurred") + error);
    });
  };

  return (
    <>
      <div className="w-fu">
        <div className="mb-10">
          <h1 className="text-3xl">{t("NewAccommodationTitle")}</h1>
        </div>

        <div className="w-full max-w-5xl p-10 bg-white border-2 rounded-lg shadow-md">
          <div className="flex justify-between">
            <h2 className="text-2xl">{t("AccommodationDetails")}</h2>
            <SelectMenu
              className={"max-w-xs min-w-[250px] my-0 p-1 w-fit mb-3 mr-4 flex flex-col items-start justify-start"}
              isSearchable={false}
              options={statusTypology}
              placeholder={t("SelecttheStatusPlaceholder")}
              onChange={(value) => {
                formValues.status = value;
              }}
            />
          </div>
          <hr className="my-4 " />
          <div className="flex flex-wrap justify-start mb-5 ml-auto mr-auto h-fit">

            <TextField
              label={t("NameRequiredTitle")}
              inputClassName={"p-2 mr-32 max-w-xs min-w-[350px]"}
              placeholder={t("AccommodationNameTitle")}
              className={"w-fit mt-3 mr-4 flex flex-col"}
              onChange={(e) => {
                formValues.name = e.target.value;
              }}
            />

            <SelectMenu
              label={t("ContractTypology")}
              className={"max-w-xs min-w-[250px] py-0 my-0 w-fit mt-3 mr-4 flex flex-col"}
              isSearchable={false}
              options={contractTypology}
              placeholder={t("ContractTypologyPlaceholder")}
              onChange={(value) => {
                formValues.contractTypology = value;
              }}
            />

          </div>
          <hr className="my-4 " />
          <div className="flex flex-wrap mb-5  h-fit w-fit">
            <UnitField
              label={t("NumberRooms")}
              className={"w-1/4 mt-3 mr-12"}
              inputClassName={"w-fit p-2 max-w-[200px]"}
              placeholder={"0"}
              onChange={(e) => {
                formValues.numberRooms = Number(e.target.value);
              }}
            />
            <UnitField
              label={t("MaxPeopleNumber")}
              className={"w-1/4 mt-3 mr-12"}
              inputClassName={"w-fit p-2 max-w-[200px]"}
              placeholder={"0"}
              onChange={(e) => {
                formValues.maxPeople = Number(e.target.value);
              }}
            />
            <UnitField
              label={t("Number of double bed")}
              className={"w-1/4 mt-3 mr-12"}
              placeholder={"0"}
              inputClassName={"w-fit p-2 max-w-[200px]"}
              onChange={(e) => {
                formValues.numberDoubleBed = Number(e.target.value);
              }}
            />
            <UnitField
              label={t("SingleBed")}
              className={"w-1/4 mt-3 mr-12"}
              placeholder={"0"}
              inputClassName={"w-fit p-2 max-w-[200px]"}
              onChange={(e) => {
                formValues.numberSingleBed = Number(e.target.value);
              }}
            />
            <UnitField
              label={t("BathroomNumber")}
              className={"w-1/4 mt-3 mr-12"}
              placeholder={"0"}
              inputClassName={"w-fit p-2 max-w-[200px]"}
              onChange={(e) => {
                formValues.numberBathroom = Number(e.target.value);
              }}
            />
            <CurrencyField
              className={" mt-2 mr-12   max-w-[380px]"}
              placeholder={"0.00"}
              label={t("TouristTax")}
              inputClassName={"w-fit p-2 min-w-[150px]"}
              onChange={(e) =>
                formValues.touristTaxRate = Number(e.target.value)
              }
            />
            <CurrencyField
              className={" mt-2 mr-12   max-w-[380px]"}
              placeholder={"0.00"}
              label={t("BasePrice")}
              inputClassName={"w-fit p-2 min-w-[150px]"}
              onChange={(e) =>
                formValues.basePrice = Number(e.target.value)
              }
            />
            <CurrencyField
              className={" mt-2 mr-12   max-w-[380px]"}
              placeholder={"0.00"}
              label={t("ExtraServiceFee")}
              inputClassName={"w-fit p-2 min-w-[150px]"}
              onChange={(e) =>
                formValues.extraServiceFee = Number(e.target.value)
              }
            />
            <PercentField
              label={t("OccupancyRate")}
              className={" mt-2 mr-12   max-w-[380px]"}
              inputClassName={"w-fit p-2 min-w-[150px]"}
              placeholder={0}
              onChange={(e) =>
                formValues.occupancyRate = Number(e.target.value)
              }
            />
            <CurrencyField
              className={" mt-2 mr-12   max-w-[380px]"}
              placeholder={"0.00"}
              label={t("AnnualRevenue")}
              inputClassName={"w-fit p-2 min-w-[150px]"}
              onChange={(e) =>
                formValues.annualRevenue = Number(e.target.value)
              }
            />
          </div>

          <h3 className="text-xl">{t("AddressInfo")}</h3>
          <hr className="my-4 " />
          <div className="flex flex-wrap justify-between mb-4 ml-auto mr-auto h-fit">
            <div>
              <TextField
                label={t("Street")}
                className={"max-w-xs mr-10"}
                inputClassName={"p-2 "}
                placeholder={t("Insert")}
                onChange={(e) => {
                  formValues.address.street = e.target.value;
                }}
              />
            </div>
            <div>
              <TextField
                label={t("CityRequired")}
                inputClassName={"p-2"}
                className={"max-w-xs mr-10"}
                placeholder={t("Insert")}
                onChange={(e) => {
                  formValues.address.city = e.target.value;
                }}
              />
            </div>
            <div>
              <TextField
                label={t("StateProvince")}
                inputClassName={"p-2"}
                className={"max-w-xs mr-10"}
                placeholder={t("Insert")}
                onChange={(e) => {
                  formValues.address.state = e.target.value;
                }}
              />
            </div>
            <div>
            </div>
          </div>
              <TextField
                label={t("ZipPostalCode")}
                inputClassName={"p-2 max-w-[250px]"}
                className={"w-fit mr-10"}
                placeholder={t("Insert")}
                onChange={(e) => {
                  formValues.address.cap = e.target.value;
                }}
              />
          <h3 className="text-xl mt-8">{t("Ownership")}</h3>
          <hr className="my-4 " />
          <div className="flex items-end justify-start">
            <SelectMenu
              placeholder={t("SelectPlaceholder")}
              className={"max-w-xs mr-10"}
              label={t("OwnerRequired")}
              options={
                ownersList &&
                ownersList.map((item) => {
                  return {
                    label: item.data.lastName + " " + item.data.firstName,
                    value: item.id,
                  };
                })
              }
              onChange={(value) => {
                value ? (formValues.owner = value.value) : undefined;
              }}
            />
            <SecondaryButton
              content={t("AddNewOwner")}
              className={"w-fit p-2 mb-2 max-w-[80px]"}
              onClick={() => setOpenModal(true)}
            />
          </div>
        </div>
        <div className="flex justify-end w-full max-w-5xl">
          <SecondaryButton
            content={t("Go Back")}
            className={"mt-10 p-3 max-w-[8rem] mr-10"}
            onClick={() => navigator("../accommodations")}
          />

          {((oldSubscription.plan === "host" && createdAccomodations < 4) || oldSubscription.plan === "propertyManager") && (
            <PrimaryButton
              content={t("Add")}
              className={"mt-10 p-3 max-w-[16rem]"}
              onClick={checkFields}
            />
          )
          }

        </div>
      </div>

      <Modal
        externalControl
        status={modal}
        body={
          <NewOwnerForm
            onCloseModal={onCloseModal}
            onComplete={(owner) => {
              createOwner(owner, context.organizationId)
                .then(() => {
                  context.updateData("owners");
                  setOpenModal(false);
                })
                .catch((err) => {
                  toast.error(t("ErrorOccurred") + err);
                }
                );
            }}
          />
        }
        title={t("NewOwner")}
        className={""}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};
