import { sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { toast } from "react-toastify";
import { auth } from "../../../utils/firebaseConfig";
import PrimaryButton from "../../buttons/primary-button";
import Modal from "../dialog";
import { useTranslation } from "react-i18next";

export const PasswordResetDialog = () => {
  
  const { i18n, t } = useTranslation();
  const [email, setEmail] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const passwordResetHandler = async () => {
    if (!email) {
      toast.error(t("EmptyEmailField"));
      return;
    }
    await sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success(t("ResetEmailSent"));
        setIsOpen(false);
      })
      .catch((error) => {
        toast.error(t("CheckEmail") + error);
      });
  };
  return (
    <Modal
      status={isOpen}
      primaryButton
      buttonLabel={t("PasswordForget")}
      onClose={() => {setIsOpen(false); setEmail(null)}}
      buttonClassName={"w-32 text-black bg-transparent text-xs hover:underline"}
      body={
        <div>
          <div>
            <h6>
            {t("PasswordFill")}
              <br /> {t("IfRegisterReset")}
            </h6>
          </div>
          <div className="my-4">
            <label className="text-lg font-semibold text-petrol">Email</label>
            <input
              className="w-full h-10 my-1 bg-gray-100 border-2 rounded-lg outline-none indent-4 focus:border-petrol"
              placeholder={t("EmailPlaceholder")}
              type="text"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex justify-end mt-5">
            <PrimaryButton
              className={"p-2 w-fit max-w-[120px]"}
              onClick={passwordResetHandler}
              content={t("RetrieveAccount")}
            />
          </div>
        </div>
      }
      //TODO: da tradurre
      title={"Reset your password"}
      subtitle={"Write down you email to reset your email"}
    />
  );
};
