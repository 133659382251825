import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../dialogs/dialog";
import TertiaryButton from "../buttons/tertiary-button";
import { TermsAndConditions } from "../../resources/termsAndConditions";

const TermsAndConditionsStep = ({ lang, onComplete, onBack }) => {
  const { i18n, t } = useTranslation();
  const [termsAndConditionsContent, setTermsAndConditionsContent] = useState("");

  useEffect(() => {

    const handleChangeLanguage = () => {
      i18n.changeLanguage(lang);
    }

   
      setTermsAndConditionsContent(TermsAndConditions[lang]);
    
      handleChangeLanguage();
  }, [lang]);

  return (
    <>
      <div className="max-w-[80vw] py-4 px-2 rounded-xl mt-2">
        <h2 className="mt-4 text-center text-3xl text-petroil">{t("TermsAndConditions")}</h2>
        <div className="flex row mt-12 bg-white rounded-xl borderPetroil p-5" >
          <div className="col-md-7 col-12 h-[50vh] overflow-hidden overflow-y-auto" id="scroller">
            <div className="px-6">
            <p className="text-base mb-5" dangerouslySetInnerHTML={{ __html: termsAndConditionsContent }} />
            </div>
          </div>
        </div>
        <div className="flex row justify-between">
          <div className="col-md-6 justify-start">
          <button className="mt-10 p-3 max-w-[16rem] mr-10 w-full min-w-fit h-fit bg-petroil text-white rounded-lg text-base font-semibold hover:brightness-125" onClick={() => onBack(4)}>{t("Go Back")}</button>

          </div>

          <div className="col-md-6 justify-end">

            <TertiaryButton
            className={`bg-petroil py-3 px-10 w-fit mt-10 `}
            content={t("Accept")}
            onClick={() => {
              onComplete(6);
            }}
          />

          </div>
          
        </div>
      </div>
    </>
  );
};

export default TermsAndConditionsStep;
