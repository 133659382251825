import { useState } from "react";

import {
  createOrganization,
  setUserExtraInfo,
  getUserExtraInfo, logout
} from "../../utils/firestore-functions";
import { useNavigate } from "react-router-dom";
import LangStep from "../../components/initial-steps-organization/langStep";
import TermsAndConditionsStep from "../../components/initial-steps-organization/termsAndConditionsStep";
import CountryStep from "../../components/initial-steps-organization/countryStep";
import SubscriptionStep from "../../components/initial-steps-organization/subscriptionStep";
import CompanyStep from "../../components/initial-steps-organization/companyStep";
import { useTranslation } from "react-i18next";

const OrganizationSetup = () => {
  const [today, setToday] = useState(new Date());
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState({
    organizationName: "",
    organizationState: "",
    language: "",
    subscription: {
      expired: "",
      properties: 0,
      plan: "",
      amount: 0,
      recurrency: "",
      description: "",
      propertiesExtra: 0,
    },
    termsAndConditions: {
      dateAccept: "",
      accepted: ""
    }
  });
  const todayDate = new Date();
  todayDate.setDate(todayDate.getDate());
  

  
  const handleLogout = () => {
    logout().then(() => navigate('/login'));
  };

  const handleSubmit = () => {
    createOrganization({
      name: formValues.organizationName,
      state: formValues.organizationState,
      language: formValues.language,
      collaboratorList: "emailList",
      website: "",
      phoneNumber: "",
      companyEmail: "",
      address: {
        street: "",
        city: "",
        cap: "",
      },
      logoUrl: "",
      subscription: {
        amount: formValues.subscription.amount,
        expired: formValues.subscription.expired,
        amountExtra: formValues.subscription.amountExtra,
        plan: formValues.subscription.plan,
        properties: formValues.subscription.properties,
        recurrency: formValues.subscription.recurrency,
        propertiesExtra: formValues.subscription.propertiesExtra,
      },
      termsAndConditions: {
        dateAccept: formValues.termsAndConditions.dateAccept,
        accepted: formValues.termsAndConditions.accepted,

      },
      powerBiWorkspaceId: "",
      powerBiDataSetId: "",
      powerBiReportId: ""
    }).then((id) => {

      setUserExtraInfo({ organization: id, newsLetterAgreement: false }).then(
        window.location.replace('/dashboard')
      );
    });
  };


  return (

    <div>
      <a className="fixed top-4 right-8 font-bold cursor-pointer text-petroil text-base p-2" onClick={handleLogout}>
      {t("Logout")}
      </a>
      <div className="flex items-start justify-center h-fit mb-28">
        <div className="max-w-lg m-10">
          <div className="flex flex-col items-center justify-center px-[10%] text-petroil">
            <img
              className="h-auto w-fit"
              alt="Bilemon logo"
              src="/Logo-Petroil.png"
            />
            <p className="mt-4 text-center">{t("Welcome")} </p>
          </div>
          <div>
            <p className="mt-4 font-bold text-center text-petroil">
              {step}{t("FiveSteps")}
            </p>
          </div>
          {step == 1 && (
            <SubscriptionStep
              setFormValues={setFormValues}
              onComplete={() => setStep(2)}
              value={formValues}
            />

          )}
          {step == 2 && (
            <CompanyStep
              onComplete={(value) => setStep(value)}
              onChange={(value) => {
                (formValues.organizationName = value)
              }}
              value={formValues.organizationName}
            />
          )}
          {step == 3 && (
            <CountryStep
              onChange={(value) => (formValues.organizationState = value)}
              onComplete={(value) => setStep(value)}
              value={formValues.organizationState}
            />
          )}
          {step == 4 && (
            <LangStep
              onChange={(value) => {
                (formValues.language = value);
              }}
              onComplete={(value) => setStep(value)}
              value={formValues.language}
            />
          )}
          {step == 5 && (
            <TermsAndConditionsStep
              onBack={value => setStep(value)}
              onComplete={() => {
                formValues.termsAndConditions = {
                  accepted : true,
                  dateAccept : todayDate
                }
                
                handleSubmit();
              }}
              lang={formValues.language}
              
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrganizationSetup;
