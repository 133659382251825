import {React} from "react";


function PaymentRules() {
  return (
    <div>PaymentRules</div>
  );
}

export default PaymentRules;
