import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { SelectMenu } from "../../components/input/select/select-menu";
import SecondaryButton from "../buttons/secondary-button";
import { useTranslation } from "react-i18next";

const LangStep = ({ onComplete, onChange, value }) => {

  const { i18n, t } = useTranslation();

  const optionsLanguage = [
    {
      label: "English",
      value: "en"
    },
    {
      label: "Español",
      value: "es"
    },
    {
      label: "Francais",
      value: "fr"
    },
    {
      label: "Italian",
      value: "it"
    }
  ];


  const [langChoosed, setLang] = useState(optionsLanguage.filter(x => x.value == value)[0]);


  const handleSetData = (lang) => {
    const langSelected = optionsLanguage.filter(x => x.value == lang)[0];
    setLang(langSelected);
    onChange(value = lang);
  }


  return (
    <div className="mt-10 space-y-5">
      <SelectMenu label={"What language do you want to use?"}
        options={optionsLanguage}
        defaultValue={""}
        labelClassName={"text-4xl font-bold text-petroil mb-4"}
        className={"w-50 mr-5"}
        value={langChoosed}
        onChange={(e) => handleSetData(e.value) } />
      <div className="flex items-center justify-between w-full">
      <button className=" p-3 max-w-[16rem] mr-10 w-full min-w-fit h-fit bg-petroil text-white rounded-lg text-base font-semibold hover:brightness-125" onClick={() => onComplete(3)}>{t("Go Back")}</button>
        <SecondaryButton
          className={"py-3 px-10 w-fit"}
          content={"Next >"}
          onClick={() => {
            if(langChoosed) {
              onComplete(5)
            } else {
              toast.error("Invalid language");
            }
          }}
        />
      </div>
    </div>
  );
};

export default LangStep;