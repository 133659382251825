import React, { useContext, useState } from "react";
import { IoAdd, IoPencil, IoTrashBin } from "react-icons/io5";
import { DataContext } from "..";
import PrimaryButton from "../../components/buttons/primary-button";
import SecondaryButton from "../../components/buttons/secondary-button";
import Modal from "../../components/dialogs/dialog";
import { NewOtaCommission } from "../../components/modals/new-otaCommission";
import { setBookingProvider, updateBookingProvider, deleteBookingProvider, updateCostRule, updateCost, updateBooking } from "../../utils/firestore-functions";
import InfoButton from "../../components/buttons/info-button";
import TertiaryButton from "../../components/buttons/tertiary-button";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const OtaCommissionsSettings = () => {
  const { i18n, t } = useTranslation();
  const context = useContext(DataContext);
  const [editModal, setEditModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [otaCommissionSelected, setOtaCommissionSelected] = useState();
  const [removeModal, setRemoveModal] = useState(false);
  const otaCommissionList = context.bookingProvidersList;
  const costsRulesList = context.costsRuleList;
  const costsList = context.costsList;
  const bookingList = context.bookingList;

  const closeModal = () => {
    setCreateModal(false);
    setEditModal(false);
  };

  const handleCreateOtaCommission = (otaCommission) => {
    setOtaCommissionSelected(otaCommission);
    setCreateModal(true);
  };
  const handleChangeOtaCommission = (otaCommission) => {
    setOtaCommissionSelected(otaCommission);
    setEditModal(true);
  };

  const handleRemoveOtaCommission = (otaCommission) => {
    setOtaCommissionSelected(otaCommission);
    setRemoveModal(true);
  };
  return (
    <>
    
    <InfoButton nameComponent={'ota'}/>
      <div className="flex flex-col content-between w-full h-full mx-10 ">
        <div className="space-y-4 text-3xl font-bold">
          <div className="flex items-center justify-start">
            <h1>{t("OtaCommissionsManagement")}</h1>
          </div>
          <div className="flex flex-col p-5 space-y-4 font-normal bg-white rounded-lg shadow-md w-fit">
            <div className="my-1 space-y-2">
              <div className="flex flex-row justify-between">
              <div className="text-xl font-bold ">{t("General")}</div>
                <SecondaryButton content={<IoAdd className="mx-auto" />}
                        className={"p-1 mx-2 max-w-[100px]"}
                        onClick={() => {
                          handleCreateOtaCommission({
                            name: '',
                          });
                        }}
                      />
              </div>
              <hr className="w-full" />
            </div>
            <div className="z-10 inline-block border rounded-xl">
              {/* Table */}
              <table className="w-full max-w-6xl ">
                {/* Header */}
                <thead className="text-lg">
                  <tr className="border-b-2">
                    <th className="w-48 p-4 border-r ">{t("Name")}</th>
                    <th className="w-52">{t("Actions")}</th>
                  </tr>
                </thead>
                <tbody className="text-base">
                  {otaCommissionList &&
                    otaCommissionList.map((item) => {
                      return (
                        <tr
                          key={item.id}
                          className="border-b cursor-pointer hover:bg-neutral-100"
                        >
                          <td className="p-2 text-left border-r">
                            {item.data.name}
                          </td>                         

                          <td className="">
                            <div className="flex items-center justify-center">
                              <PrimaryButton
                                content={<IoPencil className="mx-auto" />}
                                className={"p-1 mx-2 max-w-[100px]"}
                                onClick={() =>
                                  handleChangeOtaCommission({
                                    id: item.id,
                                    name: item.data.name,
                                  })
                                }
                              />
                              <SecondaryButton
                                content={<IoTrashBin className="mx-auto" />}
                                className={"p-1 mx-2 max-w-[100px]"}
                                onClick={() => {
                                  handleRemoveOtaCommission({
                                    id: item.id,
                                    name: item.data.name
                                  });
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="font-bold absolute bottom-0 right-10 flex items-center justify-end p-4 text-petroil rounded-full ">
  <p className="mr-1">{t("NeedSupport")}</p>
  <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
  >
    {t("ContactUs")}
  </a>
</div>
      </div>
      <Modal
        externalControl
        title={t("NewOtaCommission")}
        body={
          <NewOtaCommission          
          onCloseModal={closeModal}
            onComplete={(value) => {
              setCreateModal(false);
              setBookingProvider(value, context.organizationId);
              context.updateData("booking-providers");
            }}
          />
        }
        status={createModal}
        onClose={() => setCreateModal(false)}
      />
      <Modal
        externalControl
        title={t("EditOtaCommission")}
        className={"max-w-[400px]"}
        body={
          <NewOtaCommission
          onCloseModal={closeModal}
          onComplete={(otaCommission) => {
              if (
                !(JSON.stringify(otaCommission) === JSON.stringify(otaCommissionSelected))
              )
                updateBookingProvider(
                  {
                    name: otaCommission.name
                  },
                  otaCommission.id,
                  context.organizationId
                )
                  .then(() => {
                    costsRulesList.filter( x => x.data.name === otaCommissionSelected.name ).map((item) => {
                      item.name = otaCommission.name;
                      updateCostRule(item, item.id, context.organizationId);
                      context.updateData("cost-rules");
                    })
                    
                    costsList.filter( y => y.data.name === otaCommissionSelected.name).map((item) => {
                      item.name = otaCommission.name;
                      updateCost(item, item.id, context.organizationId)
                      context.updateData("costs");
                    })

                    bookingList.filter( z => z.data.commissionChannel === otaCommission.name).map((item) => {
                      item.commissionChannel = otaCommission.name;
                      updateBooking(item, item.id, context.organizationId)
                      context.updateData("bookings")
                    })
                    
                    context.updateData("booking-providers");
                    setOtaCommissionSelected();
                    setEditModal(false);
                    
                  })
                  .catch((error) =>
                  {
                       toast.error("Error while updating rule. " + error);
                  });
            }}
            otaCommission={otaCommissionSelected}
          />
        }
        status={editModal}
        onClose={() => setEditModal(false)}
      />
      <Modal
        externalControl
        title={t("RemoveOtaCommission")}
        body={
          <>
            <h1 className="text-lg">{t("RemoveOtaCommissionQuestion")}</h1>
            <h2>{}</h2>
            <h2 className="flex items-end justify-center my-5">
              {t("LostOtaCommissionRelationship")}{" "}
              <p className="ml-2 text-base text-red">{t("LostConnection")}</p>.
            </h2>
            <div className="flex justify-end">
            <TertiaryButton
                className={"bg-slate-400 mr-2 text-white p-2 w-fit max-w-[130px] mt-2"}
                content={"Cancel"}
                onClick={() => {
                  setRemoveModal(false)}}
              />
              <TertiaryButton
                className={"bg-red text-white p-2 w-fit max-w-[130px] mt-2"}
                content={"Yes, I'm sure"}
                onClick={() => {
                  deleteBookingProvider(otaCommissionSelected.id, context.organizationId)
                    .then(() => {
                      context.updateData("booking-providers");
                      setRemoveModal(false);
                      setOtaCommissionSelected();
                    })
                    .catch((error) => toast.error("Error while deleting booking provider: " + error));
                }}
              />              
            </div>
          </>
        }
        status={removeModal}
        onClose={() => setRemoveModal(false)}
      />
    </>
  );
};
