import React, { useContext, useState } from "react";
import TextField from "../../components/input/fields/text-field";
import { EmailField } from "../../components/input/fields/email-filed";
import SecondaryButton from "../../components/buttons/secondary-button";
import { SelectMenu } from "../../components/input/select/select-menu";
import { DataContext } from "..";
import {
  getStorage,
  ref,
  getDownloadURL
} from "firebase/storage";
import { PhoneField } from "../../components/input/fields/phone-field";
import {
  getAuth,
  updateProfile,
  updateEmail,
  sendEmailVerification,
} from "firebase/auth";
import { setOrganizationInfo } from "../../utils/firestore-functions";
import { toast } from "react-toastify";
import { NeutralButton } from "../../components/buttons/neutral-button";
import { logout } from "../../utils/firestore-functions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function UserSettings(onChange, value) {

  const context = useContext(DataContext);
  const { i18n, t } = useTranslation();
  const auth = getAuth();
  const user = auth.currentUser;
  const storage = getStorage();
  const [displayName, setDisplayName] = useState(user.displayName);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phoneNumber);
  const navigator = useNavigate();
  const [langChoosed, setLang] = useState(context.organizationInfo.language);
  let isGoogleLinked = false;

  const optionsLanguage = [
    {
      label: t("English"),
      value: "en"
    },
    {
      label: t("Spanish"),
      value: "es"
    },
    {
      label: t("French"),
      value: "fr"
    },
    {
      label: t("Italian"),
      value: "it"
    }
  ];

  user.providerData.forEach((userInfo) => {
    if (userInfo.providerId == "google.com") {
      isGoogleLinked = true;
      return;
    }
  });

  try {
    const pathReference = ref(storage, user.photoURL);
    getDownloadURL(pathReference)
      .then((url) => {
        setHasProfilePic(1);
      })
      .catch((error) => {
        if (error.code === "storage/object-not-found") {
          setHasProfilePic(0);
        }
      });

    getDownloadURL(pathReference)
      .then((url) => {
        const img = document.getElementById("profilepicture");
        img.setAttribute("src", url);
      })
      .catch((error) => { });
  } catch (error) { }

  function uploadFiles() {
    document.getElementById("selectFileInput").click();
  }

  function validateStates() {
    setDisplayName(displayName.trim());
    setEmail(email.trim());
    setPhone(phone.trim());

    if (displayName !== "" && displayName !== user.displayName) {
      updateProfile(auth.currentUser, {
        displayName: `${displayName}`,
      })
        .then(() => {
          toast.success(t("DisplayNameUpdated"));
          setDisplayName("");
          document.getElementById("displayNameField").value = "";
        })
        .catch((error) => {
          toast.error(t("DisplayNameError"), error);
        });
    }

    if (phone !== "" && phone !== user.phoneNumber) {
      toast.error(t("PhoneNumberWrong"));
    }
    if (email !== "" && email !== user.email) {
      let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (regexEmail.test(email)) {
        updateEmail(auth.currentUser, email)
          .then(() => {
            toast.success(t("EmailUpdated"));
            setEmail("");
            sendEmailVerification(email);
            document.getElementById("emailField").value = "";
          })
          .catch((error) => {
            toast.error(t("ErrorChangingEmail") + error);
          });
      } else {
        document.getElementById("emailField").style.borderColor = "red";
      }
    }
    if (langChoosed && langChoosed != context.organizationInfo.language) {
      let orgInfo = { ...context.organizationInfo };
      orgInfo.language = langChoosed;
      setOrganizationInfo(orgInfo, context.organizationId, false)
      .then(() => {
        context.updateData("organization-info");
        i18n.changeLanguage(langChoosed, () => toast.success(t("LanguageChangedCorrectly")));
      })
      .catch((error) => {
        toast.error(t("ErrorChangingLanguage") + error);
      });
    }
  }

  return (
    <>

      <div className="flex flex-col content-between w-full ml-10 h-fit ">
        <div className="text-4xl font-bold ">{t("UserProfile")}</div>
        <div className="my-5 text-2xl font-normal">{t("General")}</div>
        <hr className="w-full mb-6" />
        <div className="big-screen:hidden font-bold w-[15rem] mb-5">{t("Details")}</div>
        <div className="flex flex-row justify-center big-screen:justify-start">
          <div className="hidden font-bold w-[15rem] big-screen:flex">
            {t("Details")}
          </div>

          {/* Card details */}

          <div className="w-[70vw] max-w-[30rem] min-w-[23rem] h-fit pb-6 border-2 rounded-lg bg-neutral-50">
            <div className="flex flex-col mx-6 mt-6">
              {t("DisplayName")}
              <div className="max-w-[85%] min-w-[60%] h-10 ">
                <TextField
                  id="displayNameField"
                  placeholder={t(" Insert")}
                  inputClassName="px-3 my-1 p-1 bg-white"
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col mx-6 mt-6">
              Email
              <div className="max-w-[85%] min-w-[60%] h-10">
                <EmailField
                  id="emailField"
                  placeholder={t(" Insert")}
                  inputClassName="px-3 my-1 p-1 bg-neutral-50"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col mx-6 mt-6">
              {t("PhoneNumber")}
              <div className="max-w-[85%] min-w-[60%] h-10">
                <PhoneField
                  placeholder={t(" Insert")}
                  inputClassName="px-3 my-1 p-1 bg-neutral-50 "
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>

            </div>
            <div className="flex flex-col mx-6 mt-6">
              {t("Language")}
              <div className="max-w-[85%] min-w-[60%] h-10">
                <SelectMenu
                  options={optionsLanguage}
                  defaultValue={""}
                  labelClassName={"text-4xl font-bold text-petroil mb-4"}
                  className={"w-50 mr-5"}
                  value={optionsLanguage.find(x => x.value == langChoosed)}
                  onChange={(e) => setLang(e.value)}
                />
              </div>
            </div>
            <hr className="w-[90%] my-6 ml-[5%]" />
            <div className="flex justify-between mx-6 mt-6">
              <div className="flex items-center justify-center">
                <SecondaryButton
                  content={t("Update")}
                  className="w-[5.5rem] h-[2.6rem] text-sm"
                  onClick={validateStates}
                />
                <SecondaryButton
                  content={t("Cancel")}
                  className="w-[5.5rem] h-[2.6rem] mx-6 bg-white text-sm"
                  onClick={() => {
                    setEmail("");
                    setDisplayName("");
                    setPhone("");
                  }}
                />
              </div>

              <NeutralButton
                content={t("Logout")}
                className={"w-[5.5rem] h-[2.6rem] mx-6 text-red text-sm"}
                onClick={() => {
                  logout().then(() => navigator("/"));
                }}
              />
            </div>
          </div>
        </div>
        <hr className="w-full my-6" />

        <div className="big-screen:hidden font-bold w-[15rem] mb-5">
          {t("AccessProviders")}
        </div>

        <div className="flex flex-col">
          <div className="flex items-center justify-start"></div>
        </div>
      </div>
    </>
  );
}

export default UserSettings;