import React from "react";
import { SelectMenu } from "./select-menu";
import { useTranslation } from "react-i18next";

const SubCategorySelect = ({ onChange, type, value, className }) => {
  const { i18n, t } = useTranslation();

  const accomodationOptions = [

    {
      value: "support-owner",
      label: t("SupportOwner"),
    },
    {
      value: "accessories-appliances",
      label: t("AccessoriesAppliances"),
    },
    {
      value: "channels-manager",
      label: t("ChannelsManager"),
    },
    {
      value: "community-fees",
      label: t("CommunityFees"),
    },
    {
      value: "direct-marketing-acc",
      label: t("DirectMarketing"),
    },
    {
      value: "dynamic-pricing-tool",
      label: t("DynamicTool"),
    },
    {
      value: "furnitures",
      label: t("Furnitures"),
    },
    {
      value: "home-owner-payment",
      label: t("HomeOwnerPayment"),
    },
    {
      value: "host-happiness",
      label: t("HostHappiness"),
    },
    {
      value: "insurance",
      label: t("Insurance"),
    },
    {
      value: "ListingPeparation",
      label: t("ListingPeparation"),
    },
    {
      value: "maintenance",
      label: t("Maintenance"),
    },
    {
      value: "materials",
      label: t("Materials"),
    },
    {
      value: "payment-support",
      label: t("PaymentSupport"),
    },
    {
      value: "photo-shooting",
      label: t("PhotoShooting"),
    },
    {
      value: "pms",
      label: t("Pms"),
    },
    {
      value: "property-acquisition-cost",
      label: t("PropertyCost"),
    },
    {
      value: "rubbish-removal",
      label: t("RubbishRemoval"),
    },
    {
      value: "shopping",
      label: t("Shopping"),
    },
    {
      value: "supplies",
      label: t("Supplies"),
    },
    {
      value: "utilities",
      label: t("Utilities"),
    },
    {
      value: "other",
      label: t("Other"),
    },
  ];

  const bookingOption = [
    {
      value: "amenities",
      label: t("Amenities"),
    },
    {
      value: "checkin-keyhandling",
      label: t("CheckinKeyhandling"),
    },
    {
      value: "Checkout",
      label: t("Checkout"),
    },
    {
      value: "cleaning",
      label: t("Cleaning"),
    },
    {
      value: "cleaning-products",
      label: t("CleaningProducts"),
    },
    {
      value: "direct-marketing",
      label: t("DirectMarketing"),
    },
    {
      value: "GuestCompensation",
      label: t("GuestCompensation"),
    },
    {
      value: "GuestHappiness",
      label: t("GuestHappiness"),
    },
    {
      value: "home-owner-payment",
      label: t("HomeOwnerPayment"),
    },
    {
      value: "linen-laundry",
      label: t("LinenLaundry"),
    },
    {
      value: "ota-commission",
      label: t("OTACommission"),
    },
    {
      value: "payment-processor-fees",
      label: t("PaymentProcessorFees"),
    },
    {
      value: "shopping",
      label: t("Shopping"),
    },
    {
      value: "tourist-tax-software",
      label: t("TouristTaxSoftware"),
    },
    {
      value: "other",
      label: t("Other"),
    },
  ];

  const organizationOption = [
    {
      value: "accounting",
      label: t("Accounting"),
    },
    {
      value: "administrative-expenses",
      label: t("AdministrativeExpenses"),
    },
    {
      value: "advertising",
      label: t("Advertising"),
    },
    {
      value: "bank-commission",
      label: t("BankCommission"),
    },
    {
      value: "bank-credit",
      label: t("BankCredit"),
    },
    {
      value: "bi",
      label: t("Bi"),
    },
    {
      value: "bonus-benefit",
      label: t("BonusBenefit"),
    },
    {
      value: "company-insurance",
      label: t("CompanyInsurance"),
    },
    {
      value: "consulting-fees",
      label: t("ConsultingFees"),
    },
    {
      value: "crm",
      label: t("Crm"),
    },
    {
      value: "digital-marketing-campaign",
      label: t("DigitalMarketingCampaign"),
    },
    {
      value: "employee-happiness",
      label: t("EmployeeHappiness"),
    },
    {
      value: "events",
      label: t("Events"),
    },
    {
      value: "food",
      label: t("Food"),
    },
    {
      value: "fuel",
      label: t("Fuel"),
    },
    {
      value: "general-expenses",
      label: t("GeneralExpenses"),
    },
    {
      value: "legal-expenses",
      label: t("LegalExpenses"),
    },
    {
      value: "market-data",
      label: t("MarketData"),
    },
    {
      value: "media",
      label: t("Media"),
    },
    {
      value: "meeting-expenses",
      label: t("MeetingExpenses"),
    },
    {
      value: "office-expenses",
      label: t("OfficeExpenses"),
    },
    {
      value: "operation-issues",
      label: t("OperationIssues"),
    },
    {
      value: "other-expenses",
      label: t("OtherExpenses"),
    },
    {
      value: "other-technology",
      label: t("OtherTechnology"),
    },
    {
      value: "partnership-referrals",
      label: t("PartnershipReferrals"),
    },
    {
      value: "recruitment",
      label: t("Recruitment"),
    },
    {
      value: "referral-program",
      label: t("ReferralProgram"),
    },
    {
      value: "rent",
      label: t("Rent"),
    },
    {
      value: "rent-storage",
      label: t("RentStorage"),
    },
    {
      value: "research",
      label: t("Research"),
    },
    {
      value: "retargeting",
      label: t("Retargeting"),
    },
    {
      value: "seo-marketing",
      label: t("SeoMarketing"),
    },
    {
      value: "services",
      label: t("Services"),
    },
    {
      value: "services-owners-cost",
      label: t("ServicesOwnersCost"),
    },
    {
      value: "software",
      label: t("Software"),
    },
    {
      value: "staff",
      label: t("Staff"),
    },
    {
      value: "staff-recognition",
      label: t("StaffRecognition"),
    },
    {
      value: "staff-training-welfare",
      label: t("StaffTrainingWelfare"),
    },
    {
      value: "subscriptions",
      label: t("Subscriptions"),
    },
    {
      value: "telephone-internet",
      label: t("TelephoneInternet"),
    },
    {
      value: "transport-travel",
      label: t("TransportTravel"),
    },
    {
      value: "other",
      label: t("Other"),
    },
  ];


  return (
    <SelectMenu
      label={t("CategoryRequired")}
      options={
        type
          ? (type.value === 0 && accomodationOptions) ||
          (type.value === 1 && organizationOption) ||
          (type.value === 2 && bookingOption)
          : null
      }
      placeholder={t("SelectPlaceholder")}
      onChange={onChange}
      value={value}
      className={className + " max-w-[320px]"}
    />
  );
};

export default SubCategorySelect;
