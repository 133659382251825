import { React, useContext } from "react";
import { format } from "date-fns";
import { it } from "date-fns/locale";

import { DataContext } from "..";
import TertiaryButton from "../../components/buttons/tertiary-button";
import { useTranslation } from "react-i18next";

const BookingView = ({ selectedBooking, onClose }) => {
  const { i18n, t } = useTranslation();
  const context = useContext(DataContext);

  const formattedBookingDate = format(selectedBooking.bookingDate, "dd/MM/yyyy", { locale: it });
const formattedCheckInDate = format(selectedBooking.checkInDate, "dd/MM/yyyy", { locale: it });
const formattedCheckOutDate = format(selectedBooking.checkOutDate, "dd/MM/yyyy", { locale: it });

  function formatNumberWithComma(number) {
    if(number == null || number == undefined) { 
      return 0; 
    }
    return number.toString().replace('.', ',');
  }
  const accomodationName = context.accomodationsList.find((item) =>
      item.id === selectedBooking.accomodationReference)?.data.name;

  // const selectedAccomodation = context.accomodationsList.filter(x => selectedBooking.accomodationList && selectedBooking.accomodationList.filter(accomodation => x && x.id == accomodation).length > 0);
  return (
    <>
    <div className="text-base">
      {selectedBooking.bookingId && (

      <div className="flex items-center space-x-2">
        <h4 className="font-bold">{t("BookingID")}:</h4>
        <p> {selectedBooking.bookingId}</p>
      </div>
      )}
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">{t("Accomodation")}:</h4>
        <p> {accomodationName}</p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">{t("Reference")}:</h4>
        <p> {selectedBooking.bookingReference}</p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">{t("BookingDate")}: </h4>
        <p> {formattedBookingDate}</p> 
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">{t("CheckInDate")}: </h4>
         <p> {formattedCheckInDate}</p> 
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">{t("CheckOutDate")}: </h4>
         <p> {formattedCheckOutDate}</p> 
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">{t("Guests")}: </h4>
        <p> {selectedBooking.numberOfGuest}</p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">{t("Country")}: </h4>
        <p> {selectedBooking.country}</p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">{t("amount")}: </h4>
        <p>{selectedBooking.amount ? "€ " + formatNumberWithComma(selectedBooking.amount): ""}</p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">{t("AccommodationFare")}: </h4>
        <p>{selectedBooking.accommodationFare ? "€ " + formatNumberWithComma(selectedBooking.accommodationFare): ""}</p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">{t("ExtraServiceFee")}: </h4>
        <p>{selectedBooking.extraServiceFee ? "€ " + formatNumberWithComma(selectedBooking.extraServiceFee): ""}</p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">{t("channel")}: </h4>
        <p>  {selectedBooking.commissionChannel = selectedBooking.commissionChannel ? selectedBooking.commissionChannel : "Direct" }</p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">{t("channelCommission")}: </h4>
        <p>€ {selectedBooking.commissionAmount ? formatNumberWithComma(selectedBooking.commissionAmount) : 0 }</p>
      </div>
    </div>
          <div className="flex justify-end">
          <TertiaryButton
            content={t("Close")}
            className={"bg-red text-white p-2 w-fit max-w-[130px] mt-2"}
            onClick={onClose}
          />
        </div>
        </>
  );
};

export default BookingView;
