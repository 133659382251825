import React, { useContext, useState } from "react";
import { Tab } from "@headlessui/react";
import TextField from "../../components/input/fields/text-field";
import PrimaryButton from "../../components/buttons/primary-button";
import { UnitField } from "../../components/input/fields/unit-field";
import { CurrencyField } from "../../components/input/fields/currency-field";
import { PercentField } from "../../components/input/fields/percent-field";
import { SelectMenu } from "../../components/input/select/select-menu";
import { DataContext } from "..";
import TertiaryButton from "../../components/buttons/tertiary-button";
import { updateAccomodation } from "../../utils/firestore-functions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const EditAccomodation = ({ accomodationInfo, onClose }) => {
  const { t } = useTranslation();
  const context = useContext(DataContext);
  const [index, setIndex] = useState(0);
  const [name, setName] = useState(accomodationInfo.name);
  const [numberRooms, setNumberRooms] = useState(accomodationInfo.numberRooms);
  const [maxPeople, setMaxPeople] = useState(accomodationInfo.maxPeople);
  const [numberBathroom, setNumberBathroom] = useState(accomodationInfo.bathroom);
  const [numberDoubleBed, setNumberDoubleBed] = useState(accomodationInfo.doubleBed);
  const [numberSingleBed, setNumberSingleBed] = useState(accomodationInfo.singleBed);
  const [touristTaxRate, setTouristTaxRate] = useState(accomodationInfo.tax);
  const [city, setCity] = useState(accomodationInfo.address.city);
  const [street, setStreet] = useState(accomodationInfo.address.street);
  const [state, setState] = useState(accomodationInfo.address.state);
  const [cap, setCap] = useState(accomodationInfo.address.cap);
  const [owner, setOwner] = useState(accomodationInfo.owner);
  const [status, setStatus] = useState(accomodationInfo.status);
  const [contractType, setContractTypology] = useState(accomodationInfo.contractTypology);
  const [basePrice, setBasePrice] = useState(accomodationInfo.basePrice);
  const [extraServiceFee, setServiceFee] = useState(accomodationInfo.extraServiceFee);
  const [occupancyRate, setOccupancyRate] = useState(accomodationInfo.occupancyRate);
  const [annualRevenue, setAnnualRevenue] = useState(accomodationInfo.annualRevenue);

  var ownersOptions = null;

  const formValues = {
    name: name,
    maxPeople: maxPeople ?? 0,
    numberRooms: numberRooms ?? 0,
    contractTypology: contractType,
    numberBathroom: numberBathroom ?? 0,
    numberDoubleBed: numberDoubleBed ?? 0,
    numberSingleBed: numberSingleBed ?? 0,
    touristTaxRate: touristTaxRate ?? 0.00,
    extraServiceFee: extraServiceFee ?? 0.00,
    occupancyRate: occupancyRate ?? 0,
    annualRevenue: annualRevenue ?? 0.00,
    basePrice: basePrice ?? 0.00,
    address: {
      city: city,
      street: street,
      state: state,
      cap: cap,
    },
    owner: owner,
    status: status,
  };

  //#region Options Select
  const contractTypology = [
    {
      label: t("FixedRent"),
      value: "fixed-rent",
    },
    {
      label: t("Revenue share"),
      value: "revenue-share",
    },
    {
      label: t("CommissionBased"),
      value: "commission-based"
    },
    {
      label: t("GuaranteedIncome"),
      value: "guaranteed-income"
    },
    {
      label: t("OnlyAgency"),
      value: "only-agency"
    },
    {
      label: t("FeeBased"),
      value: "fee-based"
    },
    {
      label: t("HybridModels"),
      value: "hybrid-models"
    },
    {
      label: t("TieredRevenueShare"),
      value: "tiered-revenue-share"
    },
    {
      label: t("PerformanceBasedBonus"),
      value: "performance-based-bonus"
    },
    {
      label: t("BookingFeeModel"),
      value: "booking-fee-model"
    },
    {
      label: t("FullServiceVariableFee"),
      value: "full-service-with-variable-fee"
    },
    {
      label: t("RetainerPlusCommission"),
      value: "retainer-plus-commission"
    },
    {
      label: t("EquityShareModel"),
      value: "equity-share-model"
    },
    {
      label: t("ZeroFeeUpsells"),
      value: "zero-fee-with-upsells"
    },
  ];

  
  const statusTypology = [
    {
      label: t("Active"),
      value: "Active"
    },
    {
      label: t("Disabled"),
      value: "Disabled"
    }
  ]
  //#endregion
  //#region Checking fields
  const update = () => {
    if (
      formValues.name === accomodationInfo.name &&
      formValues.address.cap === accomodationInfo.address.cap &&
      formValues.address.city === accomodationInfo.address.city &&
      formValues.address.state === accomodationInfo.address.state &&
      formValues.address.street === accomodationInfo.address.street &&
      formValues.owner === accomodationInfo.owner &&
      formValues.numberBathroom === accomodationInfo.bathroom &&
      formValues.numberDoubleBed === accomodationInfo.doubleBed &&
      formValues.numberSingleBed === accomodationInfo.singleBed &&
      formValues.touristTaxRate === accomodationInfo.tax &&
      formValues.maxPeople === accomodationInfo.maxPeople &&
      formValues.contractTypology === accomodationInfo.contractTypology &&
      formValues.status === accomodationInfo.status &&
      formValues.numberRooms === accomodationInfo.numberRooms &&
      formValues.basePrice === accomodationInfo.basePrice &&
      formValues.extraServiceFee === accomodationInfo.extraServiceFee &&
      formValues.occupancyRate === accomodationInfo.occupancyRate &&
      formValues.annualRevenue === accomodationInfo.annualRevenue
    ) {
      toast.error(t("NoChangesMade"));
      return;
    }

    if (formValues.name === "") {
      toast.error(t("AccommodationNameRequired"));
      return;
    }

    if (city === "") {
      toast.error(t("AccommodationCityRequired"));
      return;
    }

    if (!formValues.owner) {
      toast.error(t("AccommodationOwnerRequired"));
      return;
    }

    updateAccomodation(accomodationInfo.id, formValues, context.organizationId)
      .then(() => {
        context.updateData("accomodations");
        onClose();
      })
      .catch((err) => {
        toast.error(t("AnErrorOccurred") + ": " + err);
      });
  };
  //#endregion

  if (!useContext(DataContext).loading) {
    ownersOptions = context.ownersList.map((item) => {
      if (item.id != accomodationInfo.id)
        return {
          label: item.data.lastName + " " + item.data.firstName,
          value: item.id,
        };
    });
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  let categories = {
    [t("Details")]: [
      {
        input: (
          <>
            <TextField
              label={t("Name")}
              inputClassName={"p-1 mb-5"}
              placeholder={t("AccommodationName")}
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
            />
            <SelectMenu
            label={t("Owner")}
            options={ownersOptions}
            isSearchable
            onChange={(e) => setOwner(e.value)}
            defaultValue={ownersOptions.find((x) => {
              return x.value === owner;
            })}
          />

            <SelectMenu
              label={t("ContractTypology")}
              className={"ml-0 mb-5"}
              isSearchable={false}
              isClearable={false}
              defaultValue={contractType}
              options={contractTypology}
              placeholder={t("SelectPlaceholder")}
              onChange={(e) => setContractTypology(e)}
            />
            <SelectMenu
              label={t("Status")}
              className={"ml-0 mb-5"}
              isSearchable={false}
              isClearable={false}
              defaultValue={status}
              options={statusTypology}
              placeholder={t("SelectStatusPlaceholder")}
              onChange={(e) => setStatus(e)}
            />
          </>
        ),
      },
      {
        input: (
          <div className="flex flex-wrap justify-around my-1">
            <div className="flex justify-between items-end w-full">
              <UnitField
                label={t("NumberofRooms")}
                className={"w-[150px]"}
                inputClassName={"w-[130px] p-1"}
                placeholder={"0"}
                defaultValue={numberRooms}
                onChange={(e) => setNumberRooms(Number(e.target.value))}
              />
              <UnitField
                label={t("MaxPeopleNumber")}
                inputClassName={"w-[130px] p-1"}
                placeholder={"0"}
                defaultValue={maxPeople}
                className={"w-[150px]"}
                onChange={(e) => setMaxPeople(Number(e.target.value))}
              />
              <UnitField
                label={t("NumberDoublebed")}
                inputClassName={"w-[130px] p-1"}
                placeholder={"0"}
                defaultValue={numberDoubleBed}
                className={"w-[150px]"}
                onChange={(e) => setNumberDoubleBed(Number(e.target.value))}
              />
            </div>
            <div className="flex justify-between w-full my-1 mt-10 mb-4">
              <UnitField
                label={t("NumberSinglebed")}
                inputClassName={"w-[130px] p-1"}
                placeholder={"0"}
                defaultValue={numberSingleBed}
                className={"w-[150px]"}
                onChange={(e) => setNumberSingleBed(Number(e.target.value))}
              />
              <UnitField
                label={t("NumberBathroom")}
                inputClassName={"w-[130px] p-1 "}
                placeholder={"0"}
                defaultValue={numberBathroom}
                className={"w-[150px]"}
                onChange={(e) => setNumberBathroom(Number(e.target.value))}
              />
              <CurrencyField
                label={t("TouristTax")}
                inputClassName={"w-[100px] p-1"}
                placeholder={"0.00"}
                value={touristTaxRate}
                className={"w-[150px]"}
                onChange={(e) => setTouristTaxRate(Number(e.target.value))}
              />
            </div>
            
          </div>
        ),
      },
    ],
    [t("Address")]: [
      {
        input: (
          <TextField
            label={t("Street")}
            inputClassName={"p-1 mb-4"}
            placeholder={t("Insert")}
            defaultValue={street}
            onChange={(e) => setStreet(e.target.value)}
          />
        ),
      },
      {
        input: (
          <div className="flex flex-wrap">
            <TextField
              label={t("City")}
              inputClassName={"p-1 mb-4"}
              placeholder={t("Insert")}
              defaultValue={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <TextField
              label={t("StateProvince")}
              inputClassName={"p-1 mb-4"}
              placeholder={t("Insert")}
              defaultValue={state}
              onChange={(e) => setState(e.target.value)}
            />
            <TextField
              label={t("ZipPostalCode")}
              inputClassName={"p-1 mb-4"}
              placeholder={t("Insert")}
              defaultValue={cap}
              onChange={(e) => setCap(e.target.value)}
            />
          </div>
        ),
      },
    ],
    [t("Costs")]: [
      {
        input: (
          
          <div className="flex flex-wrap w-full items-end  mb-10">
              <CurrencyField
                className={"min-w-[190px] p-1 mr-10"}
                placeholder={"0.00"}
                value={basePrice}
                label={t("BasePrice")}
                inputClassName={"w-fit"}
                onChange={(e) =>
                  setBasePrice(Number(e.target.value))
                }
              />
              <CurrencyField
                className={"min-w-[190px] p-1 "}
                placeholder={"0.00"}
                value={extraServiceFee}
                label={t("ExtraServiceFee")}
                inputClassName={"w-fit"}
                onChange={(e) =>
                  setServiceFee(Number(e.target.value))
                }
              />
            
              <PercentField
                label={t("OccupancyRate")}
                className={"min-w-[190px] p-1 mr-10"}
                inputClassName={"w-fit"}
                placeholder={"0.00"}
                value={occupancyRate}
                onChange={(e) =>
                  setOccupancyRate(Number(e.target.value))
                }
              />
              <CurrencyField
                className={"min-w-[190px] p-1"}
                placeholder={"0.00"}
                label={t("AnnualRevenue")}
                value={annualRevenue}
                inputClassName={"w-fit"}
                onChange={(e) =>
                  setAnnualRevenue(Number(e.target.value))
                }
              />
            </div>
        ),
      },
    ],
  };

  return (
    <div className="w-full">
      <Tab.Group selectedIndex={index} onChange={(value) => setIndex(value)}>
        <Tab.List className="flex p-1 space-x-1 rounded-xl bg-blue-900/20 bg-neutral-50 text-blue">
          {Object.keys(categories).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                  selected
                    ? "shadow bg-green font-extrabold"
                    : "text-blue-100 hover:bg-white/[0.12] hover:text-petroil"
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="my-1 min-h-[25rem]">
          {Object.values(categories).map((formValues, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames(
                "rounded-xl bg-white p-3",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
              )}
            >
              {formValues.map((item, key) => (
                <div key={key}>{item.input}</div>
              ))}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
      <div className="flex justify-end">
        <TertiaryButton
          onClick={onClose}
          className={"bg-red p-2 w-fit mr-5 max-w-[80px]"}
          content={t("Close")}
        />
        <PrimaryButton
          className={"p-2 w-fit h-auto bg-green text-blue max-w-[80px]"}
          content={t("Update")}
          onClick={update}
        />
      </div>
    </div>
  );
};

export default EditAccomodation;