import { React, useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { toast } from "react-toastify";
import PrimaryButton from "../buttons/primary-button";
import TertiaryButton from "../buttons/tertiary-button";
import TextField from "../input/fields/text-field";
import { useTranslation } from "react-i18next";

export const NewOwnerForm = ({ onComplete, owner, onCloseModal }) => {
  const { i18n, t } = useTranslation();
  const [hasVat, setHasVat] = useState(owner ? owner.hasVat : false);
  const [firstName, setFirstName] = useState(owner ? owner.firstName : '');
  const [lastName, setLastName] = useState(owner ? owner.lastName : '');
  const checkValues = () => {
    if (!firstName.trim()) {
      setFirstName('');
      toast.error(t("NameRequired"));
      return false;
    }
    if (!lastName.trim() && !hasVat) {
      setLastName('');
    }
    return true;
  };
  return (
    <div className="flex flex-col justify-start">
      <div>
        <TextField
          label={t("Name")}
          defaultValue={firstName}
          placeholder={t("Insert")}
          inputClassName={"p-1 pr-10 mr-10"}
          className={"max-w-xs"}
          onChange={(e) => setFirstName(e.target.value)}
        />
        {!hasVat && (
          <TextField
            label={t("LastName")}
            defaultValue={lastName}
            placeholder={t("Insert")}
            inputClassName={"p-1 pr-10 mr-10"}
            className={"max-w-xs"}
            onChange={(e) => setLastName(e.target.value)}
          />
        )}
        <FormControlLabel
          className="mt-3"
          control={
            <Switch
              value={hasVat}
              checked={hasVat}
              onChange={() => setHasVat(!hasVat)}
            />
          }
          label={t("HasVAT")}
        />
      </div>
      <div className="flex justify-end mt-4">
        <TertiaryButton
          onClick={onCloseModal}
          className={"bg-red p-2 w-fit mr-5"}
          content={t("Close")}
        />
        <PrimaryButton
          className={"w-fit p-2 "}
          content={(owner && (owner.firstName !== '' && owner.lastName !== '')) ? t("Edit"): t("Add")}
          
          onClick={() => {
            if (checkValues())
              owner
                ? onComplete({
                  id: owner.id,
                  firstName: firstName,
                  lastName: lastName,
                  hasVat: hasVat,
                })
                : onComplete({
                  firstName: firstName,
                  lastName: lastName,
                  hasVat: hasVat,
                });
          }}
        />
      </div>
    </div>
  );
};
