import React, { useContext, useRef, useState } from "react";
import SecondaryButton from "../../components/buttons/secondary-button";
import { CurrencyField } from "../../components/input/fields/currency-field";
import { NewDatePicker } from "../../components/input/fields/date-pickers/newDatePicker";
import TextField from "../../components/input/fields/text-field";
import { UnitField } from "../../components/input/fields/unit-field";
import { PercentField } from "../../components/input/fields/percent-field";
import PrimaryButton from "../../components/buttons/primary-button";
import { useNavigate } from "react-router-dom";
import { SelectMenu } from "../../components/input/select/select-menu";
import Modal from "../../components/dialogs/dialog";
import { toast } from "react-toastify";
import {
    createCostRule,
    setSupplier,
    setBookingProvider
} from "../../utils/firestore-functions";
import { DataContext } from "..";
import { MdOutlinePostAdd } from "react-icons/md";
import { NewSupplier } from "../../components/modals/new-supplier";
import { NewBookingProvider } from "../../components/modals/new-booking-provider";
import SubCategorySelect from "../../components/input/select/sub-category-select";
import { useTranslation } from "react-i18next";

export const NewCostRule = () => {
    const { i18n, t } = useTranslation();
    const context = useContext(DataContext);
    const accommodationsList = context.accomodationsList;
    const [costTypology, setCostTypology] = useState(null);
    const [costCategory, setCostCategory] = useState(null);
    const [selectedSecondaryMenu, setSelectedSecondaryMenu] = useState();
    const [openNewSupplierModal, setOpenNewSupplierModal] = useState();
    const [openNewBookingProviderModal, setOpenNewBookingProviderModal] = useState();
    const [paymentType, setPaymentType] = useState(null);
    const [paymentDateType, setPaymentDateType] = useState(null);
    const [recurrencyType, setRecurrencyType] = useState(null);
    const [recurrencyDate, setRecurrencyDate] = useState(null);
    const [otaIncluded, setOtaIncluded] = useState(null);


    const [formValues, setFormValues] = useState({
        costrulename: null,
        name: null,
        amount: null,
        costTypology: null,
        costCategory: null,
        accomodationList: null,
        recurrency: null,
        recurrencyCustom: null,
        extraServiceFee: null,
        otaIncluded: null,
        supplierId: null,
        paymentType: null,
        paymentDateType: null,
        creationDate: null,
        executionDate: null,
        endDate: null

    });
    const closeModal = () => {
        setOpenNewSupplierModal(false);
    };

    const navigator = useNavigate("");
    const costTypologyOptions = [
        {
            label: t("Accommodation"),
            value: 0,
        },
        {
            label: t("Company"),
            value: 1,
        },
        {
            label: t("Booking"),
            value: 2,
        },
    ];

    const paymentTypeOptions = [
        {
            label: t("Fixed"),
            value: "Fixed",
        },
        {
            label: t("Percentage"),
            value: "Percentage",
        }
    ];

    const otaCommissionOptions = [
        {
            label: t("Included"),
            value: "Included",
        },
        {
            label: t("Excluded"),
            value: "Excluded",
        }
    ];

    const includingOptions = [
        {
            label: t("Included"),
            value: "Included",
        },
        {
            label: t("Excluded"),
            value: "Excluded",
        }
    ];

    const recurrencyOption = [
        {
            label: t("Daily"),
            value: "daily"
        },
        {
            label: t("Weekly"),
            value: "weekly"
        },
        {
            label: t("Monthly"),
            value: "monthly",
        },
        {
            label: t("Yearly"),
            value: "yearly",
        },
        {
            label: t("Custom"),
            value: "custom"
        }
    ];

    const costDateOption = [
        {
            label: t("BookingDate"),
            value: null
        },
        {
            label: t("Check-In date"),
            value: "check-in"
        },
        {
            label: t("Check-Out date"),
            value: "check-out",
        }
    ];

    const checkFields = () => {
        var regexNumbers = /^[0-9]*(\.[0-9]{0,2})?$/;
        if (
            formValues.costrulename &&
            (formValues.amount) &&
            formValues.costTypology &&
            formValues.costCategory &&
            formValues.paymentType
        ) {
            if (!regexNumbers.test(formValues.amount)) {
                toast.error(t("CheckField"));
                return;
            }

            if (formValues.costTypology.value !== 2) {
                if (!formValues.recurrency) {
                    toast.error(t("CheckAllFields"));
                    return false;
                }
                if (formValues.recurrency.value === "custom") {
                    if (!formValues.recurrencyCustom) {
                        toast.error(t("RecurrencyRequired"));
                        return false;
                    }
                    else if (formValues.recurrencyCustom <= 0) {
                        toast.error(t("RecurrencyGreater"));
                        return false;
                    }
                }
                if (formValues.endDate && recurrencyDate && formValues.endDate <= recurrencyDate) {
                    toast.error(t("EndDateGreater"));
                    return false;
                }
                if (recurrencyDate && recurrencyDate < new Date()) {
                    toast.error(t("RecurrencyDateGreater"));
                    return false;
                }
            }
            if (formValues.costTypology.value !== 1) {
                if (!formValues.accomodationList || formValues.accomodationList.length === 0) {
                    toast.error(t("CostRuleNeedsAccommodation"));
                    return false;
                }
            }

            if (formValues.paymentType === "Percentage" && formValues.costCategory.value === "home-owner-payment" && !formValues.extraServiceFee) {
                toast.error(t("ExtraServiceFeeError"));
                return false;
            }

            if (formValues.paymentType === "Percentage" && formValues.costCategory.value === "home-owner-payment" && !formValues.otaIncluded) {
                toast.error(t("OTACommissionError"));
                return false;
            }

            return true;
        } else {
            toast.error(t("CheckAllFields"));
            return false;
        }
    };

    const setExecutionDate = () => {
        formValues.executionDate = recurrencyDate ? recurrencyDate : new Date();
        if (!recurrencyDate) {
            switch (formValues.recurrency.value) {
                case "daily":
                    return formValues.executionDate.setDate((formValues.creationDate.getDate() + 1));
                case "weekly":
                    return formValues.executionDate.setDate((formValues.creationDate.getDate() + 7));
                case "monthly":
                    return formValues.executionDate.setMonth(formValues.creationDate.getMonth() + 1);
                case "yearly":
                    return formValues.executionDate.setFullYear(formValues.creationDate.getFullYear() + 1);
                case "custom":
                    return formValues.executionDate.setDate(formValues.creationDate.getDate() + formValues.recurrencyCustom);
            }
        }
    };

    return (
        <>
            <div className="w-full">
                <div className="mb-10">
                    <h1 className="text-3xl">{t("NewCostRule")}</h1>
                </div>
                <div className="w-full max-w-5xl p-10 bg-white border-2 rounded-lg shadow-md">
                    <h2 className="text-2xl">{t("CostRuleDetails")}</h2>
                    <hr className="my-4 " />
                    <div className="flex flex-col justify-between ml-auto mr-auto h-fit">
                        <div className="flex flex-wrap items-center justify-start">
                            <TextField
                                label={t("CostRuleNameLabel")}
                                placeholder={t("costRuleNamePlaceholder")}
                                className={"max-w-xs mr-10 m-1"}
                                inputClassName={"p-1"}
                                onChange={(e) => (formValues.costrulename = e.target.value)}
                                defaultValue={formValues.costrulename}
                            />
                        </div>
                        <div className="flex flex-wrap items-center justify-start mb-4">
                            <SelectMenu
                                label={t("CostTypologyLabelRequired")}
                                className={"max-w-xs mr-10"}
                                isSearchable={false}
                                options={costTypologyOptions}
                                placeholder={t("CostTypologyPlaceholder")}
                                onChange={(value) => {
                                    formValues.costTypology = value;
                                    formValues.accomodationList = null;
                                    formValues.costCategory = null;
                                    formValues.recurrency = null;
                                    formValues.recurrencyCustom = null;
                                    formValues.paymentDateType = null;
                                    setCostCategory(null);
                                    setCostTypology(value);
                                    setSelectedSecondaryMenu(null);
                                    setRecurrencyType(null);
                                    setPaymentDateType(null);
                                    if (value.value !== 2) {
                                        formValues.paymentType = {
                                            label: "Fixed",
                                            value: "Fixed",
                                        };
                                        setPaymentType("Fixed");
                                    }
                                    else {
                                        formValues.paymentType = null;
                                        setPaymentType(null);
                                    }
                                }}
                            />
                            {costTypology && costTypology.value != 1 && (
                                <SelectMenu
                                    label={
                                        t("SelectAccomodationLabel")
                                    }
                                    isClearable
                                    className={"max-w-xs min-w-[250px] "}
                                    isMulti={true}
                                    isSearchable
                                    onChange={(value) => {
                                        if (formValues.costTypology.value == 0) {
                                            formValues.accomodationList = value.map((item) => {
                                                return item.value;
                                            });
                                        }

                                        if (formValues.costTypology.value == 2) {
                                            formValues.accomodationList = value.map((item) => {
                                                return item.value;
                                            });
                                        }
                                        setSelectedSecondaryMenu(value);
                                    }}
                                    value={selectedSecondaryMenu}
                                    options={
                                        accommodationsList &&
                                        accommodationsList.map((item) => {
                                            return { label: item.data.name, value: item.id };
                                        })
                                    }
                                />
                            )}
                        </div>
                        <div className="flex flex-wrap items-end justify-start">
                            
                            <SubCategorySelect
                                className={"max-w-xs min-w-[250px]"}
                                type={costTypology}
                                onChange={(e) => {
                                    formValues.name = null;
                                    formValues.costCategory = e;
                                    setCostCategory(e);
                                }}
                                value={costCategory}
                            />
                            {costCategory && costCategory.value === "ota-commission" && (
                                <React.Fragment>
                                    <SelectMenu
                                        label={t("BookingProviderLabel")}
                                        className={"max-w-xs mr-5 ml-10"}
                                        options={
                                            context.bookingProvidersList
                                                ? context.bookingProvidersList.map((item) => {
                                                    return {
                                                        label: item.data.name,
                                                        value: item.data.name,
                                                    };
                                                })
                                                : null
                                        }
                                        onChange={(el) => (formValues.name = el.value)}
                                    />
                                    <SecondaryButton
                                        content={<MdOutlinePostAdd className="w-6 h-auto" />}
                                        className={"w-fit p-2 mb-2 max-w-[30px]"}
                                        onClick={() => setOpenNewBookingProviderModal(true)}
                                    />
                                </React.Fragment>
                            )}
                            {costCategory && costCategory.value !== "ota-commission" && (
                                <TextField
                                    label={t("SubCategory")}
                                    placeholder={t("SubCategoryPlaceholder")}
                                    className={"max-w-xs min-w-[250px] ml-10 m-1"}
                                    inputClassName={"p-1"}
                                    onChange={(e) => (formValues.name = e.target.value)}
                                    defaultValue={formValues.name}
                                />
                            )}

                            <div className="flex items-end justify-start w-full mt-3">
                                <SelectMenu
                                    label={t("Supplier")}
                                    className={"max-w-xs mr-5"}
                                    isClearable
                                    options={
                                        context.suppliesList
                                            ? context.suppliesList.map((item) => {
                                                return {
                                                    label:
                                                        item.data.name +
                                                        (item.data.category
                                                            ? " (" + item.data.category + ")"
                                                            : ""),
                                                    value: item.id,
                                                };
                                            })
                                            : null
                                    }
                                    onChange={(el) => (formValues.supplierId = el ? el.value : null)}
                                />
                                <SecondaryButton
                                    content={<MdOutlinePostAdd className="w-6 h-auto" />}
                                    className={"w-fit p-2 mb-2 max-w-[30px]"}
                                    onClick={() => setOpenNewSupplierModal(true)}
                                />
                            </div>
                            {costTypology && costTypology.value === 2 && costCategory && (
                                <div className="flex items-end justify-start w-full mt-3">
                                    <SelectMenu
                                        label={t("Trigger")}
                                        className={"max-w-xs mr-5"}
                                        options={costDateOption}
                                        onChange={(el) => {
                                            formValues.paymentDateType = el.value;
                                            setPaymentDateType(el.value);
                                        }}
                                    />
                                    <NewDatePicker
                                        className={"w-[200px] ml-6"}
                                        label={t("EndDate")}
                                        placeholder={t("EndDate")}
                                        onCompleted={(el) => {
                                            formValues.endDate = el ? el : null
                                        }}
                                    />
                                </div>
                            )}
                            {costTypology && costTypology.value !== 2 && (
                                <div className="flex items-end justify-start w-full mt-3">
                                    <SelectMenu
                                        label={t("RecurrenceRequired")}
                                        className={"max-w-xs mr-10"}
                                        options={recurrencyOption}
                                        onChange={(el) => {
                                            formValues.recurrency = el;
                                            if (el.value !== "custom")
                                                formValues.recurrencyCustom = null;
                                            setRecurrencyType(el.value);
                                        }}
                                    />
                                    {recurrencyType == "custom" && (
                                        <UnitField
                                            label={t("NumberOfDays*")}
                                            inputClassName={"w-[150px] p-1 mb-2 mr-10"}
                                            placeholder={0}
                                            value={formValues.recurrencyCustom}
                                            className={"w-fit"}
                                            onChange={(e) =>
                                                (formValues.recurrencyCustom = Number(e.target.value))
                                            }
                                        />
                                    )}
                                    <NewDatePicker
                                        className={"w-[200px] "}
                                        label={t("StartDate")}
                                        placeholder={t("StartDate")}
                                        onCompleted={(el) => {
                                            setRecurrencyDate(el);
                                        }}
                                    />
                                    <NewDatePicker
                                        className={"w-[200px] ml-8"}
                                        label={t("EndDate")}
                                        placeholder={t("EndDate")}
                                        onCompleted={(el) => {
                                            formValues.endDate = el ? el : null
                                        }}
                                    />
                                </div>
                            )}
                        </div>

                        {costTypology && costTypology.value === 2 && (
                            <div className="flex flex-wrap mt-4">
                                <SelectMenu
                                    label={t("PaymentTypeLabelRequired")}
                                    className={"max-w-xs mr-10"}
                                    isSearchable={false}
                                    options={paymentTypeOptions}
                                    placeholder={t("PaymenttypePlaceholder")}
                                    onChange={(value) => {
                                        formValues.paymentType = value;
                                        setPaymentType(value.value);
                                    }}
                                />
                                {paymentType === "Percentage" ?
                                    <div className="ml-1 mt-1">
                                        <PercentField
                                            className={"max-w-xs mr-10"}
                                            label={t("PercentageAmount")}
                                            inputClassName={"p-[5px] w-[150px]"}
                                            value={0}
                                            onChange={(e) => {
                                                formValues.amount = Number(e.target.value);
                                            }}
                                        />
                                    </div> :
                                    <div className="ml-1 mt-1">
                                        <CurrencyField
                                            className={"max-w-xs mr-10"}
                                            label={t("PaymentamountLabelRequired")}
                                            inputClassName={"p-1"}
                                            value={0}
                                            onChange={(value) =>
                                                (formValues.amount = Number(value.target.value))
                                            }
                                        />
                                    </div>
                                }

                                {paymentType && paymentType === "Percentage" && costCategory && costCategory.value === "home-owner-payment" && (
                                    <>
                                        <SelectMenu
                                            label={t("ExtraServiceFeeRequired")}
                                            className={"max-w-xs mr-10 mt-6"}
                                            isSearchable={false}
                                            options={includingOptions}
                                            placeholder={t("Select")}
                                            onChange={(value) => {
                                                formValues.extraServiceFee = value.value;
                                            }}
                                        />
                                        <SelectMenu
                                            label={t("OTACommissionRequired")}
                                            className={"max-w-xs mr-10 mt-6"}
                                            isSearchable={false}
                                            options={includingOptions}
                                            placeholder={t("Select")}
                                            onChange={(value) => {
                                                formValues.otaIncluded = value.value;
                                                setOtaIncluded(value.value);
                                            }}
                                        />
                                    </>
                                )}
                            </div>

                        )}
                        {costTypology && costTypology.value !== 2 && (
                            <div className="flex flex-wrap mt-6">
                                <CurrencyField
                                    className={"max-w-xs mr-10"}
                                    label={t("PaymentamountLabelRequired")}
                                    inputClassName={"p-1"}
                                    value={0}
                                    onChange={(value) =>
                                        (formValues.amount = Number(value.target.value))
                                    }
                                />

                            </div>
                        )}
                    </div>
                </div>
                <div className="flex justify-end w-full max-w-5xl">
                    <SecondaryButton
                        content={t("Go Back")}
                        className={"mt-10 p-3 max-w-[16rem] mr-10"}
                        onClick={() => navigator("../rules")}
                    />
                    <PrimaryButton
                        content={t("Add")}
                        className={"mt-10 p-3 max-w-[16rem]"}
                        onClick={() => {
                            if (checkFields()) {
                                formValues.creationDate = new Date();
                                if (formValues.costTypology.value !== 2) {
                                    setExecutionDate();
                                }
                                createCostRule(formValues, context.organizationId).then(() => {
                                    context.updateData("cost-rules");
                                    navigator("../rules");
                                });
                            }
                        }}
                    />
                </div>
            </div>

            <Modal
                externalControl
                status={openNewSupplierModal}
                onClose={() => setOpenNewSupplierModal(false)}
                body={
                    <NewSupplier
                        onCloseModal={closeModal}
                        onComplete={(value) => {
                            setOpenNewSupplierModal(false);
                            setSupplier(value, context.organizationId);
                            context.updateData("suppliers");
                        }}
                    />
                }

                title={t("NewSupplier")}
            />

            <Modal
                externalControl
                status={openNewBookingProviderModal}
                onClose={() => setOpenNewBookingProviderModal(false)}
                body={
                    <NewBookingProvider
                        onComplete={(value) => {
                            setOpenNewBookingProviderModal(false);
                            setBookingProvider(value, context.organizationId);
                            context.updateData("booking-providers");
                        }}
                    />
                }
                title={t("NewBookingProvider")}
            />
        </>
    );
}; 