import  React, { useContext, useState, useEffect } from "react";
import TextField from "../../components/input/fields/text-field";
import { CurrencyField } from "../../components/input/fields/currency-field";
import { RangeDatePicker } from "../../components/input/fields/date-pickers/multiple";
import { DatePicker } from "../../components/input/fields/date-pickers/single";
import { UnitField } from "../../components/input/fields/unit-field";
import PrimaryButton from "../../components/buttons/primary-button";
import SecondaryButton from "../../components/buttons/secondary-button";
import { useNavigate } from "react-router-dom";
import { SelectMenu } from "../../components/input/select/select-menu";
import { DataContext } from "..";
import { toast } from "react-toastify";
import { getCountries } from "react-phone-number-input";
import { createBooking, createCost } from "../../utils/firestore-functions";
import { useTranslation } from "react-i18next";



export const NewBooking = () => {
  const { i18n, t } = useTranslation();
  const context = useContext(DataContext);
  const [bookingMenu, setBookingMenu] = useState(false);  
  const [reference, setReference] = useState();  
  const [optionsCountry, setOptionsCountry] = useState();
  const [checkDatesMenu, setCheckDatesMenu] = useState(false);
  const [bookingChannelOpt, setBookingChannelOpt] = useState(null);
  const [commissionChannelSel, setCommissionChannelSel] = useState();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [extraServiceFee, setServiceFee] = useState(0);
  const [amount, setAmount] = useState(0);
  useEffect(() => {
    setOptionsCountry(
      getCountries().map((countryCode) => {
        return { label: countryCode, value: countryCode };
      })
    );
  }, []);


  const navigator = useNavigate();
  const [formsValues, setFormsValues] = useState({    
    bookingDate: null,
    checkInDate: null,
    checkOutDate: null,
    country: null,
    numberOfGuest: null,
    amount: null,
    extraServiceFee: null,
    accommodationFare: null,
    accomodationReference: null,
    commissionChannel: null,
    commissionAmount: null,
    commissionType: null,
    bookingReference: null
  });

  var options = context.loading
    ? null
    : context.accomodationsList &&
    context.accomodationsList.map((item) => {
      return { label: item.data.name, value: item.id, other: item.data };
    });

    

  const submit = () => {

   if (
      !(
        formsValues.bookingDate &&
        formsValues.checkInDate &&
        formsValues.checkOutDate &&
        formsValues.accomodationReference &&        
        formsValues.amount != null
      )
    ) {
      toast.error(t("CheckRequiredFields"));
      return;
    }

    if (formsValues.bookingDate > formsValues.checkInDate) {
      toast.error(t("BookingDateShorter"));
      return;
    }
    if (formsValues.checkInDate > formsValues.checkOutDate) {
      toast.error(t("CheckInDateErrorShorter"));
      return;
    }

    if (formsValues.amount == 0 ) {
      toast.error(t("InvalidAmount"));
      return;
    }

    formsValues.accommodationFare = formsValues.amount - (formsValues.extraServiceFee ?? 0);
    
    let commissionCostRule = context.costsRuleList && context.costsRuleList.filter(x => x.data.costTypology.value === 2 && x.data.costCategory.value === "ota-commission" && x.data.name === formsValues.commissionChannel && (!x.data.accomodationList || (x.data.accomodationList && x.data.accomodationList.indexOf(formsValues.accomodationReference) > -1)) && (!x.data.endDate || (x.data.endDate && new Date() < x.data.endDate.toDate())));
    if (commissionCostRule.length > 0) {
      if (commissionCostRule[0].data.paymentType.value === "Fixed") {
        formsValues.commissionAmount = commissionCostRule[0].data.amount;
        formsValues.commissionType = { label: t("Fixed"), value: null };
      }
      else if (commissionCostRule[0].data.paymentType.value === "Percentage") {
        formsValues.commissionAmount = parseFloat(((commissionCostRule[0].data.amount * formsValues.amount) / 100).toFixed(2));
        formsValues.commissionType = { label: t("Percentage"), value: commissionCostRule[0].data.amount };
      }
    }
    createBooking(formsValues, context.organizationId)
      .then(async (bookingId) => {
        navigator("../bookings");
        context.updateData("bookings");
        if (formsValues.commissionChannel) {
          //Adding ota commission cost
          if (commissionCostRule.length > 0) {
            commissionCostRule = commissionCostRule[0];
            let commissionBooking = 0;
            if (commissionCostRule.data.paymentType.value === "Fixed") {
              commissionBooking = commissionCostRule.data.amount;
            }
            else if (commissionCostRule.data.paymentType.value === "Percentage") {
              commissionBooking = parseFloat(((commissionCostRule.data.amount * formsValues.amount) / 100).toFixed(2));
            }
            let paymentDate = formsValues.bookingDate;
            if (commissionCostRule.data.paymentDateType) {
              if (commissionCostRule.data.paymentDateType.value == "check-in") {
                paymentDate = formsValues.checkInDate;
              }
              else if (commissionCostRule.data.paymentDateType.value == "check-out") {
                paymentDate = formsValues.checkOutDate;
              }
            }

          formsValues.commissionAmount = commissionBooking;
            
          try {
              await createCost(
                {
                  name: formsValues.commissionChannel,
                  costDate: paymentDate,
                  paymentDate: paymentDate,
                  amount: commissionBooking,
                  costTypology: { label: "Booking", value: 2 },
                  costCategory: commissionCostRule.data.costCategory,
                  accomodationList: [formsValues.accomodationReference],
                  bookingRef: bookingId,
                  costRuleRef: commissionCostRule.id,
                  supplierId: commissionCostRule.data.supplierId ? commissionCostRule.data.supplierId : null,
                },
                context.organizationId
              )
            }
            catch (e) {

            }
          }
        }
        // Adding other costs
        const costRulesAccomodation = context.costsRuleList ? context.costsRuleList.filter(x => x.data.costTypology.value === 2 && x.data.costCategory.value != "ota-commission" && (!x.data.accomodationList || (x.data.accomodationList && x.data.accomodationList.indexOf(formsValues.accomodationReference) > -1)) && (!x.data.endDate || (x.data.endDate && new Date() < x.data.endDate.toDate()))) : [];
        for (let i = 0; i < costRulesAccomodation.length; i++) {
          try {
            let commissionAccomodation = 0;
            if (costRulesAccomodation[i].data.paymentType.value === "Fixed") {
              commissionAccomodation = costRulesAccomodation[i].data.amount;
            } else if (costRulesAccomodation[i].data.paymentType.value === "Percentage") {
             
              if(costRulesAccomodation[i].data.costCategory.value === "home-owner-payment"){
                let tempTotal = formsValues.amount;
                tempTotal -= costRulesAccomodation[i].data.extraServiceFee === "Excluded" ? formsValues.extraServiceFee ?? 0 : 0;
                tempTotal -= costRulesAccomodation[i].data.otaIncluded === "Excluded" ? formsValues.commissionAmount ?? 0 : 0;
                commissionAccomodation = Number(((tempTotal / 100 ) * (costRulesAccomodation[i].data.amount)).toFixed(2));       

              }else{
                commissionAccomodation = parseFloat(((costRulesAccomodation[i].data.amount * formsValues.amount) / 100).toFixed(2));
              }

            }
            let paymentDate = formsValues.bookingDate;
            if (costRulesAccomodation[i].data.paymentDateType) {
              if (costRulesAccomodation[i].data.paymentDateType.value == "check-in") {
                paymentDate = formsValues.checkInDate;
              }
              else if (costRulesAccomodation[i].data.paymentDateType.value == "check-out") {
                paymentDate = formsValues.checkOutDate;
              }
            }
            await createCost(
              {
                name: costRulesAccomodation[i].data.name,
                costDate: paymentDate,
                paymentDate: paymentDate,
                amount: commissionAccomodation,
                costTypology: { label: t("Booking"), value: 2 },
                costCategory: costRulesAccomodation[i].data.costCategory,
                accomodationList: [formsValues.accomodationReference],
                bookingRef: bookingId,
                costRuleRef: costRulesAccomodation[i].id,
                supplierId: costRulesAccomodation[i].data.supplierId ? costRulesAccomodation[i].data.supplierId : null,
                otaIncluded: costRulesAccomodation[i].data.otaIncluded ?? null,
                extraServiceFee: costRulesAccomodation[i].data.extraServiceFee ?? null,
                percentage: costRulesAccomodation[i].data.amount ?? null,
                paymentType: costRulesAccomodation[i].data.paymentType.value ?? "Fixed",
              },
              context.organizationId
            )
          } catch (e) {

          }
        }
        toast.success (t("AutomaticCostsAdded"));
        context.updateData("costs");

      })
      .catch((err) => 
      {
        toast.error(err);
      });
  };

  return (
    <div className="w-full">
      <div className="mb-10">
        <h1 className="text-3xl">{t("NewBooking")}</h1>
      </div>
      <div className="w-full max-w-5xl p-10 bg-white border-2 rounded-lg shadow-md">
      <div className="flex flex-wrap justify-between w-full">
            <div className="flex items-center mr-9 space-x-9 ">
            <h2 className="text-2xl">{t("BookingDetails")}</h2>
              </div>
              <div className="flex items-center mr-9 space-x-9 ">
              <TextField
              inputClassName={"p-1 mb-4"}
              placeholder={t("Reference")}
              defaultValue={reference}
              onChange={(e) => {
                formsValues.bookingReference = e.target.value;
                setReference(e.target.value)}
              }
            />
              </div>
              
              
              </div>
      

        <hr className="my-4 " />
        <div className="flex flex-wrap justify-start ml-auto mr-auto h-fit">
          <DatePicker
            className={"w-[250px] m-1 mr-10"}
            inputClassName={"p-[5px] font-normal"}
            placeholder={t("BookingDate")}
            label={t("BookingDateReq")}
            onClick={() => {
              setBookingMenu(!bookingMenu);
              setCheckDatesMenu(false);
            }}
            onCompleted={(value) => {
              setBookingMenu(false);
              formsValues.bookingDate = value;
            }}
            menu={bookingMenu}
          />
          <RangeDatePicker
            className={"w-[400px] m-1"}
            inputClassName={"p-[5px] font-normal"}
            label={t("CheckDatesLabelRequired")}
            placeholder={t("CheckDatesPlaceholder")}
            onClick={() => {
              setCheckDatesMenu(!checkDatesMenu);
              setBookingMenu(false);
            }}
            menu={checkDatesMenu}
            onCompleted={(value) => {
              setCheckDatesMenu(false);
              formsValues.checkInDate = value.from;
              formsValues.checkOutDate = value.to;
            }}
          />
          <div className="flex flex-wrap justify-between w-full mt-10 font-normal">
            <div className="flex items-center mr-9 space-x-9 ">
            <SelectMenu
            label={t("Country")}
            placeholder={t("Select")}
            className={"w-36 mr-5"}
            inputClassName={"p-1"}
            options={optionsCountry}
            onChange={(item) => {
              formsValues.country = item.value;
              setSelectedCountry(item);
            }}
            defaultValue={selectedCountry}
          />
          <SelectMenu
              className={"max-w-fit min-w-[230px] mr-auto"}
              label={t("AccomodationRequired")}
              placeholder={t("SelectAccommodation")}
              options={options}
              isLoading={context.loading}
              isDisabled={context.loading}
              isMulti={false}
              isSearchable
              onChange={(value) => {
                
                formsValues.extraServiceFee = value.other.extraServiceFee ?? 0;
                
                formsValues.accomodationReference = value.value;
                const bookingChannelOptTemp = new Array();
 
                context.loading ? null : context.costsRuleList && context.costsRuleList.map((rule) => {
                const bookingChannel = new Object();
                const costRelated = rule.data.accomodationList ? rule.data.accomodationList.filter((x) => x === value.value) : [];

                                  
                  if (rule.data.costTypology.value === 2 && rule.data.costCategory.value === "ota-commission" && costRelated.length > 0 && (!rule.data.endDate || (rule.data.endDate && new Date() < rule.data.endDate.toDate()))) {
                    bookingChannel.label = <div className="flex items-center justify-start space-x-5 w-fit"><p className="text-base">{rule.data.name}</p></div>;
                    bookingChannel.value = rule.data.name;
                    bookingChannelOptTemp.push(bookingChannel);
                  }
                });
                setBookingChannelOpt(bookingChannelOptTemp);
                setCommissionChannelSel(null);
              }
              }
            />
              <UnitField
                label={t("NumberGuests")}
                inputClassName={"ml-1 max-w-[100px] text-left p-[5px]"}
                className={"max-w-fit"}
                defaultValue={formsValues.numberOfGuest}
                placeholder={"0"}
                onChange={(e) =>
                  (formsValues.numberOfGuest = Number(e.target.value))
                }
              />
          </div>

            
          </div>
            <div className="flex items-center mr-9 space-x-9 mt-10">
              <CurrencyField
                className={""}
                label={t("TotalAmountRequired")}
                placeholder={"0"}
                value={formsValues.amount}
                inputClassName={"p-[5px]"}
                onChange={(e) =>{
                  formsValues.amount = Number(e.target.value);
                  setAmount(formsValues.amount);
                } }
              />
              <CurrencyField
                className={""}
                label={t("ExtraServiceFee")}
                placeholder={"0"}
                value={formsValues.extraServiceFee}
                inputClassName={"p-[5px]"}
                onChange={(e) => {
                  formsValues.extraServiceFee = Number(e.target.value);
                  
                }}
              />
              
            </div>
        </div>
        <h2 className="mt-10 text-2xl">{t("CommissionDetails")}</h2>
        <hr className="my-4 " />
        <div className="flex flex-wrap justify-start font-normal w-fit h-fit">
          <SelectMenu
            className={"max-w-fit mr-16"}
            label={t("BookingChannel")}
            isClearable
            value={commissionChannelSel}
            options={bookingChannelOpt}
            onChange={(el) => {
              if (el) {
                setCommissionChannelSel(el);
                formsValues.commissionChannel = el.value;
              } else {
                setCommissionChannelSel(null);
                formsValues.commissionChannel = null;
                // formsValues.commissionRate = null;
                formsValues.fixedCommission = null;
              }
            }}
            inputClassName={"min-w-[250px] max-w-fit p-2"}

          />

        </div>
      </div>
      <div className="flex justify-end w-full max-w-5xl">
        <SecondaryButton
          content={t("Go Back")}
          className={"mt-10 p-3 max-w-[16rem] mr-10"}
          onClick={() => navigator("../bookings")}
        />
        <PrimaryButton
          content={t("AddBooking")}
          className={"mt-10 p-3 max-w-[16rem]"}
          onClick={() => {
            submit();
          }}
        />
      </div>
    </div>
  );
};

export default NewBooking;