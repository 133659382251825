import { React, useContext } from "react";
import { formatISO } from "date-fns";
import { DataContext } from "..";
import TertiaryButton from "../../components/buttons/tertiary-button";
import { useTranslation } from "react-i18next";

const CostView = ({ selectedCost, onClose }) => {
  const { i18n, t } = useTranslation();
  const context = useContext(DataContext);
  const selectedAccomodation = context.accomodationsList.filter(x => selectedCost.accomodationList && selectedCost.accomodationList.filter(accomodation => x && x.id == accomodation).length > 0);
  let accomodationListsName = "";
for (let i = 0; i < selectedAccomodation.length; i++) {
  accomodationListsName += selectedAccomodation[i].data.name;
  if (i < selectedAccomodation.length - 1) {
    accomodationListsName += ", ";
  }
}
  const costDate = new Date(1000 * selectedCost.costDate.seconds);
  const paymentDate = selectedCost.paymentDate
    ? new Date(1000 * selectedCost.paymentDate.seconds)
    : null;
  const chargebacksDate = selectedCost.chargebacksDate
    ? new Date(1000 * selectedCost.chargebacksDate.seconds)
    : null;
  const supplierData = selectedCost.supplierId
    ? context.suppliesList.find((item) => item.id === selectedCost.supplierId)
        .data
    : null;
  return (
    <div className="text-base">
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">{t("CostName")}:</h4>
        <p>{selectedCost.name ? selectedCost.name : t("CostNameError")}</p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">{t("CostType")}:</h4>
        <p>{selectedCost.costTypology.label}</p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">{t("CostAmount")}:</h4>
        <p>€ {selectedCost.amount}</p>
      </div>
      {(selectedCost.costTypology.value == 0 || selectedCost.costTypology.value == 2) && (
        <div className="flex items-center space-x-2">
          <h4 className="font-bold">{t("Accommodations")}:</h4>
          <p>{accomodationListsName}</p>
        </div>
      )}
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">{t("CostDate")}:</h4>
        <p>
          {formatISO(costDate, {
            representation: "date",
          })}
        </p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">{t("PaymentDate")}:</h4>
        <p>
          {paymentDate != null
            ? formatISO(paymentDate, {
                representation: "date",
              })
            : "Not defined"}
        </p>
      </div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold">{t("CostCategory")}:</h4>
        <p>{selectedCost.costCategory.label}</p>
      </div>
      {supplierData && (
        <div className="flex items-center space-x-2">
          <h4 className="font-bold">{t("CostSupplier")}:</h4>
          <p>{supplierData.name}</p>
        </div>
      )}
      <div className="flex justify-end">
        <TertiaryButton
          content={t("Close")}
          className={"bg-red text-white p-2 w-fit max-w-[130px] mt-2"}
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default CostView;
