import React, { useEffect } from "react";
import {
  HiOutlineChartBar,
  HiOutlineCash,
  HiOutlineTicket,
  HiOutlineCog,
  HiUserGroup,
  HiOutlineFilter,
  HiOutlineQuestionMarkCircle,
  HiOutlineCurrencyDollar
} from "react-icons/hi";
import { AiOutlineHome } from "react-icons/ai";

import { Link } from "react-router-dom";
import { BiLock, BiUserCircle } from "react-icons/bi";
import { useMatch } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import { UilHouseUser } from "@iconscout/react-unicons";
import { CiBoxes } from "react-icons/ci";
import { useTranslation } from "react-i18next";

const menu = [
  {
    name: "Dashboard",
    href: "",
    show: true,
    active: false,
    icon: <HiOutlineChartBar className={"w-6 h-auto hover:text-blue"} />,
  },
  {
    name: "Accommodations",
    href: "accommodations",
    show: true,
    active: false,
    icon: <AiOutlineHome className={"w-6 h-auto hover:text-blue"} />,
  },  
  {
    name: "CostRules",
    href: "rules",
    show: true,
    active: false,
    icon: <HiOutlineFilter className={"w-6 h-auto hover:text-blue"} />,
  },
  {
    name: "PaymentRules",
    href: "settings/payment-rules",
    show: true,
    active: false,
    disabled: true,
    icon: <HiOutlineCurrencyDollar className={"w-6 h-auto icon-disabled "} />,
  },
  {
    name: "Costs",
    href: "costs",
    show: true,
    active: false,
    icon: <HiOutlineCash className={"w-6 h-auto hover:text-blue"} />,
  },
  {
    name: "Bookings",
    href: "bookings",
    show: true,
    active: false,
    icon: <HiOutlineTicket className={"w-6 h-auto hover:text-blue"} />,
  },
  {
    name: "FAQ",
    href: "faq",
    show: true,
    active: false,
    icon: <HiOutlineQuestionMarkCircle className={"w-6 h-auto hover:text-blue"} />,
  },
];

const settingsMenu = [
  {
    name: "General",
    href: "settings",
    show: false,
    active: false,
    icon: <BiUserCircle className={"w-6 h-auto hover:text-blue"} />,
  },
  {
    name: "Security",
    href: "settings/security",
    show: true,
    active: false,
    icon: <BiLock className={"w-6 h-auto hover:text-blue"} />,
  },
  {
    name: "Subscription",
    href: "settings/subscription",
    show: true,
    active: false,
    icon: <HiOutlineTicket className={"w-6 h-auto hover:text-blue"} />,
  },
  {
    name: "Organization",
    href: "settings/organization",
    show: true,
    active: false,
    icon: <HiUserGroup className={"w-6 h-auto hover:text-blue"} />,
  },
  {
    name: "Owners",
    href: "settings/owners",
    show: true,
    active: false,
    icon: <UilHouseUser className={"w-6 h-auto hover:text-blue"} />,
  },
  {
    name: "Suppliers",
    href: "settings/suppliers",
    show: true,
    active: false,
    icon: <CiBoxes className={"w-6 h-auto hover:text-blue"} />,
  },
  {
    name: "OTA",
    href: "settings/otaCommissions",
    show: true,
    active: false,
    icon: <HiOutlineCash className={"w-7 h-7 hover:text-blue "} />,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function SideBar({ className, setIsOpen, i18n }) {
  const { t } = useTranslation();
  const locationSettings = useMatch("dashboard/settings/*");
  const locationDashboard = useMatch("dashboard/*");
  useEffect(() => {
    if (!locationSettings) {
      menu.forEach((element) => {
        if (element.href === locationDashboard.params["*"]) {
          element.active = true;
        } else {
          element.active = false;
        }
      });
    } else {
      settingsMenu.forEach((element) => {
        if (element.href === locationDashboard.params["*"]) {
          element.active = true;
        } else {
          element.active = false;
        }
      });
    }
  });

  return (
    <div
      className={
        className + " flex-col pr-5 border-r-2 h-screen bg-neutral-50 flex"
      }
    >
      <div className="flex flex-col items-start h-full my-5 text-blue">
        <Link
          className="flex items-center justify-center w-full h-2 my-10"
          to="/dashboard"
        >
          <img
            src="/Logo-Petroil.png"
            className="items-center h-[5rem] pt-3 pb-4"
            alt="logo"
          />
        </Link>
        <div className="h-full">
          <div className="mt-10 space-y-5 items-center" id="selectionmenu">
          {locationSettings == null
  ? menu.map((item, index) => {
      return (
        <div
          key={index}
          className={classNames(
            item.active ? "border-l-4" : "border-0",
            "flex flex-row items-center border-petroil",
            item.disabled ? " cursor-not-allowed" : "" 
          )}
        >
          <div className="flex items-center mx-12 ">
            {item.icon}
            {item.disabled ? (
              <div className="ml-2 cursor-not-allowed color-disabled font-semibold text-lg">
                <span className="text-lg" ></span>
                <span className="premium-required-tooltip " data-tooltip={t("PremiumRequired")} >{t(item.name)}</span>
              </div>
            ) : (
              <Link
                to={item.href}
                onClick={() => {
                  menu.forEach((element) => (element.active = false));
                  item.active = true;
                  if (window.innerWidth < 1200) setIsOpen(false);
                }}
                className={classNames(
                  item.active
                    ? "font-bold"
                    : "font-semibold opacity-80",
                  "flex ml-2 cursor-pointer hover:text-blue hover:opacity-100 text-lg"
                )}
              >
                {t(item.name)}
              </Link>
            )}
          </div>
        </div>
      );
    })
  : settingsMenu.map((item, index) => {
      return (
        <div
          key={index}
          className={classNames(
            item.active ? "border-l-4" : "border-0",
            "flex flex-row items-center border-petroil",
            item.disabled ? "opacity-50 cursor-not-allowed" : "" 
          )}
        >
          <div className="flex items-center mx-12">
            {item.icon}
            {item.disabled ? (
              <div className="ml-2 cursor-not-allowed">
              <span className="text-lg">{t(item.name)}</span>
              <span className="premium-required-tooltip ">{t("PremiumRequired")}</span>
            </div>
            ) : (
              <Link
                to={item.href}
                onClick={() => {
                  settingsMenu.forEach(
                    (element) => (element.active = false)
                  );
                  item.active = true;
                  if (window.innerWidth < 1200) setIsOpen(false);
                }}
                className={classNames(
                  item.active ? "font-bold" : "font-semibold",
                  "flex ml-2 cursor-pointer hover:text-petroil/80 text-lg"
                )}
              >
                {t(item.name)}
              </Link>
            )}
          </div>
        </div>
      );
    })}
          </div>
        </div>
        <div className="flex items-center justify-start w-full mx-8 mb-5">
          {!locationSettings && (
            <>
              <HiOutlineCog className="w-8 h-auto mr-2" />
              <Link
                to={"settings"}
                className="text-lg font-semibold cursor-pointer hover:text-petroil/80"
                onClick={() => {
                  settingsMenu.forEach((element) => {
                    element.href == "settings"
                      ? (element.active = true)
                      : (element.active = false);
                  });
                }}
              >
                {t("Settings")}
              </Link>
            </>
          )}
          {locationSettings && (
            <>
              <IoChevronBack className="w-8 h-auto mr-2" />
              <Link
                to={""}
                onClick={() => {
                  menu.forEach((element) => {
                    element.href == ""
                      ? (element.active = true)
                      : (element.active = false);
                  });
                }}
                className="text-lg font-semibold cursor-pointer hover:text-petroil/80"
              >
                {t("Go Back")}
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}