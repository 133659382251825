import React, { useContext, useState } from "react";
import { IoPersonAdd } from "react-icons/io5";
import { IoPencil, IoTrashBin } from "react-icons/io5";
import { DataContext } from "..";
import PrimaryButton from "../../components/buttons/primary-button";
import SecondaryButton from "../../components/buttons/secondary-button";
import Modal from "../../components/dialogs/dialog";
import { NewSupplier } from "../../components/modals/new-supplier";
import { setSupplier, deleteSupplier, updateSupplier } from "../../utils/firestore-functions";
import InfoButton from "../../components/buttons/info-button";
import TertiaryButton from "../../components/buttons/tertiary-button";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const SuppliersSettings = () => {
  const { i18n, t } = useTranslation();
  const context = useContext(DataContext);
  const [editModal, setEditModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [supplierSelected, setSupplierSelected] = useState();
  const [removeModal, setRemoveModal] = useState(false);
  const supplierList = context.suppliesList;

  const closeModal = () => {
    setCreateModal(false);
    setEditModal(false);
  };

  const handleCreateSupplier = (supplier) => {
    setSupplierSelected(supplier);
    setCreateModal(true);
  };
  const handleChangeSupplier = (supplier) => {
    setSupplierSelected(supplier);
    setEditModal(true);
  };

  const handleRemoveSupplier = (supplier) => {
    setSupplierSelected(supplier);
    setRemoveModal(true);
  };
  return (
    <>
    
    <InfoButton nameComponent={'suppliers'}/>
      <div className="flex flex-col content-between w-full h-full mx-10 ">
        <div className="space-y-4 text-3xl font-bold">
          <div className="flex items-center justify-start">
            <h1>{t("SuppliersManagement")}</h1>
          </div>
          <div className="flex flex-col p-5 space-y-4 font-normal bg-white rounded-lg shadow-md w-fit">
            <div className="my-1 space-y-2">
              <div className="flex flex-row justify-between">
              <div className="text-xl font-bold ">{t("General")}</div>
                <SecondaryButton content={<IoPersonAdd className="mx-auto" />}
                        className={"p-1 mx-2 max-w-[100px]"}
                        onClick={() => {
                          handleCreateSupplier({
                            name: '',
                            phoneNumber: '',
                            category: '',
                          });
                        }}
                      />
              </div>
              <hr className="w-full" />
            </div>
            <div className="z-10 inline-block border rounded-xl">
              {/* Table */}
              <table className="w-full max-w-6xl ">
                {/* Header */}
                <thead className="text-lg">
                  <tr className="border-b-2">
                    <th className="w-48 p-4 border-r ">{t("Name")}</th>
                    <th className="w-48 p-4 border-r">{t("PhoneNumber")}</th>
                    <th className="p-4 border-r w-36">{t("CategoryRequired")}</th>
                    <th className="w-52">{t("Actions")}</th>
                  </tr>
                </thead>
                <tbody className="text-base">
                  {supplierList &&
                    supplierList.map((item) => {
                      return (
                        <tr
                          key={item.id}
                          className="border-b cursor-pointer hover:bg-neutral-100"
                        >
                          <td className="p-2 text-left border-r">
                            {item.data.name}
                          </td>
                          <td className="text-center border-r">
                            {item.data.phoneNumber}
                          </td>
                          <td className="text-center border-r">
                            {item.data.category}
                          </td>

                          <td className="">
                            <div className="flex items-center justify-center">
                              <PrimaryButton
                                content={<IoPencil className="mx-auto" />}
                                className={"p-1 mx-2 max-w-[100px]"}
                                onClick={() =>
                                  handleChangeSupplier({
                                    id: item.id,
                                    name: item.data.name,
                                    phoneNumber: item.data.phoneNumber,
                                    category: item.data.category,
                                  })
                                }
                              />
                              <SecondaryButton
                                content={<IoTrashBin className="mx-auto" />}
                                className={"p-1 mx-2 max-w-[100px]"}
                                onClick={() => {
                                  handleRemoveSupplier({
                                    id: item.id,
                                    name: item.data.name,
                                    phoneNumber: item.data.phoneNumber,
                                    category: item.data.category,
                                  });
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="font-bold absolute bottom-0 right-10 flex items-center justify-end p-4 text-petroil rounded-full ">
  <p className="mr-1">{t("NeedSupport")}</p>
  <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
  >
    {t("ContactUs")}
  </a>
</div>
      </div>
      <Modal
        externalControl

        title={t("NewSupplier")}
        body={
          <NewSupplier
          onCloseModal={closeModal}
            onComplete={(value) => {
              setCreateModal(false);
              setSupplier(value, context.organizationId);
              context.updateData("suppliers");
            }}
          />
        }
        status={createModal}
        onClose={() => setCreateModal(false)}
      />
      <Modal
        externalControl
        title={t("EditSupplier")}
        className={"max-w-[400px]"}
        body={
          <NewSupplier
          
            onCloseModal={closeModal}
            onComplete={(supplier) => {
              if (
                !(JSON.stringify(supplier) === JSON.stringify(supplierSelected))
              )
                updateSupplier(
                  {
                    name: supplier.name,
                    phoneNumber: supplier.phoneNumber,
                    category: supplier.category,
                  },
                  supplier.id,
                  context.organizationId
                )
                  .then(() => {
                    context.updateData("suppliers");
                    setSupplierSelected();
                    setEditModal(false);
                  })
                  .catch((error) =>
                  {
                    toast.error(t("ErrorOccurred") + error);
                  });
            }}
            supplier={supplierSelected}
          />
        }
        status={editModal}
        onClose={() => setEditModal(false)}
      />
      <Modal
        externalControl
        title={"Remove Supplier"}
        body={
          <>
            <h1 className="text-lg">{t("RemoveSupplierQuestion")}</h1>
            <h2>{}</h2>
            <h2 className="flex items-end justify-center my-5">
              {t("LostSupplierRelationship")}{" "}
              <p className="ml-2 text-base text-red">{t("LostConnection")}</p>.
            </h2>
            <div className="flex justify-end">
            <TertiaryButton
                className={"bg-slate-400 mr-2 text-white p-2 w-fit max-w-[130px] mt-2"}
                content={"Cancel"}
                onClick={() => {
                  setRemoveModal(false)}}
              />
              <TertiaryButton
                className={"bg-red text-white p-2 w-fit max-w-[130px] mt-2"}
                content={"Yes, I'm sure"}
                onClick={() => {
                  deleteSupplier(supplierSelected.id, context.organizationId)
                    .then(() => {
                      context.updateData("suppliers");
                      setRemoveModal(false);
                      setSupplierSelected();
                    })
                    .catch((error) => toast.error("Error while deleting supplier: " + error));
                }}
              />              
            </div>
          </>
        }
        status={removeModal}
        onClose={() => setRemoveModal(false)}
      />
    </>
  );
};
