import { Route, Routes, useMatch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Login } from "./pages/authentication/login";
import { SignUp } from "./pages/authentication/signup";
import NotFound from "./pages/shared/notFound";
import Dashboard, { DataContext } from "./pages/index";
import ExtraInfo from "./pages/authentication/signup/alternatives/extra-info";
import OrganizationSetup from "./pages/organization/organizationSetup";
import "react-toastify/dist/ReactToastify.css";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "./Auth-app-wanager";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import {
  getUserExtraInfoInitial
} from "../src/utils/firestore-functions";


function App() {
  // Get user locally
  const context = useContext(DataContext);
  const currentUser = useContext(UserContext);
  const [hasOrganization, setOrganization] = useState(true);
  const [hasSubscription, setSubscription] = useState(true);
  
  // Dynamic dashboard
  const location = useMatch("dashboard/*");
  const startLocation = useMatch("/");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#c3f73a",
      },
      secondary: {
        main: "#0a6d67",
      },
    },
  });
  /* Check if exist organization ID */


  useEffect(() => {

    if (currentUser) {
      getUserExtraInfoInitial().then(async (userInfo) => {
        if (!userInfo || !userInfo.organization) {
          setOrganization(false);
        }else{
          setOrganization(true);
        }
        
      });
    }
  }, [currentUser]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="min-h-[80vh]">
          <Routes>
            <Route path="" element={startLocation && !location ? currentUser ? window.location.replace('/dashboard') : window.location.replace('login') : <NotFound />} />
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<SignUp />} />
            <Route
              path="organization-setup"
              element={
                currentUser && !hasOrganization && currentUser.phoneNumber ? (
                  <OrganizationSetup />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="dashboard/*"
              element={currentUser ? (currentUser.phoneNumber ? (hasOrganization ? <Dashboard /> : <OrganizationSetup />) : <ExtraInfo />) : <Login />}
            />
            <Route
              path="signup/extra-info"
              element={
                currentUser && !currentUser.phoneNumber ? (
                  <ExtraInfo />
                ) : (
                  <NotFound />
                )
              }
            />            
            <Route path="404" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          
        </div>
        {/* {currentUser && location ? null : <Footer />} */}

        

      </ThemeProvider>
    </>
  );
}

export default App;
