import { useState } from "react";
import { SubscriptionCard} from "../buttons/subscription-card";

const SubscriptionStep = ({ onComplete, value, setFormValues }) => {

    // Change here if you need to change price
    const descriptionHost = "Our exclusive \"Plan Host\" subscription is a strategic cornerstone in our business intelligence solution crafted for hosts aiming to maximize profitability. Priced at just €25.99, this plan is meticulously designed for users managing up to 4 properties. Elevate your hosting journey with intelligence that transforms properties into lucrative assets. Embrace the Host plan—where data meets profitability.";
    const descriptionPropertyManager = "Embrace proactive strategies with our \"Plan PM\" subscription—an indispensable toolkit for those overseeing a dynamic portfolio. Priced at an unbeatable €6.49 per accommodation, this plan isn't just cost-effective; it's your key to absolute control. Achieve profitability with precision timing and elevate your game, seize control, and make profitability a reality—welcome to the future of property management excellence.";
    const amountHost = 25.99;
    const amountPropertyManager = 32.45;
    const amountExtraProperty = 6.49;
    
    const handleSubscriptionChange = (formValues) => {
    
      setFormValues({
        subscription: {
          expired: formValues.expired,
          properties: formValues.properties,
          plan: formValues.plan,
          amount: formValues.amount,
          recurrency: formValues.recurrency,
          propertiesExtra: formValues.propertiesExtra,
          amountExtra: formValues.amountExtra
        },
      });
      };
    
      const expiredDate = new Date();
      expiredDate.setDate(expiredDate.getDate() + 14);
      
      
  
      const todayDate = new Date();
      todayDate.setDate(todayDate.getDate());
  
      
      
    return (
      <>
      <h2 className="font-bold mt-4 text-center text-petroil">Our plans</h2>
      <h3 className="mt-4 text-center text-petroil">Select the plan best suited to your needs</h3>
      <div className="flex justify-center ">
        <div className="p-12">
  
        <SubscriptionCard
        numberProperty={4}
        amount={amountHost}
        plan={"host"}
        description={descriptionHost}
        onChange={handleSubscriptionChange}
        onClick={() => {
          handleSubscriptionChange({
            expired: expiredDate,
            properties: 0,
            plan: "host",
            amount: amountHost,
            recurrency: "monthly",
            amountExtra: 0,
            propertiesExtra: 0
          })
          onComplete(2)
        }}
        />
        </div>
        <div className="p-12">
  
        <SubscriptionCard
            numberProperty={5}
            amount={amountPropertyManager}
            plan={"propertyManager"}
            description={descriptionPropertyManager}
            amountExtra={amountExtraProperty}
            onChange={handleSubscriptionChange}
            onClick={() => {
              handleSubscriptionChange({
                expired: expiredDate,
                properties: 0,
                plan: "propertyManager",
                amount: amountPropertyManager,
                recurrency: "monthly",
                amountExtra: amountExtraProperty,
                propertiesExtra: 0
              })
              onComplete(2)
            }}
          />
        </div>
        </div>
        <p className="flex justify-center mt-6">Try it for free 14 days.</p>      
        </>
    )
  
  }

  export default SubscriptionStep;