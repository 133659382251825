import { useState } from "react";
import TextField from "../input/fields/text-field";
import SecondaryButton from "../buttons/secondary-button";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const CompanyStep = ({ onComplete, onChange, value }) => {
  const { i18n, t } = useTranslation();

    return (
      <div className="mt-10 space-y-5">
        <TextField
          labelClassName={"text-4xl font-bold text-petroil mb-4"}
          inputClassName={"bg-transparent py-2 text-lg"}
          onFocusEffect={true}
          label={"What is your company's name?"}
          placeholder={"Company name"}
          onChange={(e) => {
            onChange(value = e.target.value);
            }
          }
          defaultValue={value}
        />
        <div className="flex items-center justify-between w-full">
        <button className=" p-3 max-w-[16rem] mr-10 w-full min-w-fit h-fit bg-petroil text-white rounded-lg text-base font-semibold hover:brightness-125" onClick={() => onComplete(1)}>{t("Go Back")}</button>
        
          <SecondaryButton
            className={"py-3 px-10 w-fit"}
            content={"Next >"}
            onClick={() => {
              if(onChange && value){
                onComplete(3)
              } else {
                toast.error("Invalid Company name");
              }
            }}
          />
        </div>
      </div>
    );
  };

  export default CompanyStep;