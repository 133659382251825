import React, { useContext, useState } from "react";
import SecondaryButton from "../../components/buttons/secondary-button";
import { CurrencyField } from "../../components/input/fields/currency-field";
import { DatePicker } from "../../components/input/fields/date-pickers/single";
import TextField from "../../components/input/fields/text-field";
import PrimaryButton from "../../components/buttons/primary-button";
import { useNavigate } from "react-router-dom";
import { SelectMenu } from "../../components/input/select/select-menu";
import Modal from "../../components/dialogs/dialog";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { PercentField } from "../../components/input/fields/percent-field";
import {
  createCost,
  setSupplier,
  setBookingProvider,
  updateBooking
} from "../../utils/firestore-functions";
import { DataContext } from "..";
import { MdOutlinePostAdd } from "react-icons/md";
import { NewSupplier } from "../../components/modals/new-supplier";
import { NewBookingProvider } from "../../components/modals/new-booking-provider";
import SubCategorySelect from "../../components/input/select/sub-category-select";
import { useTranslation } from "react-i18next";

export const NewCost = () => {
  const { i18n, t } = useTranslation();
  const context = useContext(DataContext);
  const bookingList = context.bookingList;
  const accommodationsList = context.accomodationsList;
  const costsList = context.costsList;
  const [costBookingSelected, setCostBookingSelected] = useState(null);
  const [bookingWithSameCost, setBookingWithSameCost] = useState(false);
  const [paymentMenu, setPaymentMenu] = useState(false);
  const [paymentType, setPaymentType] = useState(null);
  const [invoiceMenu, setInvoiceMenu] = useState(false);
  const [costTypology, setCostTypology] = useState(null);
  const [costCategory, setCostCategory] = useState(null);
  const [selectedSecondaryMenu, setSelectedSecondaryMenu] = useState();
  const [openNewSupplierModal, setOpenNewSupplierModal] = useState();
  const [openNewBookingProviderModal, setOpenNewBookingProviderModal] = useState();  
  const [otaIncluded, setOtaIncluded] = useState(null);  
 


  const [formValues, setFormValues] = useState({
    name: null,
    costDate: null,
    paymentDate: null,
    amount: null,
    costTypology: null,
    costCategory: null,
    accomodationList: null,
    paymentType: null,
    bookingRef: null,
    otaIncluded: null,
    costRuleRef: null,
    supplierId: null,
    extraServiceFee: null,
    percentage: null
  });

  const navigator = useNavigate("");
  const costTypologyOptions = [
    {
      label: t("Accomodation"),
      value: 0,
    },
    {
      label: t("Company"),
      value: 1,
    },
    {
      label: t("Booking"),
      value: 2,
    },
  ];

  const paymentTypeOptions = [
    {
        label: t("Fixed"),
        value: "Fixed",
    },
    {
        label: t("Percentage"),
        value: "Percentage",
    }
];
  

  const includingOptions = [
  {
      label: t("Included"),
      value: "Included",
  },
  {
      label: t("Excluded"),
      value: "Excluded",
  }
];



  const checkFields = () => {
    var regexNumbers = /^[0-9]*(\.[0-9]{0,2})?$/;
    
    if (
      (formValues.amount || formValues.percentage) &&
      formValues.costDate &&
      formValues.costTypology &&
      formValues.costCategory 
    ) {
      if (formValues.costTypology.value == 0 && (!formValues.accomodationList || formValues.accomodationList.length <= 0)) {
        toast.error(t("ChooseAccomodation"));
        return false;
      }
      if (formValues.costTypology.value == 2 && !formValues.bookingRef) {
        toast.error(t("bookingError"));
        return false;
      }
      if (formValues.costTypology.value == 2  && !paymentType) {
        toast.error(t("paymentTypeError"));
        return false;
      }

      if (formValues.paymentType === "Percentage" && formValues.costCategory.value === "home-owner-payment" && !formValues.extraServiceFee ){
        toast.error(t("ExtraServiceFeeError"));
        return false;
      }

      if (formValues.paymentType === "Percentage" && formValues.costCategory.value === "home-owner-payment"  && !formValues.otaIncluded){
        toast.error(t("OTACommissionError"));
        return false;
      }
      
      if(bookingWithSameCost){
        toast.error(t("otaCommissionError"));
        return false;
      }
      if (formValues.paymentDate && format(formValues.costDate, "t") > format(formValues.paymentDate, "t") ) {
        toast.error(t("DatePaymentBigger"));
        return;
      }
      if (!regexNumbers.test(formValues.amount) && !regexNumbers.test(formValues.percentage)) {
        toast.error(t("CheckField"));
        return;
      }
      if (formValues.chargebacks) {
        if (!(formValues.chargebacksAmount || formValues.chargebacksDate)) {
          toast.error(t("CheckChargebackRequired"));
          return;
        }
      }
      return true;
    } else {
      toast.error (t("CheckAllFields"));
      return false;
    }
  };

  const closeModal = () => {
    setOpenNewSupplierModal(false);
  };

  return (
    <>
      <div className="w-full">
        <div className="mb-10">
          <h1 className="text-3xl">{t("NewCost")}</h1>
        </div>
        <div className="w-full max-w-5xl p-10 bg-white border-2 rounded-lg shadow-md">
          <h2 className="text-2xl">{t("CostDetails")}</h2>
          <hr className="my-4 " />
          <div className="flex flex-col justify-between ml-auto mr-auto h-fit">
            <div className="flex flex-wrap items-center justify-start mb-4">
              <SelectMenu
                label={t("CostTypologyLabelRequired")}
                className={"max-w-xs mr-10"}
                isSearchable={false}
                options={costTypologyOptions}
                placeholder={t("CostTypologyPlaceholder")}
                onChange={(value) => {
                  formValues.costTypology = value;
                  formValues.accomodationList = null;
                  formValues.bookingRef = null;
                  formValues.costCategory = null;
                  formValues.paymentType = null;
                  setPaymentType(null);
                  setCostCategory(null);
                  setCostTypology(value);
                  setSelectedSecondaryMenu(null);
                }}
              />
              {costTypology && costTypology.value != 1 && (
                <SelectMenu
                  label={
                    t("SelectYour") +
                    costTypology.label.toLowerCase() +
                    (costTypology.value == 2 ? " *" : " *")
                  }                  
                  className={"max-w-xs min-w-[250px]"}
                  isMulti={costTypology.value == 0 ? true : false}
                  onChange={(value) => {
                    if (costTypology.value == 0) {
                      formValues.accomodationList = value.map((item) => {
                        return item.value;
                      });
                    }
                    if (costTypology.value == 2) {
                      
                      formValues.bookingRef = value.value;

                      const costBookingSel = bookingList.find(
                        (x) => x.id === value.value
                        );              

                      setCostBookingSelected(costBookingSel);
                      if(costBookingSel.data.commissionAmount != 0 && costCategory === "ota-commission"){
                        const bookingWithSameCost = costsList.find(
                          (y) => y.data.bookingRef === costBookingSel.id && y.data.costCategory.value === "ota-commission"
                        );
                        setBookingWithSameCost(bookingWithSameCost);
                      }
                      let accomodationsList = new Array();
                      accomodationsList.push(costBookingSel.data.accomodationReference);
                      formValues.accomodationList = accomodationsList;
                    }
                    setSelectedSecondaryMenu(value);
                  }}
                  value={selectedSecondaryMenu}
                  options={
                    costTypology.value == 0
                      ? accommodationsList &&
                      accommodationsList.map((item) => {
                        return { label: item.data.name, value: item.id };
                      })
                      : bookingList &&
                      bookingList.map((item) => {
                        let referenza = accommodationsList.find((value) =>
                          item.data.accomodationReference === value.id

                        );
                        return {
                          label: item.data.bookingDate.toDate().toLocaleString('it-IT', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric'
                          }) + " - " + referenza.data.name, value: item.id
                        };
                      })
                  }
                />
              )}
            </div>
            <div className="flex flex-wrap items-end justify-start mb-4">
              <SubCategorySelect
                className={"max-w-xs min-w-[250px] "}
                type={costTypology}
                onChange={(e) => {
                  formValues.name = null;
                  formValues.costCategory = e;
                  setCostCategory(e);
                }}
                value={costCategory}
              />
              {costCategory && costCategory.value === "ota-commission" && (
                <React.Fragment>
                  <SelectMenu
                    label={t("BookingProviderLabel")}
                    className={"max-w-xs min-w-[250px] ml-10 mr-5"}
                    options={
                      context.bookingProvidersList
                        ? context.bookingProvidersList.map((item) => {
                          return {
                            label: item.data.name,
                            value: item.data.name,
                          };
                        })
                        : null
                    }
                    onChange={(el) => (formValues.name = el.value)}
                  />
                  <SecondaryButton
                    content={<MdOutlinePostAdd className="w-6 h-auto" />}
                    className={"w-fit p-2 mb-2 max-w-[30px]"}
                    onClick={() => setOpenNewBookingProviderModal(true)}
                  />
                 </React.Fragment>
              )}
              {costCategory && costCategory.value !== "ota-commission" && (
                <TextField
                  label={t("SubCategory")}
                  placeholder={t("SubCategoryPlaceholder")}
                  className={"max-w-xs min-w-[250px] ml-10 m-1"}
                  inputClassName={"p-1"}
                  onChange={(e) => (formValues.name = e.target.value)}
                  defaultValue={formValues.name}
                />
              )}

              <div className="flex items-end justify-start w-full mt-3">
                <SelectMenu
                  label={t("Supplier")}
                  className={"max-w-xs mr-5"}
                  placeholder={t("SelectPlaceholder")}
                  isClearable
                  options={
                    context.suppliesList
                      ? context.suppliesList.map((item) => {
                        return {
                          label:
                            item.data.name +
                            (item.data.category
                              ? " (" + item.data.category + ")"
                              : ""),
                          value: item.id,
                        };
                      })
                      : null
                  }
                  onChange={(el) => {
                    formValues.supplierId = el ? el.value : null;
                  }}
                />
                <SecondaryButton
                  content={<MdOutlinePostAdd className="w-6 h-auto" />}
                  className={"w-fit p-2 mb-2 max-w-[30px]"}
                  onClick={() => setOpenNewSupplierModal(true)}
                />
              </div>
            </div>

            <div className="flex flex-wrap space-y-5">
              <DatePicker
                label={t("CostDateRequired")}
                placeholder={t("CostdatePlaceholder")}
                className={"max-w-xs min-w-[250px] mt-5 mr-10"}
                inputClassName={"p-1"}
                onClick={() => {
                  setPaymentMenu(!paymentMenu);
                  setInvoiceMenu(false);
                }}
                menu={paymentMenu}
                onCompleted={(date) => {
                  setPaymentMenu(false);
                  formValues.costDate = date;
                }}
              />
              <DatePicker
                label={t("PaymentDate")}
                placeholder={t("PaymentDatePlaceholder")}
                className={"max-w-xs min-w-[250px] mr-10"}
                inputClassName={"p-1"}
                onClick={() => {
                  setInvoiceMenu(!invoiceMenu);
                  setPaymentMenu(false);
                }}
                menu={invoiceMenu}
                onCompleted={(date) => {
                  setInvoiceMenu(false);
                  formValues.paymentDate = date;
                }}
              />
              </div>
              
              {((costTypology && costTypology.value !== 2) || (!costTypology)) && (
                <div className="flex ml-1 mt-4">
                    <CurrencyField
                        className={"max-w-xs"}
                        label={t("PaymentamountLabelRequired")}
                        inputClassName={"p-1"}
                        value={0}
                        onChange={(value) =>
                            (formValues.amount = Number(value.target.value))
                        }
                    />
                  </div>
                  
                )}
              {costTypology && costTypology.value === 2 &&(
                
                <div className="flex flex-wrap mt-6">
                  <SelectMenu
                      label={t("PaymentTypeLabelRequired")}
                      className={"max-w-xs "}
                      isSearchable={false}
                      options={paymentTypeOptions}
                      placeholder={t("PaymenttypePlaceholder")}
                      onChange={(value) => {
                          formValues.paymentType = value.value;
                          setPaymentType(value.value);
                      }}
                />
                
              {paymentType === "Percentage" ?
                <div className="ml-10 mt-1">
                  <PercentField
                      className={"max-w-xs "}
                      label={t("PercentageAmount")}
                      inputClassName={"p-[5px] w-[150px]"}
                      value={formValues.percentage}
                      onChange={(e) => {
                          formValues.percentage = Number(e.target.value);
                      }}
                  />
                </div> :  
                <div className="ml-10 mt-[2px]">
                  <CurrencyField
                      className={"max-w-xs mr-10 "}
                      label={t("PaymentamountLabelRequired")}
                      inputClassName={"p-1"}
                      value={formValues.amount}
                      onChange={(value) =>  {
                        formValues.amount = Number(value.target.value);
                        if (formValues.percentage) {
                          formValues.percentage = null;
                        }
                      }}
                  />
                </div>
              }   </div>                            
              )}
              
              
                {paymentType && paymentType === "Percentage" && costCategory && costCategory.value === "home-owner-payment" && (
                <div className="flex flex-wrap mt-6">
                <SelectMenu
                    label={t("ExtraServiceFeeRequired")}
                    className={"max-w-xs mt-1"}
                    isSearchable={false}
                    options={includingOptions}
                    placeholder={t("Select")}
                    onChange={(value) => {
                        formValues.extraServiceFee = value.value;
                    }}
                />
                <div className="ml-9">
                  <SelectMenu
                  label={t("OTACommissionRequired")}
                  className={"max-w-xs mr-10"}
                  isSearchable={false}
                  options={includingOptions}
                  placeholder={t("Select")}
                  onChange={(value) => {
                    formValues.otaIncluded = value.value;
                    setOtaIncluded(value.value);
                  }}
                  />
                </div>
                </div>
                )}
            
          </div>
        </div>
        <div className="flex justify-end w-full max-w-5xl">
          <SecondaryButton
            content={t("Go Back")}
            className={"mt-10 p-3 max-w-[16rem] mr-10"}
            onClick={() => navigator("../costs")}
          />
          <PrimaryButton
            content={t("AddCost")}
            className={"mt-10 p-3 max-w-[16rem]"}
            onClick={() => {
              if (checkFields()) {

                if(formValues.costCategory.value === "home-owner-payment" && formValues.paymentType === "Percentage"){
                let tempTotal = costBookingSelected.data.amount;
                tempTotal -= formValues.extraServiceFee === "Excluded" ? costBookingSelected.data.extraServiceFee ?? 0 : 0;
                tempTotal -= formValues.otaIncluded === "Excluded" ? costBookingSelected.data.commissionAmount ?? 0 : 0;
                formValues.amount = Number(((tempTotal / 100 ) * (formValues.percentage ?? 0)).toFixed(2));           
                }

                if(formValues.percentage && formValues.costCategory.value !== "home-owner-payment" ){
                  formValues.amount = Number(((costBookingSelected.data.amount / 100 ) * (formValues.percentage ?? 0)).toFixed(2));     
                }

                createCost(formValues, context.organizationId).then(() => {                  
                  if(costBookingSelected && !bookingWithSameCost && formValues.costCategory.value === "ota-commission" && paymentType !== "Percentage"){ 
                    costBookingSelected.data.commissionChannel = formValues.name;
                    costBookingSelected.data.commissionAmount = formValues.amount;
                    updateBooking(costBookingSelected.data, costBookingSelected.id, context.organizationId);
                    
                  } else if (costBookingSelected && !bookingWithSameCost && formValues.costCategory.value === "ota-commission" && paymentType === "Percentage"){                    
                      costBookingSelected.data.commissionChannel = formValues.name;
                      costBookingSelected.data.commissionAmount = formValues.amount;
                      updateBooking(costBookingSelected.data, costBookingSelected.id, context.organizationId);
                  }
                  context.updateData("costs");
                  context.updateData("bookings");
                  navigator("../costs");                  
                });
              }
            }}
          />
        </div>
      </div>
      <Modal
        externalControl
        status={openNewSupplierModal}
        onClose={() => setOpenNewSupplierModal(false)}
        body={
          <NewSupplier
          onCloseModal={closeModal}
            onComplete={(value) => {
              setOpenNewSupplierModal(false);
              setSupplier(value, context.organizationId);
              context.updateData("suppliers");
            }}
          />
        }
        title={t("NewSupplier")}
      />

      <Modal
        externalControl
        status={openNewBookingProviderModal}
        onClose={() => setOpenNewBookingProviderModal(false)}
        body={
          <NewBookingProvider
            onComplete={(value) => {
              setOpenNewBookingProviderModal(false);
              setBookingProvider(value, context.organizationId);
              context.updateData("booking-providers");
            }}
          />
        }
        title={t("NewBookingProvider")}
      />
    </>
  );
};
