import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import { IoMdAddCircleOutline } from "react-icons/io";
import { deleteCost, getCost, updateBooking } from "../../utils/firestore-functions";
import PrimaryButton from "../../components/buttons/primary-button";
import { IoPencil, IoTrashBin } from "react-icons/io5";
import SecondaryButton from "../../components/buttons/secondary-button";
import Modal from "../../components/dialogs/dialog";
import { DataContext } from "..";
import { AiOutlineMinus } from "react-icons/ai";
import { format } from "date-fns";
import { it } from 'date-fns/locale';
import CostView from "./ViewCost";
import CostEdit from "./EditCost";
import { NewDatePicker } from "../../components/input/fields/date-pickers/newDatePicker";
import { toast } from "react-toastify";
import InfoButton from "../../components/buttons/info-button";
import TertiaryButton from "../../components/buttons/tertiary-button";
import ResetButton from "../../components/buttons/reset-button";
import { useTranslation } from "react-i18next";

function Costs() {
  const { i18n, t } = useTranslation();
  const context = useContext(DataContext);
  let costsList = context.costsList;
  const [removeModal, setRemoveModal] = useState();
  const [costSelected, setCostSelected] = useState();
  const [tablePage, setTablePage] = useState(1);
  const [viewModal, setViewModal] = useState();
  const [editModal, setEditModal] = useState();
  const [filterDateFrom, setFilterDateFrom] = useState();
  const [filterDateTo, setFilterDateTo] = useState();
  const [costDateOrder, setCostDateOrder] = useState("reverse");
  const [runCostDateOrder, setRunCostDateOrder] = useState(0);
  const datePickerRef1 = useRef();
  const datePickerRef2 = useRef();
  function formatNumberWithComma(number) {
    if (number == null || number == undefined) {
      return 0;
    }
    return number.toString().replace('.', ',');
  }

  const handleReset = () => {
    setFilterDateFrom();
    setFilterDateTo();
    datePickerRef1.current.clearDate();
    datePickerRef2.current.clearDate();
  };

  useEffect(() => {
    if (runCostDateOrder < 2) {
      handleCostDateOrder();
      setRunCostDateOrder((prev) => prev + 1);
    }
  }, [runCostDateOrder]);

  let filtredCostList = Array();
  if (filterDateFrom && filterDateTo) {
    costsList.map((value) => {
      if (
        format(filterDateFrom, "t") <=
        format(new Date(1000 * value.data.costDate.seconds), "t") &&
        format(filterDateTo, "t") >=
        format(new Date(1000 * value.data.costDate.seconds), "t")
      ) {
        filtredCostList.push(value);
      }
    });
  }



  const handleCostDateOrder = () => {

    if (costDateOrder === "sort") {
      costsList = costsList.reverse(
        (a, b) => a.data.costDate.seconds - b.data.costDate.seconds
      );
      setCostDateOrder("reverse");
    }
    if (costDateOrder === "reverse") {
      costsList = costsList.sort(
        (a, b) => a.data.costDate.seconds - b.data.costDate.seconds
      );
      setCostDateOrder("sort");
    }
  };

  const showCostInfo = () => {
    setViewModal(true);
  };

  const CostRow = ({ costInfo, id }) => {
    const type = costInfo.costTypology.label;
    const costDate = new Date(1000 * costInfo.costDate.seconds);
    const paymentDate = costInfo.paymentDate
      ? new Date(1000 * costInfo.paymentDate.seconds)
      : null;

    const supplierName = context.suppliesList.find((item) => item.id === costInfo.supplierId);

    let accomodationName = "";
    if (costInfo.accomodationList) {
      accomodationName = costInfo.accomodationList.map(
        (accomodation) =>
          context.accomodationsList.find(
            (value) => value.id === accomodation
          ).data.name + " "
      )
    }


    return (
      <tr className="border-b cursor-pointer hover:bg-neutral-100" id={id}>
        <td
          className="text-center border-r"
          onClick={() => {
            setCostSelected({
              id: id,
              accomodationList: costInfo.accomodationList,
              amount: costInfo.amount,
              bookingRef: costInfo.bookingRef,
              costRuleRef: costInfo.costRuleRef,
              costDate: costInfo.costDate,
              costCategory: costInfo.costCategory,
              costTypology: costInfo.costTypology,
              name: costInfo.name,
              paymentDate: costInfo.paymentDate,
              supplierId: costInfo.supplierId,
            });
            showCostInfo();
          }}
        >
          {costInfo.costTypology.label}
        </td>
        <td
          className="p-2 text-center border-r"
          onClick={() => {
            setCostSelected({
              id: id,
              accomodationList: costInfo.accomodationList,
              amount: costInfo.amount,
              bookingRef: costInfo.bookingRef,
              costCategory: costInfo.costCategory,
              costRuleRef: costInfo.costRuleRef,
              costDate: costInfo.costDate,
              costTypology: costInfo.costTypology,
              name: costInfo.name,
              paymentDate: costInfo.paymentDate,
              costRuleRef: costInfo.costRuleRef,
              supplierId: costInfo.supplierId,
            });
            showCostInfo();
          }}
        >
          {costInfo.costCategory.label}
        </td>
        <td
          className="p-2 text-center border-r"
          onClick={() => {
            setCostSelected({
              id: id,
              accomodationList: costInfo.accomodationList,
              amount: costInfo.amount,
              bookingRef: costInfo.bookingRef,
              costCategory: costInfo.costCategory,
              category: costInfo.category,
              costDate: costInfo.costDate,
              costTypology: costInfo.costTypology,
              name: costInfo.name,
              paymentDate: costInfo.paymentDate,
              costRuleRef: costInfo.costRuleRef,
              supplierId: costInfo.supplierId,
            });
            showCostInfo();
          }}
        >
          {costInfo.name}
        </td>
        <td
          className="text-center border-r"
          onClick={() => {
            setCostSelected({
              id: id,
              accomodationList: costInfo.accomodationList,
              amount: costInfo.amount,
              bookingRef: costInfo.bookingRef,
              costRuleRef: costInfo.costRuleRef,
              costDate: costInfo.costDate,
              costTypology: costInfo.costTypology,
              costCategory: costInfo.costCategory,
              name: costInfo.name,
              paymentDate: costInfo.paymentDate,
              supplierId: costInfo.supplierId,
            });
            showCostInfo();
          }}
        >
          € {formatNumberWithComma(costInfo.amount)}
        </td>
        <td
          className="text-center border-r"
          onClick={() => {
            setCostSelected({
              id: id,
              accomodationList: costInfo.accomodationList,
              costCategory: costInfo.costCategory,
              amount: costInfo.amount,
              bookingRef: costInfo.bookingRef,
              costRuleRef: costInfo.costRuleRef,
              costDate: costInfo.costDate,
              costTypology: costInfo.costTypology,
              name: costInfo.name,
              paymentDate: costInfo.paymentDate,
              supplierId: costInfo.supplierId,
            });
            showCostInfo();
          }}
        >
          {format(costDate, "dd/MM/yyyy", { locale: it })}
        </td>
        <td
          className="text-center border-r"
          onClick={() => {
            setCostSelected({
              id: id,
              accomodationList: costInfo.accomodationList,
              amount: costInfo.amount,
              bookingRef: costInfo.bookingRef,
              costRuleRef: costInfo.costRuleRef,
              costDate: costInfo.costDate,
              costCategory: costInfo.costCategory,
              costTypology: costInfo.costTypology,
              name: costInfo.name,
              paymentDate: costInfo.paymentDate,
              supplierId: costInfo.supplierId,
            });
            showCostInfo();
          }}
        >
          {paymentDate ? (
            format(paymentDate, "dd/MM/yyyy", { locale: it })
          ) : (
            <AiOutlineMinus className="mx-auto" />
          )}
        </td>
        <td className="text-center border-r" onClick={() => {
          setCostSelected({
            id: id,
            accomodationList: costInfo.accomodationList,
            amount: costInfo.amount,
            bookingRef: costInfo.bookingRef,
            costRuleRef: costInfo.costRuleRef,
            costDate: costInfo.costDate,
            costCategory: costInfo.costCategory,
            costTypology: costInfo.costTypology,
            name: costInfo.name,
            paymentDate: costInfo.paymentDate,
            supplierId: costInfo.supplierId,
          });
          showCostInfo();
        }}>{supplierName ? supplierName.data.name : ""}</td>
        <td className="text-center border-r" onClick={() => {
          setCostSelected({
            id: id,
            accomodationList: costInfo.accomodationList,
            amount: costInfo.amount,
            bookingRef: costInfo.bookingRef,
            costRuleRef: costInfo.costRuleRef,
            costDate: costInfo.costDate,
            costCategory: costInfo.costCategory,
            costTypology: costInfo.costTypology,
            name: costInfo.name,
            paymentDate: costInfo.paymentDate,
            supplierId: costInfo.supplierId,
          });
          showCostInfo();
        }}
        >{accomodationName}</td>
        <td className="">
          {costInfo.costCategory != "ota-commission" && (
            <div className="flex items-center justify-center">
              <PrimaryButton
                onClick={() => {
                  setCostSelected({
                    id: id,
                    accomodationList: costInfo.accomodationList,
                    amount: costInfo.amount,
                    bookingRef: costInfo.bookingRef,
                    costRuleRef: costInfo.costRuleRef,
                    costDate: costInfo.costDate,
                    costTypology: costInfo.costTypology,
                    name: costInfo.name,
                    paymentDate: costInfo.paymentDate,
                    costCategory: costInfo.costCategory,
                    supplierId: costInfo.supplierId,
                    paymentType: costInfo.paymentType,
                    percentage: costInfo.percentage,
                    extraServiceFee: costInfo.extraServiceFee,
                    otaIncluded: costInfo.otaIncluded
                  });
                  setEditModal(true);
                }}
                content={<IoPencil className="mx-auto" />}
                className={"p-1 mx-2 max-w-[100px]"}
              />
              <SecondaryButton
                onClick={() => {
                  setCostSelected({
                    id: id,
                    accomodationList: costInfo.accomodationList,
                    amount: costInfo.amount,
                    bookingRef: costInfo.bookingRef,
                    costRuleRef: costInfo.costRuleRef,
                    costDate: costInfo.costDate,
                    costTypology: costInfo.costTypology,
                    name: costInfo.name,
                    paymentDate: costInfo.paymentDate,
                    costCategory: costInfo.costCategory,
                    supplierId: costInfo.supplierId,
                  });
                  setRemoveModal(true);
                }}
                content={<IoTrashBin className="mx-auto" />}
                className={"p-1 mx-2 max-w-[100px]"}
              />
            </div>
          )}
        </td>
      </tr>
    );
  };
  return (
    <>

      <InfoButton nameComponent={'costs'} />
      <div className="w-full">
        <h1 className="text-3xl font-bold">{t("Costs")}</h1>
        <Link
          className="flex flex-row items-center my-5 text-2xl font-normal cursor-pointer w-fit"
          to="new-cost"
        >
          <IoMdAddCircleOutline className="mr-1 w-[2rem]" />
          <p>{t("AddNewCost")}</p>
        </Link>
        <hr className="w-full max-w-5xl mb-6" />

        {costsList && costsList.length > 0 ? (
          <>
            <table className="w-full mb-10 bg-white rounded-lg shadow-md">
              <tbody className="">
                <tr>
                  <th className="p-4 text-left w-fit ">{t("FilterCost")}</th>
                  <th className="w-fit">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center justify-start space-x-5">
                        <h4>{t("ByCostDate")}:</h4>
                        <NewDatePicker
                          className={"w-[200px]"}
                          ref={datePickerRef1}
                          placeholder={t("From")}
                          onCompleted={(el) => {
                            setFilterDateFrom(el);
                          }}
                        />
                        <NewDatePicker
                          className={"w-[200px]"}
                          placeholder={t("To")}
                          ref={datePickerRef2}
                          onCompleted={(el) => {
                            if (filterDateFrom && el < filterDateFrom) {
                              toast.error (t(CheckDates));
                              setFilterDateTo();
                            } else {
                              setFilterDateTo(el);
                            }
                          }}
                        />
                      </div>
                      <div className="flex items-center justify-end space-x-5 mr-10">
                        <ResetButton onReset={handleReset}></ResetButton>
                      </div>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </tbody>
            </table>
            <table className="table w-full  bg-white rounded-lg shadow-md">
              <thead className="sticky top-0 table-header-group bg-white">
                <tr className="border-b-2">
                  <th className="w-48 p-4 border-r">{t("Typology")}</th>
                  <th className="w-48 p-4 border-r">{t("CostCategory")}</th>
                  <th className="p-4 border-r w-fit">{t("SubCategory")}</th>
                  <th className="p-4 border-r w-28">{t("CostAmount")}</th>
                  <th
                    className="p-4 border-r w-96 hover:cursor-pointer hover:bg-neutral-50"
                    onClick={handleCostDateOrder}
                  >
                    <div className="flex items-center justify-center ">
                      {costDateOrder === "sort" && (
                        <GoTriangleUp className="" />
                      )}
                      {costDateOrder === "reverse" && (
                        <GoTriangleDown className="" />
                      )}
                      <p className="ml-2">{t("CostDate")}</p>
                    </div>
                  </th>
                  <th className="w-48 p-4 border-r" >{t("Payment")}</th>
                  <th className="w-48 p-4 border-r" >{t("Suppliers")}</th>
                  <th className="w-48 p-4 border-r">{t("Accommodation")}</th>
                  <th className="w-40">{t("Actions")}</th>
                </tr>
              </thead>
              <tbody className="w-full h-10 overflow-scroll ">
                {(filterDateFrom && filterDateTo) ? (
                  filtredCostList.length > 0 ? (
                    filtredCostList.map((cost, key) => {
                      if (key >= tablePage * 10 - 10 && key < tablePage * 10) {
                        return (
                          <CostRow costInfo={cost.data} id={cost.id} key={key} />
                        );
                      }
                    })
                  ) : (
                    <tr>
                      <td colSpan={9} aria-colspan={9} className="text-lg font-semibold text-center">{t("NoCost")}</td>
                    </tr>
                  )
                ) : (
                  !(filterDateFrom && filterDateTo) && (
                    costsList.length > 0 ? (                      
                      costsList.map((cost, key) => {
                        if (key >= tablePage * 10 - 10 && key < tablePage * 10) {                          
                          return (
                            <CostRow costInfo={cost.data} id={cost.id} key={key} />
                          );
                        }
                      })
                    ) : (
                      <tr>
                        <td colSpan={9} aria-colspan={9} className="text-lg font-semibold text-center">{t("NoCost")}</td>
                      </tr>
                    )
                  )
                )}
              </tbody>
              <tfoot>
                <tr className="border-b-2 bg-neutral-50">
                  <th className="p-4 w-fit"></th>
                  <th className="p-4 w-28"></th>
                  <th className="w-48 "></th>
                  <th className="w-48 "></th>
                  <th className="p-4 "></th>
                  <th></th>
                  <th></th>
                  <th className="p-4 border-r w-28"></th>
                  <th className="flex items-center justify-center w-40 px-4 space-x-4 ">
                    <SecondaryButton
                      content={"<"}
                      className={"w-[30px] p-1"}
                      onClick={() =>
                        tablePage != 1 ? setTablePage(tablePage - 1) : null
                      }
                    />

                    <p className="text-xs text-center">
                      {tablePage} {t("of")} {Math.ceil(costsList.length / 10)}
                    </p>
                    <div className="flex items-center justify-center space-x-2">
                      {
                        <SecondaryButton
                          content={">"}
                          className={"w-[30px] p-1"}
                          onClick={() =>
                            Math.ceil(costsList.length / 10) != tablePage
                              ? setTablePage(tablePage + 1)
                              : null
                          }
                        />
                      }
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </>
        ) : (
          <div className="w-full text-lg font-semibold text-center">
            {t("NoCost")}
          </div>
        )}
        {(costsList.length >= 10 && !filterDateFrom && !filterDateTo) || filtredCostList.length >= 10 ?
          <div className="font-bold flex items-center justify-end p-4 text-petroil rounded-full ">
            <p className="mr-1">{t("NeedSupport")}</p>
            <a href="mailto: support@example.com" className="underline">{t("ContactUs")}</a>
          </div>
          :
          <div className="font-bold absolute bottom-0 right-10 flex items-center justify-end p-4 text-petroil rounded-full ">
            <p className="mr-1">{t("NeedSupport")}</p>
            <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
            >
              {t("ContactUs")}
            </a>
          </div>
        }
      </div>
      <Modal
        externalControl
        title={t("RemoveCostLabel")}
        body={
          <>
            <h1 className="text-lg">{t("RemoveSure")} 
            "{costSelected && costSelected.name}"?</h1>
            <div className="flex justify-end">
              <TertiaryButton
                className={"bg-slate-400 mr-2 text-white p-2 w-fit max-w-[130px] mt-2"}
                content={t("Cancel")}
                onClick={() => {
                  setRemoveModal(false)
                }}
              />
              <TertiaryButton
                className={"bg-red text-white p-2 w-fit max-w-[130px] mt-2"}
                content={t("ConfirmedSure")}
                onClick={() => {
                  deleteCost(costSelected.id, context.organizationId)
                    .then(() => {

                      if (costSelected.costCategory.value === "ota-commission") {
                        const booking = context.bookingList.find((item) => {
                          return item.id == costSelected.bookingRef;
                        })
                        booking.data.commissionAmount = 0;
                        booking.data.commissionChannel = null;
                        booking.data.commissionType = null;
                        updateBooking(booking.data, booking.id, context.organizationId);
                        context.updateData("costs");
                        context.updateData("bookings");
                      }
                      context.updateData("costs");
                      context.updateData("bookings");
                      setRemoveModal(false);
                      setCostSelected();
                    })
                    .catch((error) => toast.error(t("ErrorDeletingCost") + error));

                }}
              />
            </div>
          </>
        }
        status={removeModal}
        onClose={() => setRemoveModal(false)}
      />
      <Modal
        externalControl
        status={editModal}
        title={t("Edit")}
        className={"max-w-[850px]"}
        body={
          <CostEdit
            selectedCost={costSelected}
            onClose={() => setEditModal(false)}
          />
        }
        onClose={() => setEditModal(false)}
      />
      <Modal
        externalControl
        status={viewModal}
        title={t("CostDetails")}
        className={"w-[600px]"}
        body={
          <CostView
            selectedCost={costSelected}
            onClose={() => setViewModal(false)}
          />
        }
        onClose={() => setViewModal(false)}
      />
    </>
  );
}

export default Costs;
