import React, { useContext, useState } from "react";
import SecondaryButton from "../../components/buttons/secondary-button";
import { CurrencyField } from "../../components/input/fields/currency-field";
import TextField from "../../components/input/fields/text-field";
import PrimaryButton from "../../components/buttons/primary-button";
import TertiaryButton from "../../components/buttons/tertiary-button";
import { SelectMenu } from "../../components/input/select/select-menu";
import Modal from "../../components/dialogs/dialog";
import { PercentField } from "../../components/input/fields/percent-field";
import { toast } from "react-toastify";
import {
  updateBooking,
  setSupplier,
  setBookingProvider,
  updateCost,
} from "../../utils/firestore-functions";
import { DataContext } from "..";
import { MdOutlinePostAdd } from "react-icons/md";
import { NewSupplier } from "../../components/modals/new-supplier";
import { NewBookingProvider } from "../../components/modals/new-booking-provider";
import SubCategorySelect from "../../components/input/select/sub-category-select";
import { NewDatePicker } from "../../components/input/fields/date-pickers/newDatePicker";
import { useTranslation } from "react-i18next";

const CostEdit = ({ selectedCost, onClose }) => {
  const { t } = useTranslation();
  const context = useContext(DataContext);
  const bookingList = context.bookingList;
  const accommodationsList = context.accomodationsList;
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [costTypology, setCostTypology] = useState(selectedCost.costTypology || "");
  const [costCategory, setCostCategory] = useState(selectedCost.costCategory || "");
  const [paymentType, setPaymentType] = useState(selectedCost.paymentType);
  const [extraServiceFee, setExtraServiceFee] = useState(selectedCost.extraServiceFee);
  const [otaIncluded, setOtaIncluded] = useState(selectedCost.otaIncluded);
  const [bookingSelected, setBookingSelected] = useState(null);
  const [percentage, setPercentage] = useState(0.00);
  const closeModal = () => {
    setOpenNewSupplierModal(false);
  };

  const costDate = selectedCost.costDate
    ? new Date(1000 * selectedCost.costDate.seconds)
    : null;
  const paymentDate = selectedCost.paymentDate
    ? new Date(1000 * selectedCost.paymentDate.seconds)
    : null;

  const supplierOptions = context.suppliesList
    ? context.suppliesList.map((item) => {
      return {
        label:
          item.data.name +
          (item.data.category ? " (" + item.data.category + ")" : ""),
        value: item.id,
      };
    })
    : null;

  const [supplier, setSupplierForm] = useState(
    selectedCost.supplierId
      ? supplierOptions.find((value) => value.value == selectedCost.supplierId)
      : null
  );

  const [openNewSupplierModal, setOpenNewSupplierModal] = useState();
  const [selectedBookingProvider, setSelectedBookingProvider] = useState({ label: selectedCost.name, value: selectedCost.name });
  const [openNewBookingProviderModal, setOpenNewBookingProviderModal] = useState();
  const [subcategory, setSubcategory] = useState(selectedCost.name || "");

  const [formValues, setFormValues] = useState({
    name: subcategory,
    costDate: selectedCost.costDate.toDate(),
    paymentDate: selectedCost.paymentDate
      ? selectedCost.paymentDate.toDate()
      : null,
    amount: parseFloat(selectedCost.amount.toFixed(2)),
    costTypology: costTypology || "",
    costCategory: costCategory || "",
    accomodationList: selectedCost.accomodationList,
    bookingRef: selectedCost.bookingRef ? selectedCost.bookingRef : null,
    costRuleRef: selectedCost.costRuleRef ? selectedCost.costRuleRef : null,
    supplierId: selectedCost.supplierId,
    percentage: selectedCost.percentage ?? 0,
    otaIncluded: selectedCost.otaIncluded ?? null,
    extraServiceFee: selectedCost.extraServiceFee ?? null,
    paymentType: selectedCost.paymentType ?? null
  });
  const costTypologyOptions = [
    {
      label: t("Accomodation"),
      value: 0,
    },
    {
      label: t("Company"),
      value: 1,
    },
    {
      label: t("Booking"),
      value: 2,
    },
  ];

  const paymentTypeOptions = [
    {
      label: t("Fixed"),
      value: "Fixed",
    },
    {
      label: t("Percentage"),
      value: "Percentage",
    }
  ];

  const includingOptions = [
    {
      label: t("Included"),
      value: "Included",
    },
    {
      label: t("Excluded"),
      value: "Excluded",
    }
  ];

  const retrieveBooking = (id) => {
    return bookingList.find((x) => x.id === id);
  }

  if (formValues.bookingRef === selectedCost.bookingRef && isFirstTime) {
    let takedValue = retrieveBooking(selectedCost.bookingRef);
    setBookingSelected(takedValue);
    setIsFirstTime(false);
  }


  const updateBookingInternal = (isToUpdate) => {
    const bookingToUpdate = bookingList.find((x) => x.id === selectedCost.bookingRef);

    if (bookingToUpdate && isToUpdate) {
      if (selectedCost.bookingRef != formValues.bookingRef && isToUpdate && selectedCost.costCategory.value === "ota-commission" && formValues.costCategory.value === "ota-commission") {

        const bookingToUpdateNew = bookingList.find((y) => y.id === formValues.bookingRef);

        if (bookingToUpdateNew && !bookingToUpdateNew.data.commissionChannel) {
          bookingToUpdate.data.commissionAmount = 0;
          bookingToUpdate.data.commissionChannel = null;
          bookingToUpdateNew.data.commissionAmount = formValues.amount;
          bookingToUpdateNew.data.commissionChannel = formValues.name;
          bookingToUpdateNew.data.accomodationReference = formValues.accomodationList[0];
          updateBooking(bookingToUpdateNew.data, bookingToUpdateNew.id, context.organizationId);

        }
      } else if (isToUpdate && selectedCost.bookingRef === formValues.bookingRef) {

        bookingToUpdate.data.commissionAmount = formValues.amount;
        bookingToUpdate.data.commissionChannel = formValues.name;
        bookingToUpdate.data.accomodationReference = formValues.accomodationList[0];
      }
    } else if (bookingToUpdate && !isToUpdate) {
      bookingToUpdate.data.commissionAmount = 0;
      bookingToUpdate.data.commissionChannel = null;
    }

    updateBooking(bookingToUpdate.data, bookingToUpdate.id, context.organizationId);
    context.updateData("bookings");
  }

  const getAccomodationSelected = () => {
    var resl = Array();
    accommodationsList.map((value) => {
      if (selectedCost.accomodationList)
        selectedCost.accomodationList.forEach((ext) => {
          if (value.id === ext) {
            resl.push({ value: value.id, label: value.data.name });
          }
        });
    });
    return resl;
  };
  const getBookingSelected = () => {
    const costBookingSelected = bookingList.find((value) => value.id === selectedCost.bookingRef);

    const costBookingSelReference = accommodationsList.find((value) => costBookingSelected.data.accomodationReference === value.id);

    return {
      value: costBookingSelected.data.id, label: costBookingSelected.data.bookingDate.toDate().toLocaleString('it-IT', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }) + " - " + costBookingSelReference.data.name
    };


  };

  const [selectedSecondaryMenu, setSelectedSecondaryMenu] = useState(
    (selectedCost.costTypology.value === 2 && getBookingSelected()) ||
    (selectedCost.costTypology.value === 0 && getAccomodationSelected())
  );

  const checkFields = () => {
    var regexNumbers = /^[0-9]*(\.[0-9]{0,2})?$/;

    const checkOta = bookingList.find((z) => z.id === formValues.bookingRef);
    const checkNewOta = bookingList.find((z) => z.id === selectedCost.bookingRef);

    if (formValues.costTypology.value === 2 && formValues.costCategory.value === "ota-commission" && checkNewOta.data.commissionAmount && checkOta.data.commissionAmount && checkNewOta.id != checkOta.id) {
      toast.error(t('otaCommissionError'));
      return false;
    }

    if (formValues.costTypology.value == 2 && !paymentType) {
      toast.error(t('paymentTypeError'));
      return false;
    }

    if (formValues.amount && !regexNumbers.test(formValues.amount)) {
      toast.error(t('amountFieldError'));
      return false;
    }

    if (costTypology.value === 0) {
      if (!formValues.accomodationList || formValues.accomodationList.length === 0) {
        toast.error(t('accommodationListError'));
        return false;
      }
    } else if (costTypology.value === 2) {
      if (!formValues.bookingRef) {
        toast.error(t('bookingError'));
        return false;
      }
    }

    if (!formValues.costDate || !formValues.costCategory || formValues.costCategory.value === "ota-commission" && !formValues.name) {
      toast.error(t('requiredFieldsError'));
      return false;
    }

    if (formValues.paymentDate && formValues.costDate > formValues.paymentDate) {
      toast.error(t('costDateError'));
      return false;
    }

    if (formValues.paymentType === "Percentage" && formValues.costCategory.value === "home-owner-payment" && !formValues.extraServiceFee) {
      toast.error(t("ExtraServiceFeeError"));
      return false;
    }

    if (formValues.paymentType === "Percentage" && formValues.costCategory.value === "home-owner-payment" && !formValues.otaIncluded) {
      toast.error(t("OTACommissionError"));
      return false;
    }

    return true;
  };



  const submit = async () => {
    if (!checkFields()) {
      return;
    }

    if (formValues.costCategory.value === "home-owner-payment" && formValues.paymentType === "Percentage") {
      let tempTotal = bookingSelected.data.amount;
      tempTotal -= formValues.extraServiceFee === "Excluded" ? bookingSelected.data.extraServiceFee ?? 0 : 0;
      tempTotal -= formValues.otaIncluded === "Excluded" ? bookingSelected.data.commissionAmount ?? 0 : 0;
      formValues.amount = Number(((tempTotal / 100) * (formValues.percentage ?? 0)).toFixed(2));
    }

    if (formValues.percentage && formValues.costCategory.value !== "home-owner-payment") {
      formValues.amount = Number(((bookingSelected.data.amount / 100) * (formValues.percentage ?? 0)).toFixed(2));
    }


    updateCost(formValues, selectedCost.id, context.organizationId)
      .then(() => {
        context.updateData("costs");
        if (formValues.costCategory && formValues.costCategory.value !== "ota-commission" && selectedCost.costCategory.value === "ota-commission") {
          updateBookingInternal(false);
        }

        if (formValues.costCategory && formValues.costCategory.value === "ota-commission") {
          if (formValues.amount != selectedCost.amount || formValues.name && formValues.name != selectedCost.name || formValues.bookingRef != selectedCost.bookingRef) {
            updateBookingInternal(true);
          }
        }
        onClose();
      });

  };

  return (
    <div>
      <div className="w-full max-w-5xl p-5">
        <div className="flex flex-col justify-between ml-auto mr-auto h-fit">
          <div className="flex flex-wrap items-center justify-start mb-4">
            <SelectMenu
              label={t("CostTypologyLabelRequired")}
              className={"max-w-xs min-w-[250px] mr-10"}
              isSearchable={false}
              options={costTypologyOptions}
              placeholder={t("CostTypologyPlaceholder")}
              onChange={(value) => {
                formValues.costTypology = value;
                formValues.accomodationList = null;
                formValues.bookingRef = null;
                formValues.costCategory = null;
                setCostCategory(null);
                setCostTypology(value);
                setSelectedSecondaryMenu(null);
              }}
              value={costTypology}
            />
            {costTypology && costTypology.value != 1 && (
              <SelectMenu
                label={
                  t("SelectYour") +
                  costTypology.label.toLowerCase() +
                  (costTypology.value == 2 ? " *" : " *")
                }
                className={"max-w-xs min-w-[250px]"}
                isMulti={costTypology.value == 0 ? true : false}
                onChange={(value) => {
                  if (costTypology.value == 0) {
                    formValues.accomodationList = value.map((item) => {
                      return item.value;
                    });

                  }
                  if (costTypology.value === 2) {
                    const selectedBooking = bookingList.find(item => item.id === value.value);
                    if (selectedBooking) {
                      const referenza = accommodationsList.find(
                        value => selectedBooking.data.accomodationReference === value.id
                      );
                      formValues.bookingRef = value.value;
                      formValues.accomodationList = [referenza.id];
                    }
                  }

                  setSelectedSecondaryMenu(value);
                }}
                value={selectedSecondaryMenu}
                options={
                  costTypology.value == 0
                    ? accommodationsList &&
                    accommodationsList.map((item) => {
                      return { label: item.data.name, value: item.id };
                    })
                    : bookingList &&
                    bookingList.map((item) => {
                      let referenza = accommodationsList.find((value) =>
                        item.data.accomodationReference === value.id

                      );
                      return {
                        label: item.data.bookingDate.toDate().toLocaleString('it-IT', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric'
                        }) + " - " + referenza.data.name, value: item.id
                      };
                    })
                }
              />
            )}
          </div>
          <div className="flex flex-wrap items-end justify-start mb-4">
            <SubCategorySelect
              type={costTypology}
              onChange={(e) => {
                if (e) {
                  const bookingProvider = context.bookingProvidersList.find(
                    (x) => x.data.name === selectedCost.name
                  );
                  if (
                    formValues.costTypology &&
                    formValues.costTypology.value === 2 &&
                    e.value === "ota-commission" &&
                    bookingProvider
                  ) {
                    formValues.name = selectedCost.name;
                    setSubcategory(selectedCost.name);
                    setSelectedBookingProvider({
                      label: selectedCost.name,
                      value: selectedCost.name,
                    });
                  } else {
                    formValues.name = null;
                    setSubcategory("");
                    setSelectedBookingProvider(null);
                  }
                  formValues.costCategory = e;
                  setCostCategory(e);
                } else {
                  // Handle the case where the user cleared the sub-category field
                  formValues.name = null;
                  setSubcategory("");
                  setSelectedBookingProvider(null);
                  formValues.costCategory = null;
                  setCostCategory(null);
                }
              }}
              value={costCategory}
            />

            {costCategory && costCategory.value === "ota-commission" && (
              <React.Fragment>
                <SelectMenu
                  label={t("BookingProviderLabel")}
                  className={"max-w-xs ml-10 mr-5"}
                  value={selectedBookingProvider}
                  options={
                    context.bookingProvidersList
                      ? context.bookingProvidersList.map((item) => {
                        return {
                          label: item.data.name,
                          value: item.data.name,
                        };
                      })
                      : null
                  }
                  onChange={(el) => {
                    formValues.name = el.value;
                    setSelectedBookingProvider(el);
                  }}
                />
                <SecondaryButton
                  content={<MdOutlinePostAdd className="w-6 h-auto" />}
                  className={"w-fit p-2 mb-2 max-w-[30px]"}
                  onClick={() => setOpenNewBookingProviderModal(true)}
                />
              </React.Fragment>
            )}
            {costCategory && costCategory.value !== "ota-commission" && (
              <TextField
                label={t("SubCategory")}
                placeholder={t("SubCategoryPlaceholder")}
                className={"max-w-xs min-w-[250px] ml-10"}
                inputClassName={"p-1"}
                value={formValues.name}
                onChange={(e) => {
                  formValues.name = e.target.value;
                  setSubcategory(e.target.value);
                }}
                notEditableValue={formValues.name}
              />
            )}
            <div className="flex items-end justify-start w-full mt-3">
              <SelectMenu
                label={t("Supplier")}
                isClearable
                className={"max-w-xs mr-5"}
                options={supplierOptions}
                value={supplier}
                onChange={(el) => {
                  setSupplierForm(el);
                  formValues.supplierId = el.value;
                }}
              />
              <SecondaryButton
                content={<MdOutlinePostAdd className="w-6 h-auto" />}
                className={"w-fit p-2 mb-2 max-w-[30px]"}
                onClick={() => setOpenNewSupplierModal(true)}
              />
            </div>
          </div>

          <div className="flex flex-wrap space-y-5">
            <NewDatePicker
              label={t("CostDateRequired")}
              placeholder={t("CostdatePlaceholder")}
              className={"max-w-xs min-w-[250px] mt-5 mr-10"}
              defaultValue={costDate}
              onCompleted={(el) => {
                formValues.costDate = el;
              }}
            />
            <NewDatePicker
              label={t("PaymentDate")}
              placeholder={t("PaymentDatePlaceholder")}
              className={"max-w-xs min-w-[250px] mr-10"}
              defaultValue={paymentDate}
              onCompleted={(el) => (formValues.paymentDate = el)}
            />

            {costTypology && costTypology.value === 2 ?

              <div className="flex flex-row mt-6">
                <div className="mr-10">
                  <SelectMenu
                    label={t("PaymentTypeLabelRequired")}
                    className={"max-w-xs min-w-[320px]"}
                    isSearchable={false}
                    value={paymentTypeOptions.find((item) => {
                      if (item.value === paymentType) {
                        return item;
                      }
                    })}
                    options={paymentTypeOptions}
                    placeholder={t("PaymenttypePlaceholder")}
                    onChange={(value) => {
                      formValues.paymentType = value.value;
                      setPaymentType(value.value);
                    }}
                  />
                </div>

                {paymentType === "Percentage" ?
                  <div className=" mt-1 min-w-[200px]">
                    <PercentField
                      className={"max-w-xs  "}
                      label={t("PercentageAmount")}
                      inputClassName={"p-[5px] w-[150px]"}
                      value={formValues.percentage}
                      onChange={(e) => {
                        formValues.percentage = Number(e.target.value);
                        setPercentage(e.target.value);
                      }}
                    />
                  </div>
                  :
                  <div className="min-w-[250px]">
                    <CurrencyField
                      className={"max-w-xs mt-1"}
                      label={t("PaymentamountLabelRequired")}
                      inputClassName={"p-1"}
                      value={formValues.amount}
                      onChange={(value) => {
                        formValues.amount = Number(value.target.value);
                      }
                      }
                    />
                  </div>
                }
              </div> :
              <div className="min-w-[250px] m-1">
                <CurrencyField
                  className={"max-w-xs "}
                  label={t("PaymentamountLabelRequired")}
                  inputClassName={"p-1"}
                  value={formValues.amount}
                  onChange={(value) =>
                    (formValues.amount = Number(value.target.value))
                  }
                />
              </div>
            }
          </div>
          <div className="flex flex-wrap space-y-5">
            {paymentType && paymentType === "Percentage" && costCategory && costCategory.value === "home-owner-payment" && (
              <>
                <SelectMenu
                  label={t("ExtraServiceFeeRequired")}
                  className={"max-w-xs mt-5 mr-10"}
                  isSearchable={false}
                  options={includingOptions}
                  placeholder={t("Select")}
                  value={includingOptions.find((item) => {
                    if (item.value === extraServiceFee) {
                      return item;
                    }
                  })}
                  onChange={(value) => {
                    formValues.extraServiceFee = value.value;
                    setExtraServiceFee(value.value);
                  }}
                />
                <SelectMenu
                  label={t("OTACommissionRequired")}
                  className={"max-w-xs mr-10"}
                  isSearchable={false}
                  options={includingOptions}
                  value={includingOptions.find((item) => {
                    if (item.value === otaIncluded) {

                      return item;
                    }
                  })}
                  placeholder={t("Select")}
                  onChange={(value) => {
                    formValues.otaIncluded = value.value;
                    setOtaIncluded(value.value);
                  }}
                />
              </>
            )}
          </div>


        </div>
      </div>
      <Modal
        externalControl
        status={openNewSupplierModal}
        onClose={() => setOpenNewSupplierModal(false)}
        body={
          <NewSupplier
            onCloseModal={closeModal}
            onComplete={(value) => {
              setOpenNewSupplierModal(false);
              setSupplier(value, context.organizationId);
              context.updateData("suppliers");
            }}
          />
        }
        title={t("NewSupplier")}
        className={"w-fit"}
      />
      <Modal
        externalControl
        status={openNewBookingProviderModal}
        onClose={() => setOpenNewBookingProviderModal(false)}
        body={
          <NewBookingProvider
            onComplete={(value) => {
              setOpenNewBookingProviderModal(false);
              setBookingProvider(value, context.organizationId);
              context.updateData("booking-providers");
            }}
          />
        }
        title={t("NewBookingProvider")}
      />
      <div className="flex flex-row-reverse justify-start">

        <PrimaryButton
          content={t("Update")}
          className={"p-3 max-w-[16rem]"}
          onClick={() => {
            submit();
          }}
        />
        <TertiaryButton
          onClick={onClose}
          className={"bg-red h-auto p-3 w-fit mr-5 max-w-[80px]"}
          content={t("Close")}
        />
      </div>
    </div>
  );
};

export default CostEdit;
