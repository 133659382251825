import React, { useContext, useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import SecondaryButton from "../../components/buttons/secondary-button";
import TertiaryButton from "../../components/buttons/tertiary-button";
import TextField from "../../components/input/fields/text-field";
import { NewDatePicker } from "../../components/input/fields/date-pickers/newDatePicker";
import { UnitField } from "../../components/input/fields/unit-field";
import { SelectMenu } from "../../components/input/select/select-menu";
import { DataContext } from "..";
import { CurrencyField } from "../../components/input/fields/currency-field";
import { getCountries } from "react-phone-number-input";
import { createCost, updateBooking, updateCost, deleteCost } from "../../utils/firestore-functions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


const BookingManager = ({ bookingInfo, onComplete, onClose }) => {
  const { i18n, t } = useTranslation();
  const context = useContext(DataContext);
  const [index, setIndex] = useState(0);
  const [selectedCommission, setSelectedCommission] = useState(
    bookingInfo.commissionChannel ? bookingInfo.commissionChannel : false
  );
  const [selectedCommissionType, setSelectedCommissionType] = useState(() => {
    if (bookingInfo.commissionType)
      if (bookingInfo.commissionType.label === "Fixed") {
        return "Fixed";
      } else {
        return "Percentage";
      }
  });
  
  const [optionsCountry, setOptionsCountry] = useState(getCountries().map((countryCode) => {
    return { label: countryCode, value: countryCode };
  }));


  /* Forms Values */
  const [bookingDate, setBookingDate] = useState(bookingInfo.bookingDate);
  const [checkInDate, setCheckInDate] = useState(bookingInfo.checkInDate);
  const [checkOutDate, setCheckOutDate] = useState(bookingInfo.checkOutDate);
  const [numberOfGuest, setNumberOfGuest] = useState(bookingInfo.numberOfGuest);
  const [amount, setAmount] = useState(bookingInfo.amount);    
  const [bookingReference, setBookingReference] = useState(bookingInfo.bookingReference);  
  const [accomodationReference, setAccomodationReference] = useState(bookingInfo.accomodationReference);
  const [commissionChannel, setCommissionChannel] = useState(bookingInfo.commissionChannel);
  const [commissionAmount, setCommissionAmount] = useState(bookingInfo.commissionAmount);
  const [commissionType, setCommissionType] = useState(bookingInfo.commissionType);
  const [accommodationFare, setAccommodationFare] = useState(bookingInfo.accommodationFare);
  const [extraServiceFee, setServiceFee] = useState(bookingInfo.extraServiceFee);  
  const [selectedCountry, setSelectedCountry] = useState(bookingInfo.country);
  const [isDisabled, setIsDisabled] = useState("");
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  var accomodationOptions = context.loading
    ? null
    : context.accomodationsList &&
    context.accomodationsList.map((item) => {
      return { label: item.data.name, value: item.id };
    });

  const commissionChannelOption = new Array();
  const bookingChannel = new Object();

  bookingChannel.label = <div className="flex items-center justify-start space-x-5 w-fit"><p className="text-base"></p></div>;
  bookingChannel.value = null;
  bookingChannel.amount = null;
  bookingChannel.type = null;
  commissionChannelOption.push(bookingChannel);

  context.loading ? null : context.costsRuleList && context.costsRuleList.map((rule) => {
    const bookingChannel = new Object();
    if (rule.data.costTypology.value === 2 && rule.data.costCategory.value === "ota-commission" && (!rule.data.accomodationList || (rule.data.accomodationList && rule.data.accomodationList.indexOf(accomodationReference ? accomodationReference : bookingInfo.accomodationReference) > -1)) && (!rule.data.endDate || (rule.data.endDate && (rule.data.paymentDateType ? (rule.data.paymentDateType.value == "check-in" ? bookingInfo.checkInDate : (rule.data.paymentDateType.value == "check-out" ? bookingInfo.checkOutDate : bookingInfo.bookingDate)) : bookingInfo.bookingDate) < rule.data.endDate.toDate()))) {
      bookingChannel.label = <div className="flex items-center justify-start space-x-5 w-fit"><p className="text-base">{rule.data.name}</p></div>;
      bookingChannel.value = rule.data.name;
      bookingChannel.amount = rule.data.amount;
      bookingChannel.type = rule?.data?.paymentType?.value ?? '';
      commissionChannelOption.push(bookingChannel);
    }
  });
  
  const typeOfCommissionOption = [
    {
      label: t("Fixed"),
      value: "Fixed",
    },
    {
      label: t("Percentage"),
      value: "Percentage",
    },
  ];
  return (
    <div className="w-full">
      <Tab.Group selectedIndex={index} onChange={(value) => setIndex(value)}>
        <Tab.List className="flex p-1 space-x-1 rounded-xl bg-blue-900/20 bg-neutral-50 text-blue">
          {/** Headers */}
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                selected
                  ? "shadow bg-green font-extrabold"
                  : "text-blue-100 hover:bg-white/[0.12] hover:text-petroil"
              )
            }
          >
            {t("Details")}
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                selected
                  ? "shadow bg-green font-extrabold"
                  : "text-blue-100 hover:bg-white/[0.12] hover:text-petroil"
              )
            }
          >
            {t("Dates")}
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                selected
                  ? "shadow bg-green font-extrabold"
                  : "text-blue-100 hover:bg-white/[0.12] hover:text-petroil"
              )
            }
          >
            {"Costs"}
          </Tab>
        </Tab.List>
        {/** Tabs */}
        <Tab.Panels className="my-1 min-h-[25rem]">
          {/** Details */}
          <Tab.Panel
            key={0}
            className={classNames(
              "rounded-xl bg-white p-3",
              "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 space-y-6"
            )}
          >
            <TextField
              label={t("Reference")}
              inputClassName={"p-1 "}
              placeholder={t("Reference")}
              defaultValue={bookingReference}
              onChange={(e) => {
                setBookingReference(e.target.value)}
              }
            />
            <SelectMenu
              label={t("Accommodation")}
              defaultValue={accomodationOptions.find((value) =>
                accomodationReference
                  ? accomodationReference === value.value
                  : bookingInfo.accomodationReference === value.value
              )}
              options={accomodationOptions}
              onChange={(value) => {
                commissionChannelOption.splice(0, commissionChannelOption.length);
                context.costsRuleList && context.costsRuleList.map((rule) => {
                  const bookingChannel = new Object();
                  bookingChannel.label = <div className="flex items-center justify-start space-x-5 w-fit"><p className="text-base"></p></div>;
                  bookingChannel.value = null;
                  bookingChannel.amount = null;
                  bookingChannel.type = null;
                  commissionChannelOption.push(bookingChannel);
                  if (rule.data.costTypology.value === 2 && rule.data.costCategory.value === "ota-commission" && (!rule.data.accomodationList || (rule.data.accomodationList && rule.data.accomodationList.indexOf(value.value) > -1)) && (!rule.data.endDate || (rule.data.endDate && (rule.data.paymentDateType ? (rule.data.paymentDateType.value == "check-in" ? bookingInfo.checkInDate : (rule.data.paymentDateType.value == "check-out" ? bookingInfo.checkOutDate : bookingInfo.bookingDate)) : bookingInfo.bookingDate) < rule.data.endDate.toDate()))) {
                    bookingChannel.label = <div className="flex items-center justify-start space-x-5 w-fit"><p className="text-base">{rule.data.name}</p></div>;
                    bookingChannel.value = rule.data.name;
                    bookingChannel.amount = rule.data.amount;
                    bookingChannel.type = rule.data.paymentType.value;
                    commissionChannelOption.push(bookingChannel);
                  }
                });
                setAccomodationReference(value.value)
              }}
            />
            
            <UnitField
              label={t("NumberofGuests")}
              placeholder={numberOfGuest}
              value={numberOfGuest}
              onChange={(el) => setNumberOfGuest(Number(el.target.value))}
              inputClassName={"m-1 p-1 max-w-[100px]"}
            />
            <SelectMenu
            label={t("Country")}
            placeholder={t("Select")}
            className={"w-36 mr-5"}
            inputClassName={"p-1"}
            options={optionsCountry}
            defaultValue={optionsCountry && selectedCountry ? optionsCountry.find((item) => {
              if(selectedCountry === item.value) {                
                return item;
              }
            }) : selectedCountry}
            onChange={(item) => {
              setSelectedCountry(item.value);
            }}
          />
          
          </Tab.Panel>
          {/** Dates */}
          <Tab.Panel
            key={1}
            className={classNames(
              "rounded-xl bg-white p-3",
              "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 space-y-6"
            )}
          >
            <NewDatePicker
              label={t("BookingDate")}
              defaultValue={bookingDate}
              onCompleted={(el) => setBookingDate(el)}
            />
            <NewDatePicker
              label={t("CheckInDate")}
              defaultValue={checkInDate}
              onCompleted={(el) => setCheckInDate(el)}
            />
            <NewDatePicker
              label={t("CheckOutDate")}
              defaultValue={checkOutDate}
              onCompleted={(el) => setCheckOutDate(el)}
            />
          </Tab.Panel>
          {/** Commissions */}
          <Tab.Panel
            key={2}
            className={classNames(
              "rounded-xl bg-white p-3",
              "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 space-y-6"
            )}
          >
            <CurrencyField
              label={t("TotalAmount")}
              placeholder={amount}
              disabled={isDisabled}
              value={amount}
              onChange={(el) => setAmount(Number(el.target.value))}
              inputClassName={"p-1 max-w-[100px]"}
            />
            <CurrencyField
                className={""}
                label={t("ExtraServiceFee")}
                placeholder={"0"}
                value={extraServiceFee ?? 0}
                inputClassName={"p-[5px]"}
                onChange={(e) => {
                  setServiceFee(Number(e.target.value));
                }}
              />
              
            
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <div className="flex justify-end">
        <TertiaryButton
          onClick={onClose}
          className={"bg-red p-2 w-fit mr-5"}
          content={t("Close")}
        />
        <SecondaryButton
          className={"p-2 h-auto w-fit"}
          content={t("UpdateBooking")}
          onClick={() => {
            const formsValues = {
              accomodationReference: accomodationReference ? accomodationReference : bookingInfo.accomodationReference,
              amount: amount !== null ? amount : bookingInfo.amount,
              bookingDate: bookingDate ? bookingDate : bookingInfo.bookingDate,
              checkInDate: checkInDate ? checkInDate : bookingInfo.checkInDate,
              checkOutDate: checkOutDate ? checkOutDate : bookingInfo.checkOutDate,
              commissionChannel: commissionChannel,
              commissionAmount: commissionAmount !== null ? commissionAmount : bookingInfo.commissionAmount,
              numberOfGuest: numberOfGuest !== null ? numberOfGuest : bookingInfo.numberOfGuest,
              commissionType: commissionType,
              extraServiceFee: extraServiceFee ? extraServiceFee : bookingInfo.extraServiceFee,
              accommodationFare: accommodationFare ? accommodationFare : bookingInfo.accommodationFare,
              country: selectedCountry ? selectedCountry : bookingInfo.country,
              bookingReference: bookingReference ? bookingReference : bookingInfo.bookingReference
            };

            if (formsValues.bookingDate > formsValues.checkInDate) {
              toast.error (t("BookingDateShorter"));
              return;
            }

            if (formsValues.checkInDate > formsValues.checkOutDate) {
              toast.error (t("CheckInDateErrorShorter"));
              return;
            }


            if (
              !(
                formsValues.accomodationReference === bookingInfo.accomodationReference &&
                formsValues.amount === bookingInfo.amount &&
                formsValues.bookingDate === bookingInfo.bookingDate &&
                formsValues.checkInDate === bookingInfo.checkInDate &&
                formsValues.checkOutDate === bookingInfo.checkOutDate &&
                formsValues.commissionChannel === bookingInfo.commissionChannel &&
                formsValues.commissionAmount === bookingInfo.commissionAmount &&
                formsValues.numberOfGuest === bookingInfo.numberOfGuest &&
                formsValues.extraServiceFee === bookingInfo.extraServiceFee && 
                formsValues.country === bookingInfo.country &&
                formsValues.bookingReference === bookingInfo.bookingReference
              )
            ) {

              if (formsValues.extraServiceFee != bookingInfo.extraServiceFee || formsValues.amount != bookingInfo.amount){
                
                formsValues.accommodationFare = formsValues.amount - (formsValues.extraServiceFee ?? 0);
              }

              if (selectedCommissionType === "Percentage") {
                formsValues.commissionAmount = parseFloat(((formsValues.amount * commissionType.value) / 100).toFixed(2));
              }

              updateBooking(formsValues, bookingInfo.id, context.organizationId)
                .then(async () => {
                  onComplete();
                  context.updateData("bookings");

                  if (formsValues.commissionChannel) {
                    // Update ota commission cost
                    let otacommissioncosts = context.costsList.filter(x => (x.data.costCategory && x.data.costCategory.value === "ota-commission") && (x.data.costTypology && x.data.costTypology.value === 2) && x.data.bookingRef === bookingInfo.id);
                    if (otacommissioncosts.length > 0) {

                      let costDate = otacommissioncosts[0].data.costDate.toDate();
                      if (bookingInfo.bookingDate !== bookingDate && bookingInfo.bookingDate.toDateString() === costDate.toDateString()) {
                        costDate = bookingDate;
                      } else if (bookingInfo.checkInDate !== checkInDate && bookingInfo.checkInDate.toDateString()  === costDate.toDateString()) {
                        costDate = checkInDate;
                      } else if (bookingInfo.checkOutDate !== checkOutDate && bookingInfo.checkOutDate.toDateString()  === costDate.toDateString()) {
                        costDate = checkOutDate;
                      }

                      await updateCost(
                        { amount: formsValues.commissionAmount, costDate: costDate, paymentDate: costDate
                         },
                        otacommissioncosts[0].id,
                        context.organizationId
                      );
                    }
                    else {
                      const costRulesCommission = context.costsRuleList ? context.costsRuleList.filter(x => (x.data.costTypology && x.data.costTypology.value === 2) && (x.data.costCategory && x.data.costCategory.value == "ota-commission") && (x.data.name && x.data.name === commissionChannel) && (!x.data.accomodationList || (x.data.accomodationList && x.data.accomodationList.indexOf(formsValues.accomodationReference) > -1)) && (!x.data.endDate || (x.data.endDate && (x.data.paymentDateType ? (x.data.paymentDateType.value == "check-in" ? bookingInfo.checkInDate : (x.data.paymentDateType.value == "check-out" ? bookingInfo.checkOutDate : bookingInfo.bookingDate)) : bookingInfo.bookingDate) < x.data.endDate.toDate()))) : [];
                      
                      for (let i = 0; i < costRulesCommission.length; i++) {
                        let paymentDate = bookingInfo.bookingDate;
                        if (costRulesCommission[i].data.paymentDateType) {
                          if (costRulesCommission[i].data.paymentDateType.value == "check-in") {
                            paymentDate = bookingInfo.checkInDate;
                          }
                          else if (costRulesCommission[i].data.paymentDateType.value == "check-out") {
                            paymentDate = bookingInfo.checkOutDate;
                          }
                        }

                        await createCost(
                          {
                            name: costRulesCommission[i].data.name,
                            costDate: paymentDate,
                            paymentDate: paymentDate,
                            amount: formsValues.commissionAmount,
                            costTypology: { label: "Booking", value: 2 },
                            costCategory: costRulesCommission[i].data.costCategory,
                            accomodationList: [formsValues.accomodationReference],
                            bookingRef: bookingInfo.id,
                            costRuleRef: costRulesCommission[i].id,
                            supplierId: costRulesCommission[i].data.supplierId ? costRulesCommission[i].data.supplierId : null,
                          },
                          context.organizationId
                        );
                      }
                    }
                  }
                  else {
                    // Delete ota commission cost if commission channel is null
                    let otacommissioncosts = context.costsList.filter(x => (x.data.costCategory && x.data.costCategory.value === "ota-commission") && (x.data.costTypology && x.data.costTypology.value === 2) && x.data.bookingRef === bookingInfo.id);
                    if (otacommissioncosts.length > 0) {
                      for (let i = 0; i < otacommissioncosts.length; i++) {
                        try {
                          await deleteCost(otacommissioncosts[i].id, context.organizationId);
                        }
                        catch (ex) {
                          toast.error("Error while updating booking." + ex);
                        }
                      }
                    }
                  }

                  //Update other costs
                  let accomodationcosts = context.costsList.filter(x => (x.data.costCategory && x.data.costCategory.value !== "ota-commission") && (x.data.costTypology && x.data.costTypology.value === 2) && x.data.bookingRef === bookingInfo.id);
                  
                  //(x.data.paymentType && x.data.paymentType.value === "Percentage") &&
                  const costRulesAccomodation = context.costsRuleList ? context.costsRuleList.filter(x => (x.data.costTypology && x.data.costTypology.value === 2) && (x.data.costCategory && x.data.costCategory.value != "ota-commission") && (!x.data.accomodationList || (x.data.accomodationList && x.data.accomodationList.indexOf(formsValues.accomodationReference) > -1)) && (!x.data.endDate || (x.data.endDate && (x.data.paymentDateType ? (x.data.paymentDateType.value == "check-in" ? bookingInfo.checkInDate : (x.data.paymentDateType.value == "check-out" ? bookingInfo.checkOutDate : bookingInfo.bookingDate)) : bookingInfo.bookingDate) < x.data.endDate.toDate()))) : [];

                  for (let i = 0; i < costRulesAccomodation.length; i++) {
                    try {
                      let costCreated = accomodationcosts.find(x => x.data.costRuleRef === costRulesAccomodation[i].id);

                      if (costCreated) {
                        let costDate = costCreated.data.costDate.toDate();
                        if (bookingInfo.bookingDate !== bookingDate && bookingInfo.bookingDate.toDateString() === costDate.toDateString()) {
                          costDate = bookingDate;
                        } else if (bookingInfo.checkInDate !== checkInDate && bookingInfo.checkInDate.toDateString()  === costDate.toDateString()) {
                          costDate = checkInDate;
                        } else if (bookingInfo.checkOutDate !== checkOutDate && bookingInfo.checkOutDate.toDateString()  === costDate.toDateString()) {
                          costDate = checkOutDate;
                        }
                        //controlliamo amount se percentage o meno
                        let amountCost = costCreated.data.amount; 
                      if (costRulesAccomodation[i].data.paymentType && costRulesAccomodation[i].data.paymentType.value === "Percentage"){

                        if(costRulesAccomodation[i].data.costCategory.value === "home-owner-payment"){
                          let tempTotal = formsValues.amount;
                          tempTotal -= costRulesAccomodation[i].data.extraServiceFee === "Excluded" ? formsValues.extraServiceFee ?? 0 : 0;
                          tempTotal -= costRulesAccomodation[i].data.otaIncluded === "Excluded" ? formsValues.commissionAmount ?? 0 : 0;
                          amountCost = Number(((tempTotal / 100 ) * (costRulesAccomodation[i].data.amount)).toFixed(2));       
          
                        }else{
                          amountCost = parseFloat(((costRulesAccomodation[i].data.amount * formsValues.amount) / 100).toFixed(2));
                        }

                      }

                      if (costCreated.data.amount != amountCost || costCreated.data.costDate.toDate() != costDate){

                        updateCost(
                          {
                            amount: amountCost,
                            costDate: costDate,
                            paymentDate: costDate,
                            otaIncluded: costRulesAccomodation[i].data.otaIncluded ?? null,
                            extraServiceFee: costRulesAccomodation[i].data.extraServiceFee ?? null,
                            percentage: costRulesAccomodation[i].data.percentage ?? null,
                            paymentType: costRulesAccomodation[i].data.paymentType.value ?? "Fixed",
                          },
                          costCreated.id,
                          context.organizationId
                        );
                      }

                      }

                      else {
                        let paymentDate = bookingInfo.bookingDate;
                        if (costRulesAccomodation[i].data.paymentDateType) {
                          if (costRulesAccomodation[i].data.paymentDateType.value == "check-in") {
                            paymentDate = bookingInfo.checkInDate;
                          }
                          else if (costRulesAccomodation[i].data.paymentDateType.value == "check-out") {
                            paymentDate = bookingInfo.checkOutDate;
                          }
                        }

                        let amountCost = costRulesAccomodation[i].data.amount; 
                        if (costRulesAccomodation[i].data.paymentType && costRulesAccomodation[i].data.paymentType.value === "Percentage"){
  
                          if(costRulesAccomodation[i].data.costCategory.value === "home-owner-payment"){
                            let tempTotal = formsValues.amount;
                            tempTotal -= costRulesAccomodation[i].data.extraServiceFee === "Excluded" ? formsValues.extraServiceFee ?? 0 : 0;
                            tempTotal -= costRulesAccomodation[i].data.otaIncluded === "Excluded" ? formsValues.commissionAmount ?? 0 : 0;
                            amountCost = Number(((tempTotal / 100 ) * (costRulesAccomodation[i].data.amount)).toFixed(2));       
            
                          }else{
                            amountCost = parseFloat(((costRulesAccomodation[i].data.amount * formsValues.amount) / 100).toFixed(2));
                          }
  
                        }

                        await createCost(
                          {
                            name: costRulesAccomodation[i].data.name,
                            costDate: paymentDate,
                            paymentDate: paymentDate,
                            amount: amountCost,
                            costTypology: { label: "Booking", value: 2 },
                            costCategory: costRulesAccomodation[i].data.costCategory,
                            accomodationList: [formsValues.accomodationReference],
                            bookingRef: bookingInfo.id,
                            costRuleRef: costRulesAccomodation[i].id,
                            supplierId: costRulesAccomodation[i].data.supplierId ? costRulesAccomodation[i].data.supplierId : null,
                            otaIncluded: costRulesAccomodation[i].data.otaIncluded ?? null,
                            extraServiceFee: costRulesAccomodation[i].data.extraServiceFee ?? null,
                            percentage: costRulesAccomodation[i].data.percentage ?? null,
                            paymentType: costRulesAccomodation[i].data.paymentType.value ?? "Fixed",
                          },
                          context.organizationId
                        );
                      }
                    }
                    catch (e) {
                      toast.error(t("ErrorOccurred") + e);
                    }
                  }
                  context.updateData("costs");
                })
                .catch((err) => {
                  toast.error(t("ErrorOccurred") + err);
                });
            }
          }}
        />
      </div>
    </div>
  );
};

export default BookingManager;