export const TermsAndConditions = {
  en: `
  <p class="mb-4">BiLemon is a tool of business intelligence for hosts and property managers who have accommodations to rent (collectively, the “Services”), which Services are accessible at bilemon.com and any other websites through which BiLemon makes the Services available (collectively, the “Site”) and as an application for mobile devices (the “Application”). Whether you register or not you will be bound by these terms and conditions in full and you also agree to be bound by the terms of our Privacy policy which explains the use we are authorized to make of your personal information.</p>
  <p class="mb-4">By using the Site or Application, you agree to comply with and be legally bound by the terms and conditions of these Terms of Service (“Terms”), whether or not you become a registered user of the Services. These Terms govern your access to and use of the Site, Application and Services and all Collective Content (defined below).</p>
  <p class="mb-4 text-petroil font-semibold text-lg text-left">Modifications</p>
  <p class="mb-4">We reserve the right to revise these Terms from time to time. The most current version will always be posted on our website. We will notify you via email to the email address associated with your account for any significant changes to the Terms. Other changes may be posted to our blog or terms page, so please check those pages regularly. By continuing to access or use the Site and Content after revisions become effective, you agree to be bound by the revised Terms.</p>
  <p class="mb-4 text-petroil font-semibold text-lg text-left">Cancellation and Termination</p>
  <p class="mb-4">On receipt of a request to cancel your registration then we will terminate your registration within the next 48 hours. We reserve the right to terminate your registration immediately without notice if in our opinion you have breached these terms.</p>
  <p class="mb-4 text-petroil font-semibold text-lg text-left">Intellectual Property</p>
  <p class="mb-4">The format and content of this Site is protected by Spain and international copyright and we reserve all rights in relation to our copyright whether owned or licensed to us and all rights are reserved to any of our registered and unregistered trademarks (whether owned or licensed to us) which appear on this Site.</p>
  <p class="mb-4">This website or any portion of this website may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without our express written consent. You may not systematically extract and/or re-utilize parts of the contents of the Site without our express written consent. In particular, you may not utilize any data mining, robots, or similar data gathering and extraction tools to extract (whether once or many times) for re-utilization of any substantial parts of this Site without our express written consent.</p>
  <p class="mb-4 text-petroil font-semibold text-lg text-left">Your account</p>
  <p class="mb-4">By using our Site and Content, you choose to provide us with private account information. If you choose to automate the pricing service, you give us the permission to update pricing information on your behalf. We will not change any other information on your related account.</p>
  <p class="mb-4">Personal information that you supply to us will not be disclosed by us to any third party save in accordance with our Privacy Policy. You agree that we may use the personal information supplied by you in accordance with our Privacy Policy</p>
  <p class="mb-4 text-petroil font-semibold text-lg text-left">Your Listing</p>
  <p class="mb-4">We are not responsible for the success of your listing, and profits. By using our Site and Content, you understand that optimization is all about probability. We will operate the Site, Content and profit information with reasonable skill. You will continue to have control over your pricing information and can update it as you deem appropriate.</p>
  <p class="mb-4">By using our Site and Content, you understand that we are not responsible for guests who use your listing. You (or who you choose to authorize), and not BiLemon, will be responsible for any bookings or maintenance of records.</p>
  `,
  it: `
  <p class="mb-4">BiLemon è uno strumento di business intelligence per host e gestori di proprietà che dispongono di alloggi da affittare (collettivamente, i "Servizi"), i quali sono accessibili all'indirizzo bilemon.com e qualsiasi altro sito web attraverso il quale BiLemon rende disponibili i Servizi (collettivamente, il "Sito") e come applicazione per dispositivi mobili (l'"Applicazione"). Indipendentemente dalla registrazione o meno, l'utente sarà vincolato dai presenti termini e condizioni nella loro interezza e accetta inoltre di essere vincolato dai termini della nostra Informativa sulla privacy, che spiega l'uso che siamo autorizzati a fare delle informazioni personali dell'utente.</p>
  <p class="mb-4">Utilizzando il Sito o l'Applicazione, l'utente accetta di rispettare e di essere legalmente vincolato dai termini e dalle condizioni dei presenti Termini di servizio ("Termini"), indipendentemente dal fatto che diventi o meno un utente registrato dei Servizi. I presenti Termini regolano l'accesso e l'utilizzo del Sito, dell'Applicazione e dei Servizi e di tutti i Contenuti collettivi (definiti di seguito).</p>
  <p class="mb-4 text-petroil font-semibold text-lg text-left">Modifiche</p>
  <p class="mb-4">Ci riserviamo il diritto di rivedere i presenti Termini di volta in volta. La versione più aggiornata sarà sempre pubblicata sul nostro sito web. Per qualsiasi modifica significativa dei Termini, l'utente riceverà una notifica via e-mail all'indirizzo di posta elettronica associato al suo account. Altre modifiche possono essere pubblicate sul nostro blog o sulla pagina dei termini, pertanto si prega di controllare regolarmente tali pagine. Continuando ad accedere o a utilizzare il Sito e i Contenuti dopo l'entrata in vigore delle modifiche, l'utente accetta di essere vincolato ai Termini modificati.</p>
  <p class="mb-4 text-petroil font-semibold text-lg text-left">Cancellazione e risoluzione</p>
  <p class="mb-4">Al ricevimento di una richiesta di cancellazione della registrazione, la registrazione verrà interrotta entro le 48 ore successive. Ci riserviamo il diritto di interrompere immediatamente la registrazione senza preavviso se, a nostro avviso, l'utente ha violato i presenti termini.</p>
  <p class="mb-4 text-petroil font-semibold text-lg text-left">Proprietà intellettuale</p>
  <p class="mb-4">Il formato e il contenuto di questo sito sono protetti dal diritto d'autore spagnolo e internazionale e ci riserviamo tutti i diritti in relazione al nostro diritto d'autore, sia di proprietà che in licenza, e tutti i diritti sono riservati a tutti i nostri marchi registrati e non registrati (sia di proprietà che in licenza) che appaiono su questo sito.</p>
  <p class="mb-4">Questo sito o qualsiasi parte di esso non può essere riprodotto, duplicato, copiato, venduto, rivenduto, visitato o altrimenti sfruttato per qualsiasi scopo commerciale senza il nostro esplicito consenso scritto. Non potete estrarre e/o riutilizzare sistematicamente parti del contenuto del Sito senza il nostro esplicito consenso scritto. In particolare, non è possibile utilizzare strumenti di data mining, robot o strumenti simili per la raccolta e l'estrazione di dati per estrarre (una o più volte) per il riutilizzo di parti sostanziali di questo Sito senza il nostro esplicito consenso scritto.</p>
  <p class="mb-4 text-petroil font-semibold text-lg text-left">Il vostro account</p>
  <p class="mb-4">Utilizzando il nostro Sito e i nostri Contenuti, l'utente sceglie di fornirci informazioni private sul proprio account. Se scegliete di automatizzare il servizio prezzi, ci autorizzate ad aggiornare le informazioni sui prezzi per vostro conto. Non modificheremo nessun'altra informazione sul vostro account.</p>
  <p class="mb-4">Le informazioni personali fornite dall'utente non saranno divulgate da noi a terzi, se non in conformità con la nostra Politica sulla privacy. L'utente accetta di utilizzare le informazioni personali fornite dall'utente in conformità con la nostra Politica sulla privacy.</p>
  <p class="mb-4 text-petroil font-semibold text-lg text-left">Il vostro annuncio</p>
  <p class="mb-4">Non siamo responsabili del successo della vostra inserzione e dei vostri profitti. Utilizzando il nostro Sito e i nostri Contenuti, l'utente comprende che l'ottimizzazione è tutta una questione di probabilità. Il Sito, i Contenuti e le informazioni sui profitti saranno gestiti con ragionevole competenza. L'utente continuerà a controllare le proprie informazioni sui prezzi e potrà aggiornarle quando lo riterrà opportuno.</p>
  <p class="mb-4">Utilizzando il Sito e i Contenuti, l'utente è consapevole che non siamo responsabili degli ospiti che utilizzano il suo annuncio. L'utente (o chi per lui), e non BiLemon, sarà responsabile di eventuali prenotazioni o del mantenimento dei registri.</p>
`,
  es: `
  <p class="mb-4">BiLemon es una herramienta de inteligencia empresarial para anfitriones y administradores de propiedades que tienen alojamientos para alquilar (colectivamente, los "Servicios"), a cuyos Servicios se puede acceder en bilemon.com y en cualquier otro sitio web a través del cual BiLemon ponga a disposición los Servicios (colectivamente, el "Sitio") y como aplicación para dispositivos móviles (la "Aplicación"). Tanto si se registra como si no, quedará vinculado por estos términos y condiciones en su totalidad y también acepta quedar vinculado por los términos de nuestra Política de privacidad, que explica el uso que estamos autorizados a hacer de su información personal.</p>
  <p class="mb-4">Al utilizar el Sitio o la Aplicación, usted acepta cumplir y estar legalmente obligado por los términos y condiciones de estas Condiciones de Servicio ("Condiciones"), independientemente de que se convierta o no en un usuario registrado de los Servicios. Estas Condiciones rigen su acceso y uso del Sitio, la Aplicación y los Servicios, así como todo el Contenido Colectivo (definido más adelante).</p>
  <p class="mb-4 text-petroil font-semibold text-lg text-left">Modificaciones</p>
  <p class="mb-4">Nos reservamos el derecho a revisar estas Condiciones periódicamente. La versión más actualizada se publicará siempre en nuestro sitio web. Le notificaremos por correo electrónico a la dirección de correo electrónico asociada a su cuenta cualquier cambio significativo en las Condiciones. Es posible que se publiquen otros cambios en nuestro blog o en nuestra página de condiciones, por lo que le rogamos que consulte estas páginas con regularidad. Al continuar accediendo o utilizando el Sitio y el Contenido después de que las revisiones entren en vigor, usted acepta quedar vinculado por los Términos revisados.</p>
  <p class="mb-4 text-petroil font-semibold text-lg text-left">Cancelación y rescisión</p>
  <p class="mb-4">Cuando recibamos una solicitud de cancelación de su inscripción, la cancelaremos en las 48 horas siguientes. Nos reservamos el derecho a cancelar su registro inmediatamente sin previo aviso si, en nuestra opinión, ha incumplido estas condiciones.</p>
  <p class="mb-4 text-petroil font-semibold text-lg text-left">Propiedad intelectual</p>
  <p class="mb-4">El formato y el contenido de este sitio web están protegidos por derechos de autor españoles e internacionales y nos reservamos todos los derechos en relación con nuestros derechos de autor, ya sean de nuestra propiedad o nos hayan sido concedidos bajo licencia, así como todos los derechos sobre cualquiera de nuestras marcas registradas y no registradas (ya sean de nuestra propiedad o nos hayan sido concedidas bajo licencia) que aparezcan en este sitio web.</p>
  <p class="mb-4">Este sitio web o cualquier parte del mismo no podrá reproducirse, duplicarse, copiarse, vendarse, revendarse, visitarse ni explotarse de ningún otro modo con fines comerciales sin nuestro consentimiento expreso por escrito. No podrá extraer y/o reutilizar sistemáticamente partes del contenido del sitio sin nuestro consentimiento expreso por escrito. En particular, no podrá utilizar ningún tipo de minería de datos, robots o herramientas similares de recopilación y extracción de datos para extraer (ya sea una o varias veces) para su reutilización partes sustanciales de este Sitio sin nuestro consentimiento expreso por escrito.</p>
  <p class="mb-4 text-petroil font-semibold text-lg text-left">Su cuenta</p>
  <p class="mb-4">Al utilizar nuestro Sitio y nuestro Contenido, usted decide proporcionarnos información privada sobre su cuenta. Si elige automatizar el servicio de precios, nos da permiso para actualizar la información de precios en su nombre. No cambiaremos ninguna otra información de su cuenta relacionada.</p>
  <p class="mb-4">La información personal que nos proporcione no será revelada por nosotros a terceros, salvo de conformidad con nuestra Política de Privacidad. Usted acepta que utilicemos la información personal que nos facilite de acuerdo con nuestra Política de Privacidad.</p>
  <p class="mb-4 text-petroil font-semibold text-lg text-left">Su anuncio</p>
  <p class="mb-4">No somos responsables del éxito de su anuncio ni de sus beneficios. Al utilizar nuestro Sitio y Contenido, usted entiende que la optimización es cuestión de probabilidad. Manejaremos el Sitio, el Contenido y la información de beneficios con habilidad razonable. Usted seguirá teniendo el control sobre su información de precios y podrá actualizarla cuando lo considere oportuno.</p>
  <p class="mb-4">Al utilizar nuestro Sitio y Contenido, usted entiende que no somos responsables de los huéspedes que utilicen su anuncio. Usted (o quien usted decida autorizar), y no BiLemon, será responsable de cualquier reserva o mantenimiento de registros.</p>
`,
  fr: `
  <p class="mb-4">BiLemon est un outil d'intelligence économique pour les hôtes et les gestionnaires immobiliers qui ont des logements à louer (collectivement, les "Services"), lesquels Services sont accessibles sur bilemon.com et tout autre site web par le biais duquel BiLemon met les Services à disposition (collectivement, le "Site") et sous forme d'application pour les appareils mobiles (l'"Application"). Que vous vous inscriviez ou non, vous serez lié par l'intégralité des présentes conditions générales et vous acceptez également d'être lié par les conditions de notre politique de confidentialité qui explique l'utilisation que nous sommes autorisés à faire de vos informations personnelles.</p>
  <p class="mb-4">En utilisant le site ou l'application, vous acceptez de vous conformer et d'être légalement lié par les termes et conditions des présentes conditions de service ("conditions"), que vous deveniez ou non un utilisateur enregistré des services. Les présentes conditions régissent votre accès et votre utilisation du site, de l'application et des services, ainsi que de l'ensemble du contenu collectif (défini ci-dessous).</p>
  <p class="mb-4 text-petroil font-semibold text-lg text-left">Modifications</p>
  <p class="mb-4">Nous nous réservons le droit de réviser les présentes conditions de temps à autre. La version la plus récente sera toujours publiée sur notre site web. Nous vous informerons par courrier électronique à l'adresse associée à votre compte de toute modification importante des conditions. D'autres modifications peuvent être publiées sur notre blog ou sur la page des conditions, veuillez donc consulter ces pages régulièrement. En continuant à accéder ou à utiliser le site et le contenu après l'entrée en vigueur des modifications, vous acceptez d'être lié par les conditions révisées.</p>
  <p class="mb-4 text-petroil font-semibold text-lg text-left">Annulation et résiliation</p>
  <p class="mb-4">Dès réception d'une demande d'annulation de votre inscription, nous mettrons fin à votre inscription dans les 48 heures qui suivent. Nous nous réservons le droit de mettre fin à votre inscription immédiatement et sans préavis si nous estimons que vous avez enfreint les présentes conditions.</p>
  <p class="mb-4 text-petroil font-semibold text-lg text-left">Propriété intellectuelle</p>
  <p class="mb-4">Le format et le contenu de ce site sont protégés par les droits d'auteur espagnols et internationaux et nous nous réservons tous les droits relatifs à nos droits d'auteur, qu'ils nous appartiennent ou qu'ils fassent l'objet d'une licence, ainsi que tous les droits relatifs à nos marques déposées et non déposées (qu'elles nous appartiennent ou qu'elles fassent l'objet d'une licence) qui apparaissent sur ce site.</p>
  <p class="mb-4">Ce site ou toute partie de ce site ne peut être reproduit, dupliqué, copié, vendu, revendu, visité ou exploité de quelque manière que ce soit à des fins commerciales sans notre autorisation écrite expresse. Vous ne pouvez pas extraire et/ou réutiliser systématiquement des parties du contenu du site sans notre autorisation écrite expresse. En particulier, vous ne pouvez pas utiliser d'outils d'exploration de données, de robots ou d'outils similaires de collecte et d'extraction de données pour extraire (une ou plusieurs fois) des parties substantielles de ce site en vue de leur réutilisation sans notre consentement écrit exprès.</p>
  <p class="mb-4 text-petroil font-semibold text-lg text-left">Votre compte</p>
  <p class="mb-4">En utilisant notre site et son contenu, vous choisissez de nous fournir des informations privées sur votre compte. Si vous choisissez d'automatiser le service de tarification, vous nous autorisez à mettre à jour les informations de tarification en votre nom. Nous ne modifierons aucune autre information sur votre compte.</p>
  <p class="mb-4">Les informations personnelles que vous nous fournissez ne seront pas divulguées par nous à des tiers, sauf conformément à notre politique de confidentialité. Vous acceptez que nous utilisions les informations personnelles que vous nous avez fournies conformément à notre politique de confidentialité.</p>
  <p class="mb-4 text-petroil font-semibold text-lg text-left">Votre inscription</p>
  <p class="mb-4">Nous ne sommes pas responsables du succès de votre annonce et de vos bénéfices. En utilisant notre site et notre contenu, vous comprenez que l'optimisation est une question de probabilité. Nous exploiterons le site, le contenu et les informations sur les bénéfices avec une compétence raisonnable. Vous continuerez à contrôler les informations relatives à vos prix et pourrez les mettre à jour si vous le jugez nécessaire.</p>
  <p class="mb-4">En utilisant notre site et son contenu, vous comprenez que nous ne sommes pas responsables des clients qui utilisent votre annonce. C'est vous (ou la personne que vous choisissez d'autoriser), et non BiLemon, qui serez responsable des réservations ou de la tenue des registres.</p>
`
};
