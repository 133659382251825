import React, { useState, useEffect, useRef } from "react";
import { IoInformationCircleOutline } from "react-icons/io5";
import Modal from "../../../../client/src/components/dialogs/dialog";
import { Info } from "../../resources/info";
import TertiaryButton from "./tertiary-button";
import { useTranslation } from "react-i18next";

const InfoButton = ({ nameComponent }) => {
    const { i18n, t } = useTranslation();
    const [viewModal, setViewModal] = useState(false);
    const modalRef = useRef(null);
    const [activeId, setActiveId] = useState(nameComponent);
    const [section, setSection] = useState("Accomodations");

    useEffect(() => {

        switch (activeId) {
                case "accommodations":
                    setSection("Accomodations");
                    break;
                case "ota":
                    setSection("OTA Commissions");
                    break;
                case "costRules":
                    setSection("Cost Rules");
                    break;
                case "bookings":
                    setSection("Bookings");
                    break;
                case "costs":
                    setSection("Costs");
                    break;
                default:
                    setSection("Accomodations");
                    setActiveId("accommodations");
                    break;
            }

    }, [nameComponent, activeId]);

    const handleSectionClick = (id) => {
        setActiveId(id);

    };

    const handleClick = () => {
        setViewModal(true);
    };



    return (
        <>
            <button
                onClick={handleClick}
                className="absolute top-10 flex items-center justify-center right-0 mx-8 text-petroil hover:brightness-125 rounded-full w-12 h-12 bg-transparent"
            >
                <IoInformationCircleOutline size={30} />
            </button>

            <Modal
                externalControl={true}
                status={viewModal}
                title={'Information - ' + section}
                icon={<IoInformationCircleOutline size={30} />}
                className="max-w-[70vw] "
                body={
                    <>
                        <div className='flex row mt-12' ref={modalRef}>
                            <div className='col-md-5 col-12 min-w-[10vw] cuteBorder'>
                                <ul className="navLeft pr-12 pl-4 customNavLeft">
                                    {Info.map(section => (

                                        <li className="pb-2" key={section.id}>
                                            <a
                                                className={`list-group-item list-group-item-action text-lg ${activeId === section.id ? 'active' : ''}`}
                                                onClick={() => handleSectionClick(section.id)}
                                            >{section.title}</a>
                                        </li>

                                    ))}
                                </ul>
                            </div>
                            <div className='col-md-7 col-12 h-[60vh] overflow-hidden overflow-y-auto'>

                                <div className="px-6">
                                    {Info.map((item, index) => {
                                        if (item.id === activeId) {
                                            return (
                                                <p className="text-base mb-5 " key={index} dangerouslySetInnerHTML={{ __html: item.content }} />
                                            )
                                        }

                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="flex row justify-end">
                            <TertiaryButton
                                onClick={() => {
                                    setViewModal(false);
                                }}
                                className={"bg-red p-2 w-fit mt-10 max-w-[50px]"}
                                content={t("Close")}
                            />
                        </div>
                    </>
                }
                onClose={() => {
                    setViewModal(false);
                }}

            />
        </>
    );
};

export default InfoButton;
