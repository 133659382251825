import React, { useEffect, useState } from "react";
import {
  getCountries,
  getCountryCallingCode,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { toast } from "react-toastify";
import PrimaryButton from "../../../../components/buttons/primary-button";
import TextField from "../../../../components/input/fields/text-field";
import { SelectMenu } from "../../../../components/input/select/select-menu";

export const FirstStep = ({ onComplete, formsValue }) => {
  /* Select Options */
  const [options, setOptions] = useState();
  /* Form Values */
  const [fullName, setFullName] = useState(formsValue.fullName);
  const [selectedPrefix, setSelectedPrefix] = useState(
    formsValue.selectedPrefix
  );
  const [phoneNumber, setPhoneNumber] = useState(formsValue.phoneNumber);

  /* Init Options */
  useEffect(() => {
    setOptions(
      getCountries().map((countryCode) => {
        return { label: countryCode, value: countryCode };
      })
    );
  }, []);

  /* Functions */
  const submit = () => {
    var formattedPhoneNumber = null;
    if (!(phoneNumber && fullName && selectedPrefix)) {
      toast.error("Check fields");
      return;
    }
    formattedPhoneNumber =
      "+" + getCountryCallingCode(selectedPrefix.value) + phoneNumber;

    formattedPhoneNumber = String(formattedPhoneNumber).replace(
      /(\(|\))|\s|-/g,
      ""
    );
    if (!isValidPhoneNumber(formattedPhoneNumber)) {
      toast.error("Invalid phone number");
      return;
    }
    onComplete({
      phoneNumber: phoneNumber,
      fullName: fullName,
      selectedPrefix: selectedPrefix,
      formattedPhoneNumber: formattedPhoneNumber,
    });
  };

  return (
    <>
      <div className="flex flex-col items-center w-full my-1 space-y-2">
        <TextField
          label={"Full name *"}
          inputClassName={"p-1"}
          onChange={(e) => setFullName(e.target.value)}
          defaultValue={fullName}
          placeholder={"Insert your first and last name"}
        />
        <div className="flex items-center justify-between w-full">
          <SelectMenu
            label={"Prefix *"}
            placeholder={"Select"}
            className={"w-36 mr-5"}
            inputClassName={"p-1"}
            options={options}
            onChange={(item) => {
              setSelectedPrefix(item);
            }}
            defaultValue={selectedPrefix}
          />
          <TextField
            label={"Phone number *"}
            inputClassName={"p-1"}
            placeholder={"Insert your phone number"}
            onChange={(e) => setPhoneNumber(e.target.value)}
            defaultValue={phoneNumber}
          />
        </div>
      </div>
      <PrimaryButton content={"Next >"} className="p-2" onClick={submit} />
    </>
  );
};
