// ResetButton.js
import React from 'react';
import { useTranslation } from "react-i18next";

const ResetButton = ({ onReset }) => {
  const { i18n, t } = useTranslation();
  return <button onClick={onReset}>{t("ResetFilters")}</button>;
};

export default ResetButton;
