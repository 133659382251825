import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IoMdAddCircleOutline } from "react-icons/io";
import { AccomodationCard } from "../../components/card/accomodation-card";
import { DataContext } from "..";
import { SelectMenu } from "../../components/input/select/select-menu";
import InfoButton from "../../components/buttons/info-button";
import { useTranslation } from "react-i18next";

export const Accomodations = () => {
  const context = useContext(DataContext);
  const { i18n, t } = useTranslation();
  const accomodationsList = useContext(DataContext).accomodationsList;
  const [filterAccomodation, setFilterAccomodation] = useState();
  const [filterStatus, setFilterStatus] = useState(null);



  var options = context.loading
    ? null
    : accomodationsList &&
    accomodationsList.map((item) => {
      return { label: item.data.name, value: item.id };
    });

  var optionsStatus = [
    {
      label: t('Active'),
      value: "Active"
    },
    {
      label: t('Disabled'),
      value: "Disabled"
    }
  ]

  let filtredAccomodationList = Array();

  if (filterAccomodation && filterStatus) {
    accomodationsList && context.accomodationsList.map((x) => {
      filterAccomodation.map((valueFilter) => {
        if (valueFilter.value === x.id && x.data.status.value === filterStatus.value) {
          filtredAccomodationList.push(x);
        }
      })
    })
  } else if (filterAccomodation && !filterStatus) {
    accomodationsList && context.accomodationsList.map((x) => {
      filterAccomodation.map((valueFilter) => {
        if (valueFilter.value === x.id) {
          filtredAccomodationList.push(x);
        }
      })
    })
  } else if (!filterAccomodation && filterStatus) {
    accomodationsList && context.accomodationsList.map((x) => {
      if (x.data.status.value === filterStatus.value) {
        filtredAccomodationList.push(x);
      }
    })
  }



  return (

    <div className="w-full">
      <InfoButton nameComponent={'accommodations'} />
      <div className="text-3xl font-bold ">{t("Accommodations")}</div>

      {((context.organizationInfo.subscription.plan === "host" && accomodationsList.length < 4) || (context.organizationInfo.subscription.plan === "propertyManager")) && (
        <Link
          className="flex flex-row items-center my-5 text-2xl font-normal cursor-pointer w-fit"
          to="new-accommodation"
        >
          <IoMdAddCircleOutline className="mr-1 w-[2rem]" />
          <p>{t("NewAccommodation")}</p>
        </Link>
      )

      }

      <div className="flex items-center justify-start space-x-5">
        <h4> {t("FilterAccommodationName")} </h4>
        <SelectMenu
          className={"max-w-[500px]"}
          placeholder={t("SelectPlaceholder")}
          options={options}
          isMulti={true}
          isClearable
          isLoading={context.loading}
          isDisabled={context.loading}
          isSearchable
          onChange={(value) => {
            if (value.length > 0) {
              setFilterAccomodation(value);
            } else {
              setFilterAccomodation(options)
            }
          }
          }
        />
        <h4>{t("FilterAccommodationStatus")}</h4>
        <SelectMenu
          className={"max-w-[250px]"}
          placeholder={t("SelectStatusPlaceholder")}
          options={optionsStatus}
          isMulti={false}
          isClearable={true}
          isLoading={context.loading}
          isDisabled={context.loading}
          isSearchable
          onChange={(e) => setFilterStatus(e)}
        />
      </div>
      <hr className="w-full max-w-20xl mb-6" />
      <div className="flex flex-wrap">
        {(filterAccomodation || filterStatus) && filtredAccomodationList.length > 0 ? (
          filtredAccomodationList.map((element, key) => (
            <AccomodationCard
              id={element.id}
              address={element.data.address}
              bathroom={element.data.numberBathroom}
              doubleBed={element.data.numberDoubleBed}
              singleBed={element.data.numberSingleBed}
              maxPeople={element.data.maxPeople}
              name={element.data.name}
              owner={element.data.owner}
              tax={element.data.touristTaxRate}
              status={element.data.status}
              key={key}
              contractTypology={element.data.contractTypology}
              numberRooms={element.data.numberRooms}
              basePrice={element.data.basePrice}
              extraServiceFee={element.data.extraServiceFee}
              occupancyRate={element.data.occupancyRate}
              annualRevenue={element.data.annualRevenue}
            />
          ))
        ) : (!filterAccomodation && !filterStatus) ? (
          accomodationsList.length > 0 ? (
            accomodationsList.map((element, key) => (
              <AccomodationCard
                id={element.id}
                address={element.data.address}
                bathroom={element.data.numberBathroom}
                doubleBed={element.data.numberDoubleBed}
                singleBed={element.data.numberSingleBed}
                maxPeople={element.data.maxPeople}
                name={element.data.name}
                owner={element.data.owner}
                tax={element.data.touristTaxRate}
                status={element.data.status}
                key={key}
                contractTypology={element.data.contractTypology}
                numberRooms={element.data.numberRooms}
                basePrice={element.data.basePrice}
                extraServiceFee={element.data.extraServiceFee}
                occupancyRate={element.data.occupancyRate}
                annualRevenue={element.data.annualRevenue}
              />
            ))
          ) : (
            <div className="mx-auto text-xl font-semibold text-center">
              {t("NoAccommodations")}
            </div>
          )
        ) : (
          <div className="mx-auto text-xl font-semibold text-center">
            {t("NoAccommodations")}
          </div>
        )}

      </div>
      {(accomodationsList.length > 4 && !filterAccomodation && !filterStatus) || (filtredAccomodationList.length > 3) ?
        <div className="font-bold  flex items-center justify-end p-4 text-petroil rounded-full ">
          <p className="mr-1">  {t("NeedSupport")}</p>
          <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
          >
            {t("ContactUs")}
          </a>
        </div>
        :
        <div className="font-bold absolute bottom-0 right-10 flex items-center justify-end p-4 text-petroil rounded-full ">
          <p className="mr-1">{t("NeedSupport")}</p>
          <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
          >
            {t("ContactUs")}
          </a>
        </div>
      }

    </div>




  );
};
