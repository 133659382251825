import { useState,  useEffect } from "react";
import { updateSubscriptionData } from "../../utils/firestore-functions";
import { toast } from "react-toastify";

export const PayPalButton = ({
    amount, 
    description,
    subscriptionInfo,
    organizationId,   
}) => {

  const [isEffectAllowed, setIsEffectAllowed] = useState(false);
 

useEffect(() => {

   
  if (!isEffectAllowed) {
    setIsEffectAllowed(true);
    return;
  }

  const newExpireDate = new Date(subscriptionInfo.expired.toDate());
  newExpireDate.setMonth((newExpireDate.getMonth() + 1));

    function initPayPalButton() {
        paypal.Buttons({
          style: {
            shape: 'rect',
            color: 'blue',
            layout: 'vertical',
            label: 'paypal',
          },
  
          createOrder: function (data, actions) {
            return actions.order.create({
              purchase_units: [{
                "amount": {
                  "currency_code": "EUR", "value": amount,
                  "breakdown": {
                    "item_total": {
                      "currency_code": "EUR",
                      "value": amount
                    }
                  }
                  },
                  "items": [
                    {
                      "name": "Subscription BiLemon",
                      "description": description,
                      "unit_amount": {
                        "currency_code": "EUR",
                        "value": amount
                      },
                      "quantity": "1"
                    },
                  ]
                }]
            });
          },
  
          onApprove: function (data, actions) {
            return actions.order.capture().then(function (orderData) {
              if(orderData && orderData.status === "COMPLETED"){
                updateSubscriptionData({
                  subscription: {
                  amount: subscriptionInfo.amount,
                  expired: newExpireDate,
                  plan: subscriptionInfo.plan,
                  properties: subscriptionInfo.properties,
                  recurrency: subscriptionInfo.recurrency,
                  amountExtra: subscriptionInfo.amountExtra,
                  propertiesExtra: 0
                  }
                }, organizationId);
                setTimeout(() => {
                  window.location.replace('/dashboard/settings/subscription');
                }, 2000); 
                useContext.updateData("organization-info");
                // Show a success message within this page, e.g.
                const element = document.getElementById('paypal-button-container');
                element.innerHTML = '';
                toast.success("Thank you for your payment!");
              }
              
            });
          },
  
          onError: function (err) {
            const element = document.getElementById('paypal-button-container');
              element.innerHTML = '';
              toast.error(err);            
          }
        }).render('#paypal-button-container');
    }
    initPayPalButton();
}, [isEffectAllowed]);

    return (
        <>
        <div className="mt-[50px]">
  <div id="smart-button-container">
  <div id="paypal-button-container"></div>
  </div>
  </div>
           
        </>
    )
}
