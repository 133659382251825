import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { AuthAppWrapper } from "./Auth-app-wanager";
import "./utils/i18n";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
  <BrowserRouter>
    <AuthAppWrapper />
  </BrowserRouter>
  </>
);
