import React, { useContext, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import { IoMdAddCircleOutline } from "react-icons/io";
import { deleteCostRule} from "../../utils/firestore-functions";
import PrimaryButton from "../../components/buttons/primary-button";
import { IoPencil, IoTrashBin } from "react-icons/io5";
import SecondaryButton from "../../components/buttons/secondary-button";
import Modal from "../../components/dialogs/dialog";
import { DataContext } from "..";
import { format } from "date-fns";
import { it } from 'date-fns/locale'; 
import CostRuleView from "./ViewCostRule";
import CostRuleEdit from "./EditCostRule";
import { NewDatePicker } from "../../components/input/fields/date-pickers/newDatePicker";
import { SelectMenu } from "../../components/input/select/select-menu";
import { toast } from "react-toastify";
import InfoButton from "../../components/buttons/info-button";
import TertiaryButton from "../../components/buttons/tertiary-button";
import ResetButton from "../../components/buttons/reset-button";
import { useTranslation } from "react-i18next";

function CostRules() {
  const context = useContext(DataContext);
  const { i18n, t } = useTranslation();
  let costsRulesList = context.costsRuleList;
  // const [costsRulesList, setCostsRulesList] = useState(context.costsRuleList);
  const [removeModal, setRemoveModal] = useState();
  const [costSelected, setCostSelected] = useState();
  const [tablePage, setTablePage] = useState(1);
  const [viewModal, setViewModal] = useState();
  const [editModal, setEditModal] = useState();
  const [filterDateFrom, setFilterDateFrom] = useState();
  const [filterDateTo, setFilterDateTo] = useState();
  const [costCreationDateOrder, setCreationDateOrder] = useState("reverse");
  const [filterRuleName, setFilterRuleName] = useState();
  const datePickerRef1 = useRef();
  const datePickerRef2 = useRef();
  const [selectedValue, setSelectedValue] = useState([]);

  function formatNumberWithComma(number) {
    if(number == null || number == undefined) { 
      return 0; 
    }
    return number.toString().replace('.', ',');
  }

  var options = context.loading
  ? null
  : context.costsRuleList &&
    context.costsRuleList.map((item) => {
      return { label: item.data.costrulename, value: item.id };
    });


  // var filtredCostList = Array();
  const filteredData = costsRulesList.filter((value) => {
    const creationDate = new Date(1000 * value.data.creationDate.seconds);
    const adjustedFilterDateTo = new Date(filterDateTo);

  if (filterDateFrom && filterDateTo && filterRuleName) {   
    
    adjustedFilterDateTo.setDate(adjustedFilterDateTo.getDate() + 1);

    return (
      format(filterDateFrom, "t") <= format(creationDate, "t") &&
      creationDate < adjustedFilterDateTo &&
      filterRuleName.some((valueFilter) => valueFilter.value === value.id)
    );
  } else if (filterDateFrom && filterDateTo) {    
    
    adjustedFilterDateTo.setDate(adjustedFilterDateTo.getDate() + 1);

    return (
      format(filterDateFrom, "t") <= format(creationDate, "t") &&
      creationDate < adjustedFilterDateTo
    );
  } else if (filterRuleName) {
    return filterRuleName.some((valueFilter) => valueFilter.value === value.id);
  } else {
    return true; // Nessun filtro applicato, mostra tutti i dati
  }
});

  
const resetSelect = () => {
  setSelectedValue([]);
};

const handleClearFilters = () => {
  datePickerRef1.current.clearDate();
  datePickerRef2.current.clearDate();
  setFilterDateFrom();
  setFilterDateTo();
  setFilterRuleName();
  resetSelect();
};

  const handleCreationDateOrder = () => {
    
    if (costCreationDateOrder === "sort") {
      costsRulesList = costsRulesList.reverse(
        (a, b) => a.data.creationDate.seconds - b.data.creationDate.seconds
      );
      setCreationDateOrder("reverse");
    }
    if (costCreationDateOrder === "reverse") {
      costsRulesList = costsRulesList.sort(
        (a, b) => a.data.creationDate.seconds - b.data.creationDate.seconds
      );
      setCreationDateOrder("sort");
    }
  };

  const showCostRuleInfo = () => {
    setViewModal(true);
  };

  const CostRuleRow = ({ costRuleInfo, id }) => {
    const supplierName = context.suppliesList.find((item) => item.id === costRuleInfo.supplierId);
    
    let accomodationName = "";
    if (costRuleInfo.accomodationList && costRuleInfo.accomodationList.length > 0) {
      accomodationName = costRuleInfo.accomodationList.map((accomodation) =>
        context.accomodationsList.find((value) => value.id === accomodation).data.name
      ).join(' - '); // Usa la virgola come separatore tra le sistemazioni
    }


    return (
      <tr className="border-b cursor-pointer hover:bg-neutral-100" id={id}>
                <td
          className="p-2 text-center border-r"
          onClick={() => {
            setCostSelected({
              id: id,
              accomodationList: costRuleInfo.accomodationList,
              amount: costRuleInfo.amount,
              costCategory: costRuleInfo.costCategory,
              costTypology: costRuleInfo.costTypology,
              costrulename: costRuleInfo.costrulename,
              name: costRuleInfo.name,
              creationDate: costRuleInfo.creationDate,
              executionDate: costRuleInfo.executionDate,
              recurrency: costRuleInfo.recurrency,
              recurrencyCustom: costRuleInfo.recurrencyCustom,
              paymentDateType: costRuleInfo.paymentDateType,
              endDate: costRuleInfo.endDate,
              paymentType: costRuleInfo.paymentType,
              supplierId: costRuleInfo.supplierId,
              paymentBased: costRuleInfo.paymentBased
            });
            showCostRuleInfo();
          }}
        >
          {costRuleInfo.costrulename}
        </td>
        <td
          className="text-center border-r"
          onClick={() => {
            setCostSelected({
              id: id,
              accomodationList: costRuleInfo.accomodationList,
              amount: costRuleInfo.amount,
              costCategory: costRuleInfo.costCategory,
              costTypology: costRuleInfo.costTypology,
              costrulename: costRuleInfo.costrulename,
              name: costRuleInfo.name,
              creationDate: costRuleInfo.creationDate,
              executionDate: costRuleInfo.executionDate,
              recurrency: costRuleInfo.recurrency,
              recurrencyCustom: costRuleInfo.recurrencyCustom,
              paymentDateType: costRuleInfo.paymentDateType,
              endDate: costRuleInfo.endDate,
              paymentType: costRuleInfo.paymentType,
              supplierId: costRuleInfo.supplierId,
              paymentBased: costRuleInfo.paymentBased
            });
            showCostRuleInfo();
          }}
        >
          {costRuleInfo.costTypology.label}
        </td>

        <td
          className="p-2 text-center border-r"
          onClick={() => {
            setCostSelected({
              id: id,
              accomodationList: costRuleInfo.accomodationList,
              amount: costRuleInfo.amount,
              costCategory: costRuleInfo.costCategory,
              costTypology: costRuleInfo.costTypology,
              costrulename: costRuleInfo.costrulename,
              name: costRuleInfo.name,
              creationDate: costRuleInfo.creationDate,
              executionDate: costRuleInfo.executionDate,
              recurrency: costRuleInfo.recurrency,
              recurrencyCustom: costRuleInfo.recurrencyCustom,
              paymentDateType: costRuleInfo.paymentDateType,
              endDate: costRuleInfo.endDate,
              paymentType: costRuleInfo.paymentType,
              supplierId: costRuleInfo.supplierId,
              paymentBased: costRuleInfo.paymentBased
            });
            showCostRuleInfo();
          }}
        >
          {costRuleInfo.costCategory.label}
        </td>
        <td
          className="p-2 text-center border-r"
          onClick={() => {
            setCostSelected({
              id: id,
              accomodationList: costRuleInfo.accomodationList,
              amount: costRuleInfo.amount,
              costCategory: costRuleInfo.costCategory,
              costTypology: costRuleInfo.costTypology,
              costrulename: costRuleInfo.costrulename,
              name: costRuleInfo.name,
              creationDate: costRuleInfo.creationDate,
              executionDate: costRuleInfo.executionDate,
              recurrency: costRuleInfo.recurrency,
              recurrencyCustom: costRuleInfo.recurrencyCustom,
              paymentDateType: costRuleInfo.paymentDateType,
              endDate: costRuleInfo.endDate,
              paymentType: costRuleInfo.paymentType,
              supplierId: costRuleInfo.supplierId,
              paymentBased: costRuleInfo.paymentBased
            });
            showCostRuleInfo();
          }}
        >
          {costRuleInfo.name}
        </td>
        <td
          className="text-center border-r"
          onClick={() => {
            setCostSelected({
              id: id,
              accomodationList: costRuleInfo.accomodationList,
              amount: costRuleInfo.amount,
              costCategory: costRuleInfo.costCategory,
              costTypology: costRuleInfo.costTypology,
              costrulename: costRuleInfo.costrulename,
              name: costRuleInfo.name,
              creationDate: costRuleInfo.creationDate,
              executionDate: costRuleInfo.executionDate,
              recurrency: costRuleInfo.recurrency,
              recurrencyCustom: costRuleInfo.recurrencyCustom,
              paymentDateType: costRuleInfo.paymentDateType,
              endDate: costRuleInfo.endDate,
              paymentType: costRuleInfo.paymentType,
              supplierId: costRuleInfo.supplierId,
              paymentBased: costRuleInfo.paymentBased
            });
            showCostRuleInfo();
          }}
        >
          {costRuleInfo.paymentType && costRuleInfo.paymentType.value == "Fixed" ? "€ " + formatNumberWithComma(costRuleInfo.amount) : formatNumberWithComma(costRuleInfo.amount) + " %"}
        </td> 
        <td
          className="text-center border-r"
          onClick={() => {
            setCostSelected({
              id: id,
              accomodationList: costRuleInfo.accomodationList,
              amount: costRuleInfo.amount,
              costCategory: costRuleInfo.costCategory,
              costTypology: costRuleInfo.costTypology,
              costrulename: costRuleInfo.costrulename,
              name: costRuleInfo.name,
              creationDate: costRuleInfo.creationDate,
              executionDate: costRuleInfo.executionDate,
              recurrency: costRuleInfo.recurrency,
              recurrencyCustom: costRuleInfo.recurrencyCustom,
              paymentDateType: costRuleInfo.paymentDateType,
              endDate: costRuleInfo.endDate,
              paymentType: costRuleInfo.paymentType,
              supplierId: costRuleInfo.supplierId,
              paymentBased: costRuleInfo.paymentBased
            });
            showCostRuleInfo();
          }}
        >
          {costRuleInfo.recurrency && costRuleInfo.recurrency.value == "custom" ? ("every " + costRuleInfo.recurrencyCustom + " days") : (costRuleInfo.recurrency && costRuleInfo.recurrency.label ? costRuleInfo.recurrency.label : "")}
        </td>
        <td
          className="text-center border-r"
          onClick={() => {
            setCostSelected({
              id: id,
              accomodationList: costRuleInfo.accomodationList,
              amount: costRuleInfo.amount,
              costCategory: costRuleInfo.costCategory,
              costTypology: costRuleInfo.costTypology,
              costrulename: costRuleInfo.costrulename,
              name: costRuleInfo.name,
              creationDate: costRuleInfo.creationDate,
              executionDate: costRuleInfo.executionDate,
              recurrency: costRuleInfo.recurrency,
              recurrencyCustom: costRuleInfo.recurrencyCustom,
              paymentDateType: costRuleInfo.paymentDateType,
              endDate: costRuleInfo.endDate,
              paymentType: costRuleInfo.paymentType,
              supplierId: costRuleInfo.supplierId,
              paymentBased: costRuleInfo.paymentBased
            });
            showCostRuleInfo();
          }}
        >
          {format(costRuleInfo.creationDate.toDate(), "dd/MM/yyyy", { locale: it }  )}
        </td>
        <td className="text-center border-r" 
                  onClick={() => {
                    setCostSelected({
                      id: id,
                      accomodationList: costRuleInfo.accomodationList,
                      amount: costRuleInfo.amount,
                      costCategory: costRuleInfo.costCategory,
                      costTypology: costRuleInfo.costTypology,
                      costrulename: costRuleInfo.costrulename,
                      name: costRuleInfo.name,
                      creationDate: costRuleInfo.creationDate,
                      executionDate: costRuleInfo.executionDate,
                      recurrency: costRuleInfo.recurrency,
                      recurrencyCustom: costRuleInfo.recurrencyCustom,
                      paymentDateType: costRuleInfo.paymentDateType,
                      endDate: costRuleInfo.endDate,
                      paymentType: costRuleInfo.paymentType,
                      supplierId: costRuleInfo.supplierId,
              paymentBased: costRuleInfo.paymentBased
                    });
                    showCostRuleInfo();
                  }}
                  >{accomodationName}</td>
        <td className="">
            <div className="flex items-center justify-center">
              <PrimaryButton
                onClick={() => {
                  setCostSelected({
                    id: id,
                    accomodationList: costRuleInfo.accomodationList,
                    amount: costRuleInfo.amount,
                    costCategory: costRuleInfo.costCategory,
                    costTypology: costRuleInfo.costTypology,
                    costrulename: costRuleInfo.costrulename,
                    name: costRuleInfo.name,
                    creationDate: costRuleInfo.creationDate,
                    executionDate: costRuleInfo.executionDate,
                    recurrency: costRuleInfo.recurrency,
                    recurrencyCustom: costRuleInfo.recurrencyCustom,
                    paymentDateType: costRuleInfo.paymentDateType,
                    endDate: costRuleInfo.endDate,
                    paymentType: costRuleInfo.paymentType,
                    supplierId: costRuleInfo.supplierId,
                    otaIncluded: costRuleInfo.otaIncluded, 
                    extraServiceFee: costRuleInfo.extraServiceFee
                  });
                  setEditModal(true);
                }}
                content={<IoPencil className="mx-auto" />}
                className={"p-1 mx-2 max-w-[100px]"}
              />
              <SecondaryButton
                onClick={() => {
                  setCostSelected({
                    id: id,
                    accomodationList: costRuleInfo.accomodationList,
                    amount: costRuleInfo.amount,
                    costCategory: costRuleInfo.costCategory,
                    costTypology: costRuleInfo.costTypology,
                    costrulename: costRuleInfo.costrulename,
                    name: costRuleInfo.name,
                    creationDate: costRuleInfo.creationDate,
                    executionDate: costRuleInfo.executionDate,
                    recurrency: costRuleInfo.recurrency,
                    recurrencyCustom: costRuleInfo.recurrencyCustom,
                    paymentDateType: costRuleInfo.paymentDateType,
                    endDate: costRuleInfo.endDate,
                    paymentType: costRuleInfo.paymentType,
                    supplierId: costRuleInfo.supplierId,
              paymentBased: costRuleInfo.paymentBased
                  });
                  setRemoveModal(true);
                }}
                content={<IoTrashBin className="mx-auto" />}
                className={"p-1 mx-2 max-w-[100px]"}
              />
            </div>
        </td>
      </tr>
      
    );
  };
  return (
    <>
    
    <InfoButton nameComponent={'costRules'}/>
      <div className="w-full">
        <h1 className="text-3xl font-bold">{t("CostRules")}</h1>
        <Link
          className="flex flex-row items-center my-5 text-2xl font-normal cursor-pointer w-fit"
          to="new-cost-rules"
        >
          <IoMdAddCircleOutline className="mr-1 w-[2rem]" />
          <p>{t("NewCostRule")}</p>
        </Link>
        <hr className="w-full max-w-5xl mb-6" />

        {costsRulesList && costsRulesList.length > 0 ? (
          <>
            <table className="w-full mb-10 bg-white rounded-lg shadow-md">
              <tbody className="">
                <tr>
                  <th className="p-4 text-left w-fit">{t("FilterCost")}</th>
                  <th className="text-left w-fit">
                  <div className="flex items-center justify-start space-x-20">
                    <div className="flex items-center justify-start space-x-5">
                      <h4>{t("ByCostDate")}:</h4>
                      <NewDatePicker
                        className={"w-[200px]"}
                        placeholder={t("From")}
                        ref={datePickerRef1}
                        onCompleted={(el) => {
                          setFilterDateFrom(el);
                        }}
                      />
                      <NewDatePicker
                        className={"w-[200px]"}
                        placeholder={t("To")}
                        ref={datePickerRef2}
                        onCompleted={(el) => {
                          if (filterDateFrom && el < filterDateFrom) {
                            toast.error(CheckDates);
                            setFilterDateTo();
                          } else {
                            setFilterDateTo(el);
                          }
                        }}
                      />
                      </div>
                      <div className="flex items-center justify-start space-x-5">
                                            <h4>{t("RuleName")}:</h4>
                      <SelectMenu
                          className={"w-[200px]"}
              placeholder={t("SelectPlaceholder")}
              options={options}
              isMulti={true}
              isClearable
              value={selectedValue}
              // isLoading={context.loading}
              isSearchable
              onChange={(value) => {
                if (value.length > 0) {
                  setSelectedValue(value);  
                  setFilterRuleName(value);
                }else{
                  setSelectedValue([]);  
                  setFilterRuleName(options)
                }
                }}
            />
                    </div>
</div>
                  </th>
                  <th className="text-left w-fit">
                    <div className="flex items-center justify-start space-x-5">
                    <ResetButton onReset={handleClearFilters}></ResetButton>
                    </div>
                    </th>
                  <th></th>
                </tr>
              </tbody>
            </table>
            <table className="table w-full  bg-white rounded-lg shadow-md">
              <thead className="sticky top-0 table-header-group bg-white">
                <tr className="border-b-2">
                  <th className="w-48 p-4 border-r">{t("RuleName")}</th>
                  <th className="w-48 p-4 border-r">{t("Typology")}</th>
                  <th className="p-4 border-r w-fit">{t("Category")}</th>
                  <th className="w-48 p-4 border-r">{t("SubCategory")}</th>
                  {/* <th className="w-48 p-4 border-r">Payment type</th> */}
                  <th className="p-4 border-r w-28">{t("Amount")}</th>
                  <th className="p-4 border-r w-28">{t("Recurrence")}</th>
                  <th
                    className="p-4 border-r w-96 hover:cursor-pointer hover:bg-neutral-50"
                    onClick={handleCreationDateOrder}
                  >
                    <div className="flex items-center justify-center ">
                      {costCreationDateOrder === "sort" && (
                        <GoTriangleUp className="" />
                      )}
                      {costCreationDateOrder === "reverse" && (
                          <GoTriangleDown className="" />
                        )}
                      <p className="ml-2">{t("CreationDate")}</p>
                    </div>
                  </th>
                  {/* <th className="p-4 border-r w-28">Supplier</th> */}
                  <th className="p-4 border-r w-28">{t("Accommodations")}</th>
                  <th className="w-40">{t("Actions")}</th>
                </tr>
              </thead>

              <tbody className="w-full h-10 overflow-scroll ">
              {
  ((filterDateFrom && filterDateTo) || filterRuleName) ? (
    filteredData.length > 0 ? (
      filteredData.map((cost, key) => {
        if (key >= tablePage * 10 - 10 && key < tablePage * 10) {
          return (
            <CostRuleRow costRuleInfo={cost.data} id={cost.id} key={key} />
          );
        }
        return null;
      })
    ) : (
      <tr><td colSpan={9} aria-colspan={9} className="text-lg font-semibold text-center">{t("NoCostRule")}</td></tr>
    )
  ) : (
    !filterRuleName && (
      filteredData.length > 0 ? (
        filteredData.map((cost, key) => {
          if (key >= tablePage * 10 - 10 && key < tablePage * 10) {
            return (
              <CostRuleRow costRuleInfo={cost.data} id={cost.id} key={key} />
            );
          }
          return null;
        })
      ) : (
        <tr> <td colSpan={9} aria-colspan={9} className="text-lg font-semibold text-center">{t("NoCostRule")}</td></tr>
      )
    )
  )
}


              </tbody>
              <tfoot>
                <tr className="border-b-2 bg-neutral-50">
                  <th className="p-4 w-fit"></th>
                  <th className="p-4 w-28"></th>
                  <th className="w-48 "></th>
                  <th className="w-48 "></th>
                  <th className="p-4 "></th>
                  <th></th>
                  <th></th>
                  <th className="p-4 border-r w-28"></th>
                  <th className="flex items-center justify-center w-40 px-4 space-x-4 ">
                    <SecondaryButton
                      content={"<"}
                      className={"w-[30px] p-1"}
                      onClick={() =>
                        tablePage != 1 ? setTablePage(tablePage - 1) : null
                      }
                    />

                    <p className="text-xs text-center">
                      {tablePage} {t("of")} {Math.ceil(costsRulesList.length / 10)}
                    </p>
                    <div className="flex items-center justify-center space-x-2">
                      {
                        <SecondaryButton
                          content={">"}
                          className={"w-[30px] p-1"}
                          onClick={() =>
                            Math.ceil(costsRulesList.length / 10) != tablePage
                              ? setTablePage(tablePage + 1)
                              : null
                          }
                        />
                      }
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </>
        ) : (
          <div className="w-full text-lg font-semibold text-center">
            {t("NoCostRule")}
          </div>
        )}
        { (costsRulesList.length >= 10 && !filterDateFrom && !filterDateTo && !filterRuleName) || filteredData.length >= 10 ?
          <div className="font-bold flex items-center justify-end p-4 text-petroil rounded-full ">
          <p className="mr-1">{t("NeedSupport")}</p>
          <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
          >
            {t("ContactUs")}
          </a>
        </div>
        :
        <div className="font-bold absolute bottom-0 right-10 flex items-center justify-end p-4 text-petroil rounded-full ">
  <p className="mr-1">{t("NeedSupport")}</p>
  <a href="mailto:support@bilemon.com" className="text-petroil hover:brightness-125 rounded-full bg-transparent"
  >
    {t("ContactUs")}
  </a>
</div>
        }
      </div>
      <Modal
        externalControl
        title={t("RemoveCostRuleLabel")}
        body={
          <>
            <h1 className="text-lg mb-3">{t("RemoveSure")} 
            "{costSelected && costSelected.costrulename}"?</h1>
            <div className="flex justify-end">
            <TertiaryButton
                className={"bg-slate-400 mr-2 text-white p-2 w-fit max-w-[130px] mt-2"}
                content={t("Cancel")}
                onClick={() => {
                  setRemoveModal(false)}}
              />
              <TertiaryButton
                className={"bg-red text-white p-2 w-fit max-w-[130px] mt-2"}
                content={t("ConfirmedSure")}
                onClick={() => {
                  deleteCostRule(costSelected.id, context.organizationId)
                    .then(() => {
                      context.updateData("cost-rules");
                      setRemoveModal(false);
                      setCostSelected();
                    })
                    .catch((error) => toast.error(t("ErrorDeletingCostRule") + error));
                }}
              />              
            </div>
          </>
        }
        status={removeModal}
        onClose={() => setRemoveModal(false)}
      />
      <Modal
        externalControl
        status={editModal}
        title={t("Edit")}
        className={"max-w-[850px]"}
        body={
          <CostRuleEdit
            selectedCostRule={costSelected}
            onClose={() => setEditModal(false)}
          />
        }
        onClose={() => setEditModal(false)}
      />
      <Modal
        externalControl
        status={viewModal}
        title={t("CostRuleDetails")}
        className={"w-[600px]"}
        body={
          <CostRuleView
            selectedCost={costSelected}
            onClose={() => setViewModal(false)}
          />
        }
        onClose={() => setViewModal(false)}
      />
    </>
  );
}

export default CostRules;
