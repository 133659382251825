import React, { useState } from "react";
import TextField from "../input/fields/text-field";
import PrimaryButton from "../buttons/primary-button";
import TertiaryButton from "../buttons/tertiary-button";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


export const NewSupplier = ({ onComplete, supplier, onCloseModal }) => {
  const { i18n, t } = useTranslation();
  const [name, setName] = useState(supplier ? supplier.name : null);
  const [phoneNumber, setPhoneNumber] = useState(
    supplier ? supplier.phoneNumber : null
  );
  const [category, setCategory] = useState(supplier ? supplier.category : null);
  const regexNumber = /^\+*[0-9]+$/g;
  const checkFields = () => {
    if (!name) { 
      toast.error("Please, provide a name");
      return;
    }
    return true;
  };
  return (
    <div>
      <div className="flex flex-wrap ">
        <TextField
          label={t("NameRequiredTitle")}
          placeholder={t("Insert")}
          className={"max-w-xl"}
          inputClassName={"p-1"}
          onChange={(e) => setName(e.target.value)}
          defaultValue={supplier ? supplier.name : ""}
        />
        <TextField
          label={t("PhoneNumber")}
          placeholder={t("Insert")}
          inputClassName={"p-1 "}
          className={"max-w-xl"}
          onChange={(e) => setPhoneNumber(e.target.value)}
          defaultValue={supplier ? supplier.phoneNumber : ""}
        />
        <TextField
          label={t("CategoryRequired")}
          className={"max-w-xl"}
          placeholder={t("Insert")}
          inputClassName={"p-1"}
          onChange={(e) => setCategory(e.target.value)}
          defaultValue={supplier ? supplier.category : ""}
        />
      </div>
      <div className="flex items-center justify-end w-full">
        {" "}
        <TertiaryButton
          onClick={onCloseModal}
          className={"bg-red p-2 w-fit mr-5 mt-5"}
          content={t("Close")}
        />
        <PrimaryButton
          content={supplier ? t("Edit") : t("Add")}
          className={"p-2 w-fit mt-5"}
          onClick={() => {
            if (checkFields())
              onComplete(
                supplier
                  ? {
                      id: supplier.id,
                      name: name,
                      phoneNumber: phoneNumber ? phoneNumber : null,
                      category: category ? category : null,
                    }
                  : {
                      name: name,
                      phoneNumber: phoneNumber ? phoneNumber : null,
                      category: category ? category : null,
                    }
              );
          }}
        />
      </div>
    </div>
  );
};
